import { IHierarchyPayloadItem } from '../models';
import { IHierarchyItemDTO, IHierarchyKeywordsDTO } from '../models/dto';
import {
	deserializeHierarchyValidationUpdateItem,
	deserializeHierarchyValidationUpdateItems,
	serializeHierarchyValidationItem,
	serializeHierarchyValidationItems,
} from '../serializers';
import axiosInstance from './axios/axios';

export const fetchHierarchiesApi = async () => {
	const response = await axiosInstance.get<IHierarchyItemDTO[]>(
		'configure/api/configureHierarchies/',
		{ params: { prod: true } }
	);
	const hierarchyItem = serializeHierarchyValidationItems(response.data);
	return hierarchyItem;
};

export const fetchHierarchiesKeywordsApi = async (payload: number) => {
	const response = await axiosInstance.get<IHierarchyKeywordsDTO>(
		`configure/api/configureHierarchies/${payload}/tree/`
	);
	const hierarchyItem = [response.data];
	return hierarchyItem;
};

// TODO: Review this code.
export const updateHierarchyApi = async (payload: IHierarchyPayloadItem) => {
	const response = await axiosInstance.patch<IHierarchyItemDTO>(
		`configure/api/configureHierarchies/${payload.id}/`,
		deserializeHierarchyValidationUpdateItem(payload),
		{ params: { prod: true } }
	);
	response.data.last_id = payload.id;
	const updatedItem = serializeHierarchyValidationItem(response.data);
	return updatedItem;
};

// TODO: Review this code.
export const bulkUpdateHierarchiesApi = async (
	payload: IHierarchyPayloadItem[]
) => {
	const response = await axiosInstance.patch<IHierarchyItemDTO[]>(
		'configure/api/configureHierarchies/bulk_update/',
		deserializeHierarchyValidationUpdateItems(payload),
		{ params: { prod: true } }
	);
	const oldIds = payload.map((payloadItem) => payloadItem.id);
	response.data.forEach((responseItem, index) => {
		responseItem.last_id = oldIds[index];
	});
	const updatedItem = serializeHierarchyValidationItems(response.data);
	return updatedItem;
};
