import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface IProps {
	delay?: number;
}

const CircularSpinner: React.FC<IProps> = ({ delay }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			if (!isOpen) setIsOpen(true);
		}, delay);

		return () => {
			clearTimeout(timeout);
		};
	}, [delay]);

	return (
		<Backdrop
			sx={{
				backgroundColor: 'action.selected',
				zIndex: (theme) => theme.zIndex.drawer + 1,
				display: 'flex',
			}}
			open={isOpen}
		>
			<CircularProgress color="primary" />
		</Backdrop>
	);
};

CircularSpinner.defaultProps = {
	delay: 400,
};

export default CircularSpinner;
