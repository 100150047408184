// libraries imports
import React, { useState, useEffect, useMemo } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

// mui imports
import {
	BottomNavigation,
	BottomNavigationAction,
	Box,
	Collapse,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// project imports
import SubMenuItems from '../Components/SubMenuItems';
import { useAppSelector } from '../../../store/hooks';
import { selectUserRole } from '../../../store/selectors';
import useScreenSize from '../../../hooks/useScreenSize';

// variables
const drawerWidth = 340;
const adminSubMenuItems = [
	{ id: 'nplSettings', label: 'NLP Settings' },
	{ id: 'emailSettings', label: 'Email Settings' },
	{ id: 'authenticationSettings', label: 'Authentication Settings' },
	{ id: 'customerLogo', label: 'Customer Logo' },
	{ id: 'otherSettings', label: 'Other Settings' },
];

// types
export type TSettingType = 'appConfigurations' | 'userPreferences';

// ========================|| APP SETTINGS ||=================================//
const AppSettings = () => {
	// states
	const [open, setOpen] = useState<boolean>(true);
	const [active, setActive] = useState<string>('appConfigurations');
	const [expandAdminSubMenu, setExpandAdminSubMenu] = useState<boolean>(true);

	// redux
	const { role } = useAppSelector(selectUserRole);
	const isAdminUser = useMemo(
		() => role === 'superuser' || role === 'admin',
		[role]
	);

	// hooks
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const { isTabletOrAbove } = useScreenSize();

	const settingsType = useMemo(() => {
		return location.pathname.split('/')[2];
	}, [location.pathname]);

	// event handlers
	const handleDrawerToggle = () => {
		setOpen((prev) => !prev);
	};

	const handleClick = (value: string) => {
		if (active !== value) {
			setActive(value);
		}
	};
	const handleAdminSubMenuToggle = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
		event.stopPropagation();
		setExpandAdminSubMenu((prev) => !prev);
	};

	const handleSubMenuItemClick = (hashValue: string, type: TSettingType) => {
		if (type !== settingsType) {
			navigate(`/settings/${type}#${hashValue}`);
		} else {
			navigate(`#${hashValue}`);
		}
	};

	// use effects
	useEffect(() => {
		setActive(settingsType);
	}, [settingsType]);

	useEffect(() => {
		if (!isTabletOrAbove) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	}, [isTabletOrAbove]);

	// render elements
	const drawerContents = (
		<List>
			<ListItemButton onClick={handleDrawerToggle}>
				<ListItemIcon>
					{open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
				</ListItemIcon>
			</ListItemButton>

			<Divider />
			{isAdminUser && (
				<>
					<Tooltip
						title={<Typography>App Configurations</Typography>}
						placement="right"
						arrow
					>
						<>
							<ListItemButton
								data-testid="appConfigButton"
								selected={active === 'appConfigurations'}
								component={Link}
								to="/settings/appConfigurations"
								onClick={() => handleClick('appConfigurations')}
								aria-label="App Configurations"
							>
								<ListItemIcon>
									<AppSettingsAltIcon
										color={
											active === 'appConfigurations' ? 'primary' : 'inherit'
										}
									/>
								</ListItemIcon>
								<ListItemText primary="App Configurations" />
								<IconButton onClick={handleAdminSubMenuToggle}>
									{expandAdminSubMenu ? <ExpandLess /> : <ExpandMore />}
								</IconButton>
							</ListItemButton>
							{open && (
								<Collapse in={expandAdminSubMenu} timeout="auto" unmountOnExit>
									<SubMenuItems
										subMenuItems={adminSubMenuItems}
										handleMenuItemClick={handleSubMenuItemClick}
										type="appConfigurations"
									/>
								</Collapse>
							)}
						</>
					</Tooltip>
					<Tooltip
						title={<Typography>Admin Preferences</Typography>}
						placement="right"
						arrow
					>
						<ListItemButton
							data-testid="adminPrefButton"
							selected={active === 'adminPreferences'}
							component={Link}
							to="/settings/adminPreferences"
							onClick={() => handleClick('adminPreferences')}
							aria-label="Admin Preferences"
						>
							<ListItemIcon>
								<AdminPanelSettingsIcon
									color={active === 'adminPreferences' ? 'primary' : 'inherit'}
								/>
							</ListItemIcon>
							<ListItemText primary="Admin Preferences" />
						</ListItemButton>
					</Tooltip>
				</>
			)}

			<Tooltip
				title={<Typography>User Preferences</Typography>}
				placement="right"
				arrow
			>
				<ListItemButton
					data-testid="userPrefButton"
					selected={active === 'userPreferences'}
					component={Link}
					to="/settings/userPreferences"
					onClick={() => handleClick('userPreferences')}
					aria-label="User Preferences"
				>
					<ListItemIcon>
						<AccountCircleIcon
							color={active === 'userPreferences' ? 'primary' : 'inherit'}
						/>
					</ListItemIcon>
					<ListItemText primary="User Preferences" />
				</ListItemButton>
			</Tooltip>
		</List>
	);

	return (
		<Box sx={{ display: 'flex' }}>
			{isTabletOrAbove ? (
				<Drawer
					{...(isTabletOrAbove && { variant: 'permanent' })}
					sx={{
						width: open ? drawerWidth : theme.spacing(7),
						flexShrink: 0,
						whiteSpace: 'nowrap',
						marginTop: 8,
						transition: theme.transitions.create('width', {
							easing: theme.transitions.easing.sharp,
							duration: open
								? theme.transitions.duration.enteringScreen
								: theme.transitions.duration.leavingScreen,
						}),
					}}
					PaperProps={{
						sx: {
							transition: theme.transitions.create('width', {
								easing: theme.transitions.easing.sharp,
								duration: open
									? theme.transitions.duration.enteringScreen
									: theme.transitions.duration.leavingScreen,
							}),
							marginTop: 8,
							width: open ? drawerWidth : theme.spacing(7),
							zIndex: 0,
							boxSizing: 'border-box',
						},
					}}
				>
					<Box
						sx={{
							height: 'calc(100% - 64px)',
							overflowY: 'auto',
							overflowX: 'hidden',
						}}
					>
						{drawerContents}
					</Box>
				</Drawer>
			) : (
				<Paper
					sx={{
						position: 'fixed',
						bottom: 0,
						left: 0,
						right: 0,
						zIndex: (theme_) => theme_.zIndex.drawer + 1,
					}}
					elevation={3}
				>
					<BottomNavigation
						showLabels
						value={active}
						onChange={(event, newValue) => {
							setActive(newValue);
						}}
					>
						<BottomNavigationAction
							component={Link}
							to="/settings/appConfigurations"
							value="appConfigurations"
							label="App Configurations"
							icon={<AppSettingsAltIcon />}
						/>
						<BottomNavigationAction
							component={Link}
							to="/settings/userPreferences"
							value="userPreferences"
							label="User Preferences"
							icon={<AccountCircleIcon />}
						/>
						<BottomNavigationAction
							component={Link}
							to="/settings/adminPreferences"
							value="adminPreferences"
							label="Admin Preferences"
							icon={<AdminPanelSettingsIcon />}
						/>
					</BottomNavigation>
				</Paper>
			)}

			<Box
				component="main"
				sx={{ flexGrow: 1, p: 3, ...(!isTabletOrAbove && { mb: 4 }) }}
			>
				<Outlet />
			</Box>
		</Box>
	);
};

export default AppSettings;
