import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	ISearchAccuracyBulkUpdate,
	ISearchAccuracyItem,
	ISearchAccuracyUpdate,
} from '../../models';
import { IAppState, ISearchAccuracyStore } from '../../models/store';
import {
	bulkUpdate,
	fetchItems,
	updateItem,
} from '../actions/searchAccuracy.actions';

const SLICE_NAME = 'searchAccuracy';

const INITIAL_STATE: ISearchAccuracyStore = {
	currentItemOrder: [],
	fetchStatus: 'idle',
	originalItemOrder: [],
	searchAccuracyItems: {},
};

const searchAccuracySlice = createSlice({
	name: SLICE_NAME,
	initialState: INITIAL_STATE,
	reducers: {
		search: (state, action: PayloadAction<ISearchAccuracyItem[]>) => {
			const searchResults = action.payload;

			if (searchResults.length) {
				state.currentItemOrder = searchResults.map((result) => result.id);
			} else {
				state.currentItemOrder = [...state.originalItemOrder];
			}
		},
		sort: (state, action: PayloadAction<ISearchAccuracyItem[]>) => {
			const sortResult = action.payload;

			if (sortResult.length) {
				state.currentItemOrder = sortResult.map((result) => result.id);
			} else {
				state.currentItemOrder = [...state.originalItemOrder];
			}
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchItems.pending, (state) => {
				state.fetchStatus = 'pending';
			})
			.addCase(fetchItems.rejected, (state) => {
				state.fetchStatus = 'rejected';
			})
			.addCase(fetchItems.fulfilled, (state, action) => {
				const items = action.payload;

				state.searchAccuracyItems = {};
				state.currentItemOrder = [];
				state.originalItemOrder = [];

				items.forEach((item) => {
					state.searchAccuracyItems[item.id] = item;
					state.originalItemOrder.push(item.id);
					state.currentItemOrder.push(item.id);
				});

				state.fetchStatus = 'fulfilled';
			})
			.addCase(updateItem.pending, (state, action) => {
				const itemId = action.payload;
				const item = state.searchAccuracyItems[itemId];

				if (item) {
					item.isUpdating = true;
				}
			})
			.addCase(updateItem.rejected, (state, action) => {
				const itemId = action.payload;
				const item = state.searchAccuracyItems[itemId];

				if (item) {
					item.isUpdating = false;
				}
			})
			.addCase(updateItem.fulfilled, (state, action) => {
				const updatedItem = action.payload;
				updatedItem.isUpdating = false;
				state.searchAccuracyItems[updatedItem.id] = updatedItem;
			})
			.addCase(bulkUpdate.pending, (state, action) => {
				const itemIds = action.payload;
				itemIds.forEach((id) => {
					const item = state.searchAccuracyItems[id];
					if (item) {
						item.isUpdating = true;
					}
				});
			})
			.addCase(bulkUpdate.rejected, (state, action) => {
				const itemIds = action.payload;
				itemIds.forEach((id) => {
					const item = state.searchAccuracyItems[id];
					if (item) {
						item.isUpdating = false;
					}
				});
			})
			.addCase(bulkUpdate.fulfilled, (state, action) => {
				const updatedItems = action.payload;
				updatedItems.forEach((item) => {
					item.isUpdating = false;
					state.searchAccuracyItems[item.id] = item;
				});
			}),
});

export const searchAccuracyActions = {
	...searchAccuracySlice.actions,
	fetchItems,
	updateItem,
	bulkUpdate,
};

export default searchAccuracySlice.reducer;
