// libraries imports
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	createSearchParams,
	useNavigate,
	useLocation,
	useSearchParams,
} from 'react-router-dom';

// mui imports
import { Box, CircularProgress, Typography } from '@mui/material';

// project imports
import {
	INavigatorModel,
	INavigatorTable,
} from '../../../../models/dataSummary.model';
import { TRequestStatus } from '../../../../models';
import MetricStoreNavigatorSearch from './MetricStoreNavigatorSearch/MetricStoreNavigatorSearch';
import MetricStoreNavigatorTreeView from './MetricStoreNavigatorTreeView/MetricStoreNavigatorTreeView';
import {
	fetchDataModels,
	fetchTables,
	searchDataSummary,
} from '../../../../api/dataSummary.api';

// types
interface IProps {
	showList: boolean;
}

// ========================|| METRIC STORE NAVIGATOR ||=======================//
const MetricStoreNavigator: React.FC<IProps> = ({ showList }) => {
	// states
	const [modelsFetchStatus, setModelsFetchStatus] =
		useState<TRequestStatus>('idle');
	const [tablesFetchStatus, setTablesFetchStatus] =
		useState<TRequestStatus>('idle');
	const [searchStatus, setSearchStatus] = useState<TRequestStatus>('idle');

	const [models, setModels] = useState<INavigatorModel[]>([]);
	const [tables, setTables] = useState<INavigatorTable[]>([]);

	const [searchTerm, setSearchTerm] = useState<string | null>(null);

	// hooks
	const location = useLocation();
	const navigate = useNavigate();
	const [queryParams] = useSearchParams();
	const modelId = useMemo(() => {
		return location.pathname.split('/')[3];
	}, [location]);

	// event handlers
	const onSearch = useCallback(
		(text: string | null) => {
			navigate(
				{
					pathname: location.pathname,
					search: text ? `?${createSearchParams({ search: text })}` : '',
				},
				{ replace: true }
			);
		},
		[navigate, location]
	);

	// use effects
	useEffect(() => {
		if (!modelId && models.length && tables.length) {
			navigate(`/dataGovernance/metricStore/models/${models[0].id}/metrics`, {
				replace: true,
			});
		}
	}, [modelId, models, navigate, tables]);

	useEffect(() => {
		const searchText = queryParams.get('search');
		setSearchTerm(searchText);
	}, [queryParams]);

	useEffect(() => {
		if (searchTerm) {
			setSearchStatus('pending');
			setModelsFetchStatus('idle');
			setTablesFetchStatus('idle');

			searchDataSummary(searchTerm)
				.then((searchResult) => {
					setModels(searchResult.models);
					setTables(searchResult.tables);
					setSearchStatus('fulfilled');
				})
				.catch(() => {
					setSearchStatus('rejected');
				});
		} else {
			setModelsFetchStatus('pending');
			setTablesFetchStatus('pending');
			setSearchStatus('idle');

			fetchDataModels()
				.then((modelItems) => {
					setModels(modelItems);
					setModelsFetchStatus('fulfilled');
				})
				.catch(() => {
					setModelsFetchStatus('rejected');
				});

			fetchTables()
				.then((tableItems) => {
					setTables(tableItems);
					setTablesFetchStatus('fulfilled');
				})
				.catch(() => {
					setTablesFetchStatus('rejected');
				});
		}
	}, [searchTerm]);

	if (!showList) {
		return null;
	}

	return (
		<Box component="div" sx={{ width: '100%' }}>
			<MetricStoreNavigatorSearch
				onSubmit={onSearch}
				currentSearchTerm={searchTerm}
			/>

			{(searchTerm
				? searchStatus === 'fulfilled'
				: searchStatus === 'fulfilled' ||
				  modelsFetchStatus === 'fulfilled') && (
				<MetricStoreNavigatorTreeView
					models={models}
					tables={tables}
					tablesFetchStatus={tablesFetchStatus}
					searchTerm={searchTerm}
				/>
			)}

			{searchStatus === 'rejected' && (
				<Typography variant="caption">
					Failed to fetch search results.
				</Typography>
			)}

			{searchStatus === 'pending' || modelsFetchStatus === 'pending' ? (
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'middle',
						justifyContent: 'center',
					}}
				>
					<Box>
						<CircularProgress sx={{ mt: 2 }} size={30} />
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default MetricStoreNavigator;
