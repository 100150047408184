import { IShareDiveChartPayload, IUserDetails } from '../../models';
import { IPinnedChartDataDTO } from '../../models/dto';
import { IWorkspaceDashboardDetailsDTO } from '../../models/dto/workspaceDashboard.dto.model';
import {
	deserializeShareDiveChartPayload,
	deserializeShareReportData,
} from '../../serializers';
import { serializePinnedChartData } from '../../serializers/charts.serializer';
import { serializeWorkspaceDashboardDetails } from '../../serializers/workspaceDashboard.serializer';
import axiosInstance from '../axios/axios';

export const shareDiveChartApi = async (items: IShareDiveChartPayload) => {
	const payload = deserializeShareDiveChartPayload(items);

	const result = await axiosInstance
		.post<IPinnedChartDataDTO>(`ddive/api/chart/${payload.id}/share/`, {
			shared_user_ids: payload.shared_user_ids,
		})
		.then((response) => response.data);

	const serializedResult = serializePinnedChartData(result);

	return serializedResult;
};

export const shareDashboardApi = async (
	dashboardId: number,
	userDetails: IUserDetails[]
) => {
	const data = deserializeShareReportData({ userDetails });
	const dashboardDto = await axiosInstance
		.post<IWorkspaceDashboardDetailsDTO>(
			`ddive/api/dashboard/${dashboardId}/share/`,
			data
		)
		.then((response) => response.data);

	const dashboard = serializeWorkspaceDashboardDetails(dashboardDto);

	return dashboard;
};
