import { createSlice } from '@reduxjs/toolkit';

import { IWorkspaceState } from '../../models/store';
import {
	createDashboard,
	deleteReport,
	fetchReports,
	scheduleReport,
	shareReport,
	updateChartDetails,
	updateDashboardDetails,
} from '../actions/workspace.actions';

const INITIAL_STATE: IWorkspaceState = {
	reports: {},
	collections: [{ id: -1, title: 'Your Reports', reports: [] }],
	fetchStatus: 'idle',
	createDashboardStatus: 'idle',
	updateChartDetailsStatus: 'idle',
	updateDashboardDetailsStatus: 'idle',
	filters: {
		search: '',
	},
};

const workspaceSlice = createSlice({
	name: 'workspace',
	initialState: INITIAL_STATE,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchReports.pending, (state) => {
				state.fetchStatus = 'pending';
			})
			.addCase(fetchReports.rejected, (state) => {
				state.fetchStatus = 'rejected';
			})
			.addCase(fetchReports.fulfilled, (state, action) => {
				const { collections, reports } = action.payload;
				state.reports = reports;

				let defaultCollection = state.collections.find(
					(coll) => coll.id === -1
				);

				if (!defaultCollection) {
					defaultCollection = {
						id: -1,
						title: 'Default Collection',
						reports: [],
					};
				}

				defaultCollection.reports = Object.keys(reports).filter(
					(reportId) => !reports[reportId]?.collectionId
				);

				state.collections = [...collections, defaultCollection];

				state.fetchStatus = 'fulfilled';
			})
			.addCase(createDashboard.pending, (state) => {
				state.createDashboardStatus = 'pending';
			})
			.addCase(createDashboard.rejected, (state) => {
				state.createDashboardStatus = 'rejected';
			})
			.addCase(createDashboard.fulfilled, (state, action) => {
				const dashboardData = action.payload;
				const dashboardId = action.payload.uiId;

				state.reports[dashboardId] = dashboardData;

				state.collections[0].reports = [
					...state.collections[0].reports,
					dashboardId,
				];
				state.createDashboardStatus = 'fulfilled';
			})
			.addCase(shareReport.pending, (state, action) => {
				const reportId = action.payload.uiId;
				const report = state.reports[reportId];

				if (report) {
					report.shareStatus = 'pending';
				}
			})
			.addCase(shareReport.rejected, (state, action) => {
				const reportId = action.payload.uiId;
				const report = state.reports[reportId];

				if (report) {
					report.shareStatus = 'rejected';
				}
			})
			.addCase(shareReport.fulfilled, (state, action) => {
				const updatedReport = { ...action.payload };

				updatedReport.shareStatus = 'fulfilled';
				state.reports[updatedReport.uiId] = updatedReport;
			})
			.addCase(scheduleReport.pending, (state, action) => {
				const reportId = action.payload.uiId;
				const report = state.reports[reportId];

				if (report) {
					report.scheduleStatus = 'pending';
				}
			})
			.addCase(scheduleReport.rejected, (state, action) => {
				const reportId = action.payload.uiId;
				const report = state.reports[reportId];

				if (report) {
					report.scheduleStatus = 'rejected';
				}
			})
			.addCase(scheduleReport.fulfilled, (state, action) => {
				const { uiId, schedule } = action.payload;

				const report = state.reports[uiId];
				report.schedule = schedule;
				report.scheduleStatus = 'fulfilled';
			})
			.addCase(deleteReport.pending, (state, action) => {
				const reportId = action.payload.uiId;
				const report = state.reports[reportId];

				if (report) {
					report.deleteStatus = 'pending';
				}
			})
			.addCase(deleteReport.rejected, (state, action) => {
				const reportId = action.payload.uiId;
				const report = state.reports[reportId];

				if (report) {
					report.deleteStatus = 'rejected';
				}
			})
			.addCase(deleteReport.fulfilled, (state, action) => {
				const deletedReport = { ...action.payload };

				delete state.reports[deletedReport.uiId];

				const collectionId = deletedReport.collectionId || -1;
				const collection = state.collections.find((c) => c.id === collectionId);

				if (collection) {
					collection.reports = collection.reports.filter(
						(report) => report !== deletedReport.uiId
					);
				}
			})
			.addCase(updateChartDetails.pending, (state) => {
				state.updateChartDetailsStatus = 'pending';
			})
			.addCase(updateChartDetails.rejected, (state) => {
				state.updateChartDetailsStatus = 'rejected';
			})
			.addCase(updateChartDetails.fulfilled, (state, action) => {
				const updatedReport = { ...action.payload };

				state.reports[updatedReport.uiId] = updatedReport;
				state.updateChartDetailsStatus = 'fulfilled';
			})
			.addCase(updateDashboardDetails.pending, (state) => {
				state.updateDashboardDetailsStatus = 'pending';
			})
			.addCase(updateDashboardDetails.rejected, (state) => {
				state.updateDashboardDetailsStatus = 'rejected';
			})
			.addCase(updateDashboardDetails.fulfilled, (state, action) => {
				const updatedReport = { ...action.payload };

				state.reports[updatedReport.uiId] = updatedReport;
				state.updateDashboardDetailsStatus = 'fulfilled';
			}),
});

export const workspaceActions = {
	...workspaceSlice.actions,
	fetchReports,
	createDashboard,
	shareReport,
	scheduleReport,
	deleteReport,
	updateChartDetails,
	updateDashboardDetails,
};

export default workspaceSlice.reducer;
