// libraries imports
import React from 'react';
import { Outlet } from 'react-router-dom';

// mui imports
import { Container } from '@mui/material';

// ===============|| WORKSPACE LAYOUT ||======================================//
const WorkspaceLayout = () => {
	return (
		<Container
			maxWidth={false}
			sx={{
				flexGrow: 1,
				mt: 8,
				py: 2,
			}}
		>
			<Outlet />
		</Container>
	);
};

export default WorkspaceLayout;
