import { IHierarchyItemDTO, IHierarchyResponseItemDTO } from '../models/dto';
import { IHierarchyItem, IHierarchyPayloadItem } from '../models';

export const serializeHierarchyValidationItem = (
	item: IHierarchyItemDTO
): IHierarchyItem => ({
	id: item.id,
	lastId: item.last_id,
	name: item.entity_name,
	status: item.status,
	assignedTo: item.assigned_to,
	updatedOn: item.updated_on,
	eta: item.eta,
	comments: item.comments,
	dashboard: item.dashboard,
});

export const serializeHierarchyValidationItems = (
	items: IHierarchyItemDTO[]
): IHierarchyItem[] =>
	items.map((item) => serializeHierarchyValidationItem(item));

// Deserializers
export const deserializeHierarchyValidationUpdateItem = (
	item: IHierarchyPayloadItem
): IHierarchyResponseItemDTO => ({
	id: item.id,
	...(item.status && { status: item.status }),
	...(item.assignedTo && {
		assigned_to: JSON.parse(item.assignedTo),
	}),
	...(item.comments && {
		comments:
			item.comments === 'null' ? JSON.parse(item.comments) : item.comments,
	}),
	...(item.dashboard && { dashboard: item.dashboard }),
	...(item.eta && { eta: item.eta }),
});

export const deserializeHierarchyValidationUpdateItems = (
	items: IHierarchyPayloadItem[]
): IHierarchyResponseItemDTO[] =>
	items.map((item) => deserializeHierarchyValidationUpdateItem(item));
