import React, { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface IProps {
	path: string;
}

const DDive: React.FC<IProps> = ({ path }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const rootUrl = useMemo(() => {
		const env = process.env.NODE_ENV;

		if (env === 'production') {
			return window?.location?.origin || '/';
		}

		return process.env.REACT_APP_DDIVE_ORIGIN;
	}, []);

	const queryParams = useRef(window.location.search);

	useEffect(() => {
		// to handle old dashboard url
		if (
			path === 'explore' &&
			searchParams.get('view_analyze_dashboard') &&
			searchParams.get('dashboard_id')
		) {
			navigate(`/workspace/dashboard/${searchParams.get('dashboard_id')}`);
		} else {
			// navigate(`/ddive/${path}`, { replace: true });
			window.location.replace(`${rootUrl}/${path}${queryParams.current}`);
		}
	}, [navigate, path, rootUrl, searchParams]);

	return null;
};

export default DDive;
