import { IAutocompleteItem } from '../models';
import { IAutocompleteResultDTO } from '../models/dto';

export const serializeAutocompleteResult = (
	result: IAutocompleteResultDTO
): IAutocompleteItem[] =>
	result.data.map((item) => ({
		optionKey: `${item.tablename}.${item.columnname}[${item.alias}]`,
		title: item.alias,
		column: item.columnname,
		table: item.tablename,
		type: item.type,
	}));
