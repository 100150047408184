import { serializeUser, serializeUsers } from '.';
import {
	IWorkspaceAutoCompleteUsers,
	IWorkspaceChart,
	IWorkspaceCollection,
	IWorkspaceDashboard,
	IWorkspaceFetchResult,
} from '../models';
import {
	IPinnedChartDataDTO,
	IWorkspaceAutoCompleteUsersDTO,
	IWorkspaceChartDTO,
	IWorkspaceCollectionDTO,
	IWorkspaceDashboardDTO,
	IWorkspaceFetchResultDTO,
} from '../models/dto';
import { serializeReportSchedule } from './schedule.serializer';

export const serializeWorkspaceChart = (
	chart: IWorkspaceChartDTO
): IWorkspaceChart => ({
	id: chart.id,
	uiId: `chart_${chart.id}`,
	type: 'chart',
	chartType: chart.chart_type,
	title: chart.chart_title,
	collectionId: chart.collection_id,
	sharedWith: serializeUsers(chart.shared_user_ids),
	schedule: chart.schedule ? serializeReportSchedule(chart.schedule) : null,
	owner: serializeUser(chart.user_id),
	createdDate: chart.created_date,
	lastModifiedDate: chart.modified_date,
	isPublished: chart.is_system,
	shareStatus: 'idle',
	deleteStatus: 'idle',
	scheduleStatus: 'idle',
});

export const serializeWorkspaceCharts = (
	charts: IWorkspaceChartDTO[]
): IWorkspaceChart[] =>
	charts
		.filter((chart) => chart.user_id !== null)
		.map((chart) => serializeWorkspaceChart(chart));

// returns chart id along with chart's coordinates in a dashboard
const serializeChartCoordinates = (charts: IPinnedChartDataDTO[]) => {
	const result = charts.map((chart) => {
		return {
			id: chart.id,
			startCoordinates: chart.start_coordinates,
		};
	});

	return result;
};

export const serializeWorkspaceDashboard = (
	dashboard: IWorkspaceDashboardDTO
): IWorkspaceDashboard => ({
	id: dashboard.id,
	uiId: `dashboard_${dashboard.id}`,
	type: 'dashboard',
	title: dashboard.dashboard_name,
	collectionId: dashboard.collection_id,
	chartCoordinates: serializeChartCoordinates(dashboard.charts),
	sharedWith: serializeUsers(dashboard.shared_user_ids),
	schedule: dashboard.schedule
		? serializeReportSchedule(dashboard.schedule)
		: null,
	owner: serializeUser(dashboard.user_id),
	createdDate: dashboard.created_date,
	lastModifiedDate: dashboard.modified_date,
	isPublished: dashboard.is_system,
	shareStatus: 'idle',
	deleteStatus: 'idle',
	scheduleStatus: 'idle',
});

export const serializeWorkspaceDashboards = (
	dashboards: IWorkspaceDashboardDTO[]
): IWorkspaceDashboard[] =>
	dashboards
		.filter((dashboard) => dashboard.user_id !== null)
		.map((dashboard) => serializeWorkspaceDashboard(dashboard));

export const serializeWorkspaceCollection = (
	collection: IWorkspaceCollectionDTO
): IWorkspaceCollection => ({
	id: collection.id,
	title: collection.title,
	reports: collection.reports.map((report) => `${report.type}_${report.id}`),
});

export const serializeWorkspaceCollections = (
	collections: IWorkspaceCollectionDTO[]
): IWorkspaceCollection[] =>
	collections.map((collection) => serializeWorkspaceCollection(collection));

export const serializeWorkspaceFetchResult = (
	workspaceData: IWorkspaceFetchResultDTO
): IWorkspaceFetchResult => {
	const result: IWorkspaceFetchResult = {
		reports: {},
		collections: serializeWorkspaceCollections(workspaceData.collections),
	};

	const charts = serializeWorkspaceCharts(workspaceData.charts);
	const dashboards = serializeWorkspaceDashboards(workspaceData.dashboards);

	charts.forEach((chart) => {
		result.reports[chart.uiId] = chart;
	});

	dashboards.forEach((dashboard) => {
		result.reports[dashboard.uiId] = dashboard;
	});

	return result;
};

export const serializeWorkspaceAutoCompleteUser = (
	user: IWorkspaceAutoCompleteUsersDTO
): IWorkspaceAutoCompleteUsers => {
	return {
		id: user.id,
		email: user.email,
		userName: user.username,
	};
};

export const serializeWorkspaceAutoCompleteUsers = (
	users: IWorkspaceAutoCompleteUsersDTO[]
): IWorkspaceAutoCompleteUsers[] => {
	return users.map((user) => serializeWorkspaceAutoCompleteUser(user));
};
