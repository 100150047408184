import { IMetricPayloadItem } from '../models';
import {
	IDashboardDTO,
	IMetricItemDTO,
	IMetricTableDTO,
	IUsersDTO,
} from '../models/dto';

import {
	deserializeMetricValidationUpdateItem,
	deserializeMetricValidationUpdateItems,
	serializeMetricValidationItem,
	serializeMetricValidationItems,
} from '../serializers';
import axiosInstance from './axios/axios';

export const fetchMetricValidationItemsApi = async () => {
	const response = await axiosInstance.get<IMetricItemDTO[]>(
		'configure/api/configureMeasures/',
		{ params: { prod: true } }
	);
	const metricItem = serializeMetricValidationItems(response.data);
	return metricItem;
};

export const fetchUsersApi = async () => {
	const response = await axiosInstance.get<IUsersDTO[]>('api/users/', {
		baseURL: '/ddive',
	});
	const users = response.data;
	return users;
};

export const fetchDashboardsApi = async () => {
	const response = await axiosInstance.get<IDashboardDTO[]>('api/dashboard/', {
		baseURL: '/ddive',
	});
	const dashboards = response.data;
	return dashboards;
};

export const fetchMetricValidationTablesApi = async () => {
	const response = await axiosInstance.get<IMetricTableDTO[]>(
		'configure/api/configureTables/?prod=True'
	);
	const tables = response.data;
	return tables;
};

export const updateMetricValidationApi = async (
	payload: IMetricPayloadItem
) => {
	const data = deserializeMetricValidationUpdateItem(payload);

	// TODO: Change the data type to enum.
	const url =
		payload.itemType === 'calculated'
			? `configure/api/configureCalcMeasures/${payload.id}/`
			: `configure/api/configureBaseMeasures/${payload.id}/`;

	const response = await axiosInstance.patch<IMetricItemDTO>(url, data, {
		params: { prod: true },
	});

	const modifiedResponse = { ...response.data, last_id: payload.id };
	const updatedItem = serializeMetricValidationItem(modifiedResponse);
	return updatedItem;
};

export const bulkUpdateMetricValidationApi = async (
	payload: IMetricPayloadItem[]
) => {
	const basePayload: IMetricPayloadItem[] = [];
	const calcPayload: IMetricPayloadItem[] = [];
	let baseResponse: IMetricItemDTO[] = [];
	let calcResponse: IMetricItemDTO[] = [];
	payload.forEach((item) => {
		if (item.itemType === 'base') {
			basePayload.push(item);
		} else {
			calcPayload.push(item);
		}
	});

	if (basePayload.length) {
		baseResponse = await axiosInstance
			.patch<IMetricItemDTO[]>(
				`configure/api/configureBaseMeasures/bulk_update/`,
				deserializeMetricValidationUpdateItems(basePayload),
				{ params: { prod: true } }
			)
			.then((response) => response.data);
	}
	if (calcPayload.length) {
		calcResponse = await axiosInstance
			.patch<IMetricItemDTO[]>(
				`configure/api/configureCalcMeasures/bulk_update/?prod=true`,
				deserializeMetricValidationUpdateItems(calcPayload),
				{ params: { prod: true } }
			)
			.then((response) => response.data);
	}

	const modifiedResponse = [...baseResponse, ...calcResponse];
	const oldBaseIds = basePayload.map((basePayloadItem) => basePayloadItem.id);
	const oldCalcIds = calcPayload.map((calcPayloadItem) => calcPayloadItem.id);
	const oldIds = [...oldBaseIds, ...oldCalcIds];
	modifiedResponse.forEach((responseItem, index) => {
		responseItem.last_id = oldIds[index];
	});

	const updatedItem = serializeMetricValidationItems(modifiedResponse);
	return updatedItem;
};
