// libraries imports
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

// mui imports
import {
	Box,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import StorageIcon from '@mui/icons-material/Storage';

// project imports
import MetricStoreNavigator from './MetricStore/MetricStoreNavigator/MetricStoreNavigator';
import Navigator from './DataSummary/Navigator/Navigator';
import useScreenSize from '../../hooks/useScreenSize';

// variables
const drawerWidth = 340;

// ==========================|| DATA GOVERNANCE ||============================//
const DataGovernance: React.FC = () => {
	// states
	const [open, setOpen] = useState(true);
	const [mobileOpen, setMobileOpen] = useState(true);
	const [active, setActive] = useState('summary');

	// hooks
	const location = useLocation();
	const theme = useTheme();
	const { isTabletOrAbove } = useScreenSize();

	// event handlers
	const handleMobileDrawerToggle = () => {
		setMobileOpen((prev) => !prev);
	};

	const handleDrawerToggle = () => {
		if (isTabletOrAbove) {
			setOpen((prev) => !prev);
		} else {
			handleMobileDrawerToggle();
		}
	};

	const handleMetricStoreClick = () => {
		if (isTabletOrAbove) {
			if (!open) {
				setOpen(true);
			}
		} else {
			setMobileOpen((prev) => !prev);
		}
		if (active !== 'metricStore') {
			setActive('metricStore');
		}
	};
	const handleDataModelClick = () => {
		if (isTabletOrAbove) {
			if (!open) {
				setOpen(true);
			}
		} else {
			setMobileOpen((prev) => !prev);
		}
		if (active !== 'summary') {
			setActive('summary');
		}
	};

	const getIcon = () => {
		if (isTabletOrAbove) {
			return open ? <ChevronLeftIcon /> : <ChevronRightIcon />;
		}
		return mobileOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />;
	};

	// use effects
	useEffect(() => {
		const pathName = location.pathname.split('/')[2];
		setActive(pathName);
	}, [location.pathname]);

	useEffect(() => {
		if (!isTabletOrAbove) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	}, [isTabletOrAbove]);

	// render elements
	const drawerContents = (isMobileView: boolean) => (
		<List>
			<ListItemButton onClick={handleDrawerToggle}>
				<ListItemIcon>{getIcon()}</ListItemIcon>
			</ListItemButton>

			<Divider />
			<Tooltip
				title={<Typography>Metric Store</Typography>}
				placement="right"
				arrow
			>
				<ListItemButton
					selected={active === 'metricStore'}
					component={Link}
					data-testid="metricStoreBtn"
					to="/dataGovernance/metricStore"
					onClick={handleMetricStoreClick}
					aria-label="Go to metric Store"
				>
					<ListItemIcon>
						<LegendToggleIcon
							color={active === 'metricStore' ? 'primary' : 'inherit'}
						/>
					</ListItemIcon>
					<ListItemText primary="Metric Store" />
				</ListItemButton>
			</Tooltip>
			{active === 'metricStore' &&
				(isTabletOrAbove ? (
					<ListItem sx={{ mb: open ? 2 : 0, p: open ? 1 : 0 }}>
						<MetricStoreNavigator showList={open} />
					</ListItem>
				) : (
					<MetricStoreNavigator showList={isMobileView} />
				))}
			<Tooltip
				title={<Typography>Data Model</Typography>}
				placement="right"
				arrow
			>
				<ListItemButton
					selected={active === 'summary'}
					component={Link}
					to="/dataGovernance/summary"
					onClick={handleDataModelClick}
					aria-label="Go to Data Summary"
				>
					<ListItemIcon>
						<StorageIcon color={active === 'summary' ? 'primary' : 'inherit'} />
					</ListItemIcon>
					<ListItemText data-testid="dataModelSection" primary="Data Model" />
				</ListItemButton>
			</Tooltip>
			{active === 'summary' &&
				(isTabletOrAbove ? (
					<ListItem>
						<Navigator showList={open} />
					</ListItem>
				) : (
					<Navigator showList={isMobileView} />
				))}
		</List>
	);

	return (
		<Box
			sx={{
				display: 'flex',
				height: '100%',
			}}
		>
			{/* Side Drawer  */}

			<Drawer
				variant="permanent"
				sx={{
					width: open ? drawerWidth : theme.spacing(7),
					flexShrink: 0,
					whiteSpace: 'nowrap',
					marginTop: 8,
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: open
							? theme.transitions.duration.enteringScreen
							: theme.transitions.duration.leavingScreen,
					}),
				}}
				PaperProps={{
					sx: {
						transition: theme.transitions.create('width', {
							easing: theme.transitions.easing.sharp,
							duration: open
								? theme.transitions.duration.enteringScreen
								: theme.transitions.duration.leavingScreen,
						}),
						marginTop: 8,
						width: open ? drawerWidth : theme.spacing(7),
						zIndex: 0,
						boxSizing: 'border-box',
					},
				}}
			>
				<Box
					sx={{
						height: 'calc(100% - 64px)',
						overflowY: 'auto',
						overflowX: 'hidden',
					}}
				>
					{drawerContents(isTabletOrAbove)}
				</Box>
			</Drawer>

			{!isTabletOrAbove && (
				<SwipeableDrawer
					open={mobileOpen}
					onOpen={handleMobileDrawerToggle}
					onClose={handleMobileDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						sx: {
							width: 280,
						},
					}}
				>
					<Box
						sx={{
							height: 'calc(100% - 64px)',
							overflowY: 'auto',
							overflowX: 'hidden',
						}}
					>
						{drawerContents(true)}
					</Box>
				</SwipeableDrawer>
			)}

			<Outlet />
		</Box>
	);
};

export default DataGovernance;
