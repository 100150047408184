import { TWorkspaceReportType } from '../models';
import { IReportScheduleDTO } from '../models/dto';
import { EScheduleFrequency, IReportSchedule } from '../models/schedule.model';
import { formatDate } from '../utils/functions/formatDate';

export const serializeReportSchedule = (
	schedule: IReportScheduleDTO
): IReportSchedule => {
	const time = new Date(
		new Date().toLocaleDateString('en-US').concat(' ', schedule.report_time)
	).toString();

	return {
		startDate: formatDate(schedule.start_date).toString(),
		endDate: schedule.end_date
			? formatDate(schedule.end_date).toString()
			: null,
		frequency: schedule.schedule_info,
		time,
		weekDay: schedule.report_day,
		dayOfMonth: schedule.report_day_number,
		month: schedule.report_month,
		scheduledFor:
			typeof schedule.to_list === 'string'
				? schedule.to_list.split(',')
				: schedule.to_list,
	};
};

export const deserializeScheduleReport = (
	id: number,
	type: TWorkspaceReportType,
	{
		frequency,
		scheduledFor,
		startDate,
		time,
		dayOfMonth,
		endDate,
		month,
		weekDay,
	}: IReportSchedule
): IReportScheduleDTO => {
	return {
		chart: type === 'chart' ? id : null,
		chart_id: type === 'chart' ? id : null,
		dashboard: type === 'dashboard' ? id : null,
		schedule_info: frequency,
		start_date: startDate,
		end_date: endDate,
		report_time: time,
		report_day:
			frequency === EScheduleFrequency.WEEKLY && weekDay ? weekDay : null,
		report_day_number:
			(frequency === EScheduleFrequency.MONTHLY ||
				frequency === EScheduleFrequency.YEARLY) &&
			dayOfMonth
				? dayOfMonth
				: null,
		report_month:
			(frequency === EScheduleFrequency.MONTHLY ||
				frequency === EScheduleFrequency.YEARLY) &&
			month
				? month
				: null,
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		to_list: scheduledFor,
	};
};
