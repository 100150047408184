import { createAction } from '@reduxjs/toolkit';
import {
	IHierarchyBulkUpdatePayload,
	IHierarchyItem,
	IHierarchySingleUpdatePayload,
} from '../../models';
import { IHierarchyKeywordsDTO } from '../../models/dto';

const SLICE_NAME = 'hierarchy';
const KEYWORDS_SLICE_NAME = 'hierarchy/keywords';

export const fetchHierarchies = {
	fetch: createAction(`${SLICE_NAME}/fetch`),
	pending: createAction(`${SLICE_NAME}/pending`),
	rejected: createAction(`${SLICE_NAME}/rejected`),
	fulfilled: createAction<IHierarchyItem[]>(`${SLICE_NAME}/fulfilled`),
};
export const fetchHierarchiesKeywords = {
	fetch: createAction<number>(`${KEYWORDS_SLICE_NAME}/fetch`),
	pending: createAction<number>(`${KEYWORDS_SLICE_NAME}/pending`),
	rejected: createAction<string>(`${KEYWORDS_SLICE_NAME}/rejected`),
	fulfilled: createAction<IHierarchyKeywordsDTO[]>(
		`${KEYWORDS_SLICE_NAME}/fulfilled`
	),
};
export const bulkUpdateHierarchies = {
	update: createAction<IHierarchyBulkUpdatePayload>(`${SLICE_NAME}/bulkUpdate`),
	pending: createAction<number[]>(`${SLICE_NAME}/bulkUpdate/pending`),
	rejected: createAction<number[]>(`${SLICE_NAME}/bulkUpdate/rejected`),
	fulfilled: createAction<IHierarchyItem[]>(
		`${SLICE_NAME}/bulkUpdate/fulfilled`
	),
};
export const updateHierarchy = {
	update: createAction<IHierarchySingleUpdatePayload>(`${SLICE_NAME}/update`),
	pending: createAction<IHierarchySingleUpdatePayload>(
		`${SLICE_NAME}/update/pending`
	),
	rejected: createAction<IHierarchySingleUpdatePayload>(
		`${SLICE_NAME}/update/rejected`
	),
	fulfilled: createAction<IHierarchyItem>(`${SLICE_NAME}/update/fulfilled`),
};
