import { createAction } from '@reduxjs/toolkit';
import {
	IMetricBulkUpdatePayload,
	IMetricSingleUpdatePayload,
	IMetricValidationData,
} from '../../models';
import { IDashboardDTO, IMetricTableDTO, IUsersDTO } from '../../models/dto';

const SLICE_NAME = 'dataValidation/metric';
const USERS_SLICE_NAME = 'dataValidation/users';
const DASHBOARD_SLICE_NAME = 'dataValidation/dashboards';
const METRIC_TABLE_SLICE_NAME = 'dataValidation/tables';

export const fetchMetricValidationItems = {
	fetch: createAction(`${SLICE_NAME}/fetch`),
	pending: createAction(`${SLICE_NAME}/pending`),
	rejected: createAction(`${SLICE_NAME}/rejected`),
	fulfilled: createAction<IMetricValidationData[]>(`${SLICE_NAME}/fulfilled`),
};

export const fetchUsers = {
	fetch: createAction(`${USERS_SLICE_NAME}/fetch`),
	pending: createAction(`${USERS_SLICE_NAME}/pending`),
	rejected: createAction(`${USERS_SLICE_NAME}/rejected`),
	fulfilled: createAction<IUsersDTO[]>(`${USERS_SLICE_NAME}/fulfilled`),
};

export const fetchDashboards = {
	fetch: createAction(`${DASHBOARD_SLICE_NAME}/fetch`),
	pending: createAction(`${DASHBOARD_SLICE_NAME}/pending`),
	rejected: createAction(`${DASHBOARD_SLICE_NAME}/rejected`),
	fulfilled: createAction<IDashboardDTO[]>(`${DASHBOARD_SLICE_NAME}/fulfilled`),
};

export const fetchMetricValidationTables = {
	fetch: createAction(`${METRIC_TABLE_SLICE_NAME}/fetch`),
	pending: createAction(`${METRIC_TABLE_SLICE_NAME}/pending`),
	rejected: createAction(`${METRIC_TABLE_SLICE_NAME}/rejected`),
	fulfilled: createAction<IMetricTableDTO[]>(
		`${METRIC_TABLE_SLICE_NAME}/fulfilled`
	),
};

export const bulkUpdateMetricValidationItems = {
	update: createAction<IMetricBulkUpdatePayload>(`${SLICE_NAME}/bulkUpdate`),
	pending: createAction<number[]>(`${SLICE_NAME}/bulkUpdate/pending`),
	rejected: createAction<number[]>(`${SLICE_NAME}/bulkUpdate/rejected`),
	fulfilled: createAction<IMetricValidationData[]>(
		`${SLICE_NAME}/bulkUpdate/fulfilled`
	),
};

export const updateMetricValidationItem = {
	update: createAction<IMetricSingleUpdatePayload>(`${SLICE_NAME}/update`),
	pending: createAction<IMetricSingleUpdatePayload>(
		`${SLICE_NAME}/update/pending`
	),
	rejected: createAction<IMetricSingleUpdatePayload>(
		`${SLICE_NAME}/update/rejected`
	),
	fulfilled: createAction<IMetricValidationData>(
		`${SLICE_NAME}/update/fulfilled`
	),
};
