import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHierarchyData, IHierarchyValidationStore } from '../../models/store';
import {
	bulkUpdateHierarchies,
	fetchHierarchies,
	fetchHierarchiesKeywords,
	updateHierarchy,
} from '../actions/hierarchyValidation.actions';

const INITIAL_STATE: IHierarchyValidationStore = {
	bulkUpdateStatus: 'idle',
	currentItemOrder: [],
	data: [],
	fetchKeywordsStatus: 'idle',
	fetchStatus: 'idle',
	keywordsData: [],
	keywordsErrorMsg: '',
	originalItemOrder: [],
	singleUpdateStatus: 'idle',
};

const hierarchyValidationSlice = createSlice({
	name: 'hierarchyValidation',
	initialState: INITIAL_STATE,
	reducers: {
		onHierarchySearch: (state, action: PayloadAction<IHierarchyData[]>) => {
			const searchResult = action.payload;
			if (searchResult.length) {
				state.currentItemOrder = searchResult.map((result) => result.id);
			} else {
				state.currentItemOrder = [...state.originalItemOrder];
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchHierarchies.pending, (state) => {
				state.fetchStatus = 'pending';
			})
			.addCase(fetchHierarchies.rejected, (state) => {
				state.fetchStatus = 'rejected';
			})
			.addCase(fetchHierarchies.fulfilled, (state, action) => {
				state.fetchStatus = 'fulfilled';

				const items = action.payload;

				state.data = {};
				state.originalItemOrder = [];
				state.currentItemOrder = [];
				items.forEach((item) => {
					state.data[item.id] = {
						...item,
						isUpdating: false,
					};
					state.originalItemOrder.push(item.id);
					state.currentItemOrder.push(item.id);
				});
			})

			.addCase(fetchHierarchiesKeywords.pending, (state) => {
				state.keywordsData = [];
				state.fetchKeywordsStatus = 'pending';
			})
			.addCase(fetchHierarchiesKeywords.rejected, (state, action) => {
				state.keywordsErrorMsg = action.payload;
				state.fetchKeywordsStatus = 'rejected';
			})
			.addCase(fetchHierarchiesKeywords.fulfilled, (state, action) => {
				state.fetchKeywordsStatus = 'fulfilled';
				state.keywordsData = action.payload;
			})
			.addCase(updateHierarchy.pending, (state, action) => {
				state.data[action.payload.updatingRow].isUpdating = true;
				state.singleUpdateStatus = 'pending';
			})
			.addCase(updateHierarchy.rejected, (state, action) => {
				state.data[action.payload.updatingRow].isUpdating = false;
				state.singleUpdateStatus = 'rejected';
			})
			.addCase(updateHierarchy.fulfilled, (state, action) => {
				const id = action.payload.lastId;

				Object.keys(state.data).forEach((key) => {
					const dataMapKey = +key;
					if (+dataMapKey === id) {
						delete state.data[dataMapKey];
						state.data[action.payload.id] = {
							...action.payload,
							isUpdating: false,
						};
					}
				});

				state.currentItemOrder.forEach((currentItem, index) => {
					if (currentItem === id) {
						state.currentItemOrder[index] = action.payload.id;
					}
				});
				state.originalItemOrder.forEach((currentItem, index) => {
					if (currentItem === id) {
						state.originalItemOrder[index] = action.payload.id;
					}
				});

				state.singleUpdateStatus = 'fulfilled';
			})
			.addCase(bulkUpdateHierarchies.pending, (state, action) => {
				action.payload.forEach((rowId) => {
					state.data[rowId].isUpdating = true;
				});
				state.bulkUpdateStatus = 'pending';
			})
			.addCase(bulkUpdateHierarchies.rejected, (state, action) => {
				action.payload.forEach((rowId) => {
					state.data[rowId].isUpdating = false;
				});
				state.bulkUpdateStatus = 'rejected';
			})
			.addCase(bulkUpdateHierarchies.fulfilled, (state, action) => {
				Object.keys(state.data).forEach((key) => {
					const dataMapKey = +key;
					action.payload.forEach((payloadItem) => {
						if (payloadItem.lastId === dataMapKey) {
							delete state.data[dataMapKey];
							state.data[payloadItem.id] = {
								...payloadItem,
								isUpdating: false,
							};
						}
					});
				});

				action.payload.forEach((payloadItem) => {
					state.currentItemOrder.forEach((currentItem, index) => {
						if (currentItem === payloadItem.lastId) {
							state.currentItemOrder[index] = payloadItem.id;
						}
					});
					state.originalItemOrder.forEach((currentItem, index) => {
						if (currentItem === payloadItem.lastId) {
							state.originalItemOrder[index] = payloadItem.id;
						}
					});
				});
				state.bulkUpdateStatus = 'fulfilled';
			});
	},
});

export const hierarchyValidationActions = {
	...hierarchyValidationSlice.actions,
	bulkUpdateHierarchies,
	fetchHierarchies,
	fetchHierarchiesKeywords,
	updateHierarchy,
};
export default hierarchyValidationSlice.reducer;
