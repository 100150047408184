import { createSlice } from '@reduxjs/toolkit';
import { IUsageLogsStore } from '../../models/usageLogs.model';
import { fetchUsageLogs } from '../actions/usageLogs.actions';

const INITIAL_STATE: IUsageLogsStore = {
	fetchStatus: 'idle',
	data: {
		count: 0,
		next: '',
		previous: '',
		results: [],
	},
};

const usageLogsSlice = createSlice({
	name: 'usageLogs',
	initialState: INITIAL_STATE,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUsageLogs.pending, (state) => {
				state.fetchStatus = 'pending';
			})
			.addCase(fetchUsageLogs.rejected, (state) => {
				state.fetchStatus = 'rejected';
			})
			.addCase(fetchUsageLogs.fulfilled, (state, action) => {
				state.data = action.payload;
				state.fetchStatus = 'fulfilled';
			});
	},
});

export const usageLogsActions = {
	...usageLogsSlice.actions,
	fetchUsageLogs,
};

export default usageLogsSlice.reducer;
