import { ENumberSystem } from '../models';
import {
	ETableImageSize,
	IAdminPreferences,
	ICustomerConfigDetails,
	ICustomerSecretsDetails,
	IUserPreferences,
	TAppConfigurationsFormWithFile,
} from '../models/appSettings.model';
import {
	IAdminPreferencesDTO,
	IAdminPreferencesPayloadDTO,
	ICustomerConfigDetailsDTO,
	ICustomerSecretsDetailsDTO,
	IUserPreferencesDTO,
	IUserPreferencesPayloadDTO,
} from '../models/dto/appSettings.dto.model';
import { adminPreferencesInitialValue } from '../store/reducers/appSettings.reducer';

export const serializeCustomerConfigDetails = (
	details: ICustomerConfigDetailsDTO
): ICustomerConfigDetails => ({
	id: details.id,
	authAllowedDomains: details.auth_allowed_domains,
	ctTaggerAddress: details.ct_tagger_address,
	qtTaggerAddress: details.qt_tagger_address,
	customerLogo: details.customer_logo,
	emailHost: details.email_host,
	emailPort: details.email_port,
	emailUseTLS: details.email_use_tls,
	queryCacheTimeout: details.query_cache_timeout,
});

export const serializeCustomerSecretDetails = (
	details: ICustomerSecretsDetailsDTO
): ICustomerSecretsDetails => ({
	id: details.id,
	emailHostPassword: details.email_host_password,
	emailHostUser: details.email_host_user,

	isFlowzEnabled: details.is_flowz_enabled,
	flowzClientId: details.flowz_client_id,
	flowzClientSecret: details.flowz_client_secret,

	isGoogleEnabled: details.is_google_enabled,
	googleOauth2Key: details.google_oauth2_key,
	googleOauth2Secret: details.google_oauth2_secret,

	isMicrosoftSSOEnabled: details.is_microsoft_sso_enabled,
	microsoftSSOKey: details.microsoft_sso_key,
	microsoftSSOSecret: details.microsoft_sso_secret,
});

export const serializeUserPreferences = (
	details: IUserPreferencesDTO[]
): IUserPreferences => {
	const detailsMap: { [name: string]: IUserPreferencesDTO } = {};
	details.forEach((item) => {
		detailsMap[item.name] = item;
	});

	return {
		numberSystem: detailsMap.lakhs_crores.value
			? ENumberSystem.INDIAN
			: ENumberSystem.INTERNATIONAL,
		numberFormatDecimals: (detailsMap.number_of_decimals.value ||
		detailsMap.number_of_decimals.value === 0
			? detailsMap.number_of_decimals.value
			: detailsMap.number_of_decimals.default) as number,

		tableImageSize: (Number(detailsMap.table_image_size?.value) ||
			Number(detailsMap.table_image_size?.default) ||
			ETableImageSize.MEDIUM) as ETableImageSize,
	};
};

export const serializeAdminPreferences = (
	details: IAdminPreferencesDTO[]
): IAdminPreferences => {
	const detailsMap: { [name: string]: IAdminPreferencesDTO } = {};
	details.forEach((item) => {
		detailsMap[item.name] = item;
	});

	const adminPreferences: IAdminPreferences = {
		...adminPreferencesInitialValue,
	};

	return {
		primaryColor: (detailsMap.theme_primary_colour?.value ||
			detailsMap.theme_primary_colour?.default ||
			adminPreferences.primaryColor) as string,
		secondaryColor: (detailsMap.theme_secondary_colour?.value ||
			detailsMap.theme_secondary_colour?.default ||
			adminPreferences.secondaryColor) as string,
		axisKeywordsLimit: (detailsMap.axis_keywords_limit?.value ||
			detailsMap.axis_keywords_limit?.default ||
			adminPreferences.axisKeywordsLimit) as number,
		numberSystem: detailsMap.lakhs_crores_default?.value
			? ENumberSystem.INDIAN
			: ENumberSystem.INTERNATIONAL,
		numberFormatDecimals:
			detailsMap.number_of_decimals_default?.value ||
			detailsMap.number_of_decimals_default?.value === 0
				? (detailsMap.number_of_decimals_default?.value as number)
				: ((detailsMap.number_of_decimals_default?.default ||
						adminPreferences.numberFormatDecimals) as number),
		queryTimeout: (detailsMap.query_timeout?.value ||
			detailsMap.query_timeout?.default ||
			adminPreferences.queryTimeout) as number,
		dataLimit: (detailsMap.data_truncation_limit?.value ||
			detailsMap.data_truncation_limit?.default ||
			adminPreferences.dataLimit) as number,
		growthInSimpleCation: (detailsMap.growth_simple_caption?.value ||
			detailsMap.growth_simple_caption?.default) as boolean,
		tableImageSize: (Number(detailsMap.table_image_size_default?.value) ||
			Number(detailsMap.table_image_size_default?.default) ||
			adminPreferences.tableImageSize) as ETableImageSize,
		keywordRefreshTime: (detailsMap.keyword_refresh_time?.value ||
			detailsMap.keyword_refresh_time?.default ||
			adminPreferences.keywordRefreshTime) as number,
	};
};

// DESERIALIZER  ---------------------------------------------------------------

export const deSerializeCustomerConfigDetails = (
	form: TAppConfigurationsFormWithFile
): FormData => {
	const formData = new FormData();

	formData.append('ct_tagger_address', form.ctTaggerAddress);
	formData.append('qt_tagger_address', form.qtTaggerAddress);
	formData.append(
		'auth_allowed_domains',
		JSON.stringify(form.authAllowedDomains)
	);
	formData.append('email_host', form.emailHost);
	formData.append('email_port', form.emailPort.toString());
	formData.append('email_use_tls', form.emailUseTLS.toString());
	formData.append('query_cache_timeout', form.queryCacheTimeout.toString());

	if (form.customerLogo) {
		formData.append('customer_logo', form.customerLogo);
	}

	return formData;
};

export const deSerializeCustomerSecretDetails = (
	form: TAppConfigurationsFormWithFile
): FormData => {
	const formData = new FormData();

	formData.append('is_google_enabled', form.isGoogleEnabled.toString());

	if (form.isGoogleEnabled && form.googleOauth2Key && form.googleOauth2Secret) {
		formData.append('google_oauth2_key', form.googleOauth2Key || '');
		formData.append('google_oauth2_secret', form.googleOauth2Secret || '');
	}

	formData.append(
		'is_microsoft_sso_enabled',
		form.isMicrosoftSSOEnabled.toString()
	);

	if (
		form.isMicrosoftSSOEnabled &&
		form.microsoftSSOKey &&
		form.microsoftSSOKey
	) {
		formData.append('microsoft_sso_key', form.microsoftSSOKey || '');
		formData.append('microsoft_sso_secret', form.microsoftSSOSecret || '');
	}

	formData.append('is_flowz_enabled', form.isFlowzEnabled.toString());

	if (form.isFlowzEnabled && form.flowzClientId && form.flowzClientSecret) {
		formData.append('flowz_client_id', form.flowzClientId || '');
		formData.append('flowz_client_secret', form.flowzClientSecret || '');
	}

	formData.append('email_host_user', form.emailHostUser);
	formData.append('email_host_password', form.emailHostPassword);

	return formData;
};

const getUserPreferencesValues = (
	key: string,
	value: string | number | boolean
) => {
	switch (key) {
		case 'numberSystem':
			return { name: 'lakhs_crores', value: value === 'indian' };
		case 'numberFormatDecimals':
			return { name: 'number_of_decimals', value };
		case 'tableImageSize':
			return { name: 'table_image_size', value };

		default:
			return { name: '', value: '' };
	}
};

export const deSerializeUserPreferences = (
	items: IUserPreferences
): IUserPreferencesPayloadDTO => {
	const payload: IUserPreferencesPayloadDTO = {};

	Object.entries(items).forEach(([key, ele]) => {
		const { name, value } = getUserPreferencesValues(key, ele);
		payload[name] = value;
	});
	return payload;
};

const getAdminPreferencesValues = (
	key: string,
	value: string | number | boolean
) => {
	switch (key) {
		case 'primaryColor':
			return { name: 'theme_primary_colour', value };
		case 'secondaryColor':
			return { name: 'theme_secondary_colour', value };

		case 'axisKeywordsLimit':
			return { name: 'axis_keywords_limit', value };

		case 'numberSystem':
			return { name: 'lakhs_crores_default', value: value === 'indian' };
		case 'numberFormatDecimals':
			return { name: 'number_of_decimals_default', value };

		case 'queryTimeout':
			return { name: 'query_timeout', value };
		case 'dataLimit':
			return { name: 'data_truncation_limit', value };

		case 'growthInSimpleCation':
			return { name: 'growth_simple_caption', value };
		case 'tableImageSize':
			return { name: 'table_image_size_default', value };

		case 'keywordRefreshTime':
			return { name: 'keyword_refresh_time', value };

		default:
			return { name: '', value: '' };
	}
};

export const deSerializeAdminPreferences = (
	items: IAdminPreferences
): IAdminPreferencesPayloadDTO => {
	const payload: IAdminPreferencesPayloadDTO = {};

	Object.entries(items).forEach(([key, ele]) => {
		const { name, value } = getAdminPreferencesValues(key, ele);
		payload[name] = value;
	});
	return payload;
};

// END: DESERIALIZER  ----------------------------------------------------------
