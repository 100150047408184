import { createAction } from '@reduxjs/toolkit';
import {
	IAllItems,
	IChartsData,
	IFiltersPayload,
	IPinnedChartData,
	IUserDetails,
} from '../../models';
import { IReportSchedule } from '../../models/schedule.model';
import {
	IWorkspaceDashboardDetails,
	IWorkspaceDashboardPayload,
	IWorkSpaceDashboardUpdatePayload,
} from '../../models/workspaceDashboard.model';

const SLICE_NAME = 'workspaceDashboard';

export const fetchWorkspaceDashboardDetails = {
	fetch: createAction<number>(`${SLICE_NAME}/details/fetch`),
	pending: createAction(`${SLICE_NAME}/details/fetch/pending`),
	fulfilled: createAction<{
		pinnedChartsData: IPinnedChartData[];
		dashboardDetails: IWorkspaceDashboardDetails;
	}>(`${SLICE_NAME}/details/fetch/fulfilled`),
	rejected: createAction(`${SLICE_NAME}/details/fetch/rejected`),
};

export const fetchDashboardTaskIds = {
	fetch: createAction<{
		dashboardId: number;
		taskId?: string;
		filters?: IFiltersPayload[];
		secret?: string;
		isPublic?: boolean;
	}>(`${SLICE_NAME}/publicDashboard/fetch`),
	pending: createAction(`${SLICE_NAME}/publicDashboard/fetch/pending`),
	fulfilled: createAction(`${SLICE_NAME}/publicDashboard/fetch/fulfilled`),
	rejected: createAction(`${SLICE_NAME}/publicDashboard/fetch/rejected`),
};

export const fetchDashboardGetData = {
	fetch: createAction<{
		id: number;
		taskId?: string;
		chartId?: number;
		isPublic?: boolean;
	}>(`${SLICE_NAME}/getData/fetch`),
	pending: createAction<number>(`${SLICE_NAME}/getData/fetch/pending`),
	rejected: createAction<number>(`${SLICE_NAME}/getData/fetch/rejected`),
	fulfilled: createAction<{ data: IChartsData; chartId: number }>(
		`${SLICE_NAME}/getData/fetch/fulfilled`
	),
};

export const fetchDashboardFiltersSubCall = {
	fetch: createAction<{
		filters: IFiltersPayload[] | null;
		dashboardId: number;
		chartId: number;
		taskId: string;
	}>(`${SLICE_NAME}/subCallGetData/fetch`),
	pending: createAction<boolean>(`${SLICE_NAME}/subCallGetData/fetch/pending`),
	rejected: createAction<number>(`${SLICE_NAME}/subCallGetData/fetch/rejected`),
	fulfilled: createAction<{ data: IChartsData; chartId: number }>(
		`${SLICE_NAME}/subCallGetData/fetch/fulfilled`
	),
};

export const fetchDashboardAllItemsData = {
	fetch: createAction(`${SLICE_NAME}/allItems/fetch`),
	pending: createAction(`${SLICE_NAME}/allItems/fetch/pending`),
	rejected: createAction(`${SLICE_NAME}/allItems/fetch/rejected`),
	fulfilled: createAction<IAllItems>(`${SLICE_NAME}/allItems/fetch/fulfilled`),
};

export const updateWorkspaceDashboardDetails = {
	update: createAction<IWorkSpaceDashboardUpdatePayload>(
		`${SLICE_NAME}/details/update`
	),
	pending: createAction(`${SLICE_NAME}/details/update/pending`),
	rejected: createAction(`${SLICE_NAME}/details/update/rejected`),
	fulfilled: createAction<{
		pinnedChartsData: IPinnedChartData[];
		dashboardDetails: IWorkspaceDashboardDetails;
	}>(`${SLICE_NAME}/details/update/fulfilled`),
};

export const updateWorkspaceDashboardChartDetails = {
	update: createAction<{
		data: IWorkspaceDashboardPayload[];
		dashboardId: number;
		isSaveAsNew: boolean;
	}>(`${SLICE_NAME}/chartDetails/update`),
	pending: createAction(`${SLICE_NAME}/chartDetails/update/pending`),
	rejected: createAction(`${SLICE_NAME}/chartDetails/update/rejected`),
	fulfilled: createAction<{ data: IPinnedChartData[]; isSaveAsNew: boolean }>(
		`${SLICE_NAME}/chartDetails/update/fulfilled`
	),
};

export const scheduleDashboard = {
	schedule: createAction<{ dashboardId: number; data: IReportSchedule }>(
		`${SLICE_NAME}/schedule/`
	),
	pending: createAction(`${SLICE_NAME}/schedule/pending`),
	rejected: createAction(`${SLICE_NAME}/schedule/rejected`),
	fulfilled: createAction<IReportSchedule>(`${SLICE_NAME}/schedule/fulfilled`),
};

export const shareDashboard = {
	share: createAction<{ dashboardId: number; userDetails: IUserDetails[] }>(
		`${SLICE_NAME}/share/`
	),
	pending: createAction(`${SLICE_NAME}/share/pending`),
	rejected: createAction(`${SLICE_NAME}/share/rejected`),
	fulfilled: createAction<{
		pinnedChartsData: IPinnedChartData[];
		dashboardDetails: IWorkspaceDashboardDetails;
	}>(`${SLICE_NAME}/share/fulfilled`),
};
