// libraries imports
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// mui imports
import { Box } from '@mui/material';

// project imports
import SideDrawer from './components/SideDrawer';
import BottomBar from './components/BottomBar';
import useScreenSize from '../../hooks/useScreenSize';

// ====================|| ROLE BASED ACCESS ||=================================//
const RoleBasedAccess: React.FC = () => {
	// states
	const [open, setOpen] = useState(true);

	// hooks
	const { isTabletOrAbove, isMobileOrAbove } = useScreenSize();

	// event handlers
	const handleDrawerToggle = () => {
		setOpen((prev) => !prev);
	};

	// use effects
	useEffect(() => {
		if (!isTabletOrAbove) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	}, [isTabletOrAbove]);

	return (
		<>
			<Box sx={{ display: 'flex', pb: { xs: 7, sm: 0 } }}>
				{isMobileOrAbove && (
					<SideDrawer open={open} handleDrawerToggle={handleDrawerToggle} />
				)}

				<Box
					component="main"
					sx={{
						flexGrow: 1,
						width: `calc(100% - ${open ? '280px' : '60px'})`,
						px: 1,
						pt: 2,
					}}
				>
					<Outlet />
				</Box>
			</Box>
			{!isMobileOrAbove && <BottomBar />}
		</>
	);
};

export default RoleBasedAccess;
