import { IUser } from '../models';
import { IUserDTO } from '../models/dto';

export const serializeUser = (user: IUserDTO): IUser => ({
	id: user.id,
	email: user.email,
	firstName: user.first_name,
	lastName: user.last_name,
	username: user.username,
});

export const serializeUsers = (users: IUserDTO[]): IUser[] =>
	users.map((user) => serializeUser(user));
