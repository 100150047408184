// libraries imports
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// mui imports
import {
	Box,
	Divider,
	ListItemIcon,
	Menu,
	MenuItem,
	Paper,
	Typography,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// project imports
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectNavbarEssentials } from '../../../store/selectors';
import { authActions } from '../../../store/reducers/auth.reducer';
import OrcablueIcon from '../../icons/OrcablueIcon';

// ==================|| CUSTOMER LOGO AND DROPDOWN MODAL ||===================//
const NavbarDropdown: React.FC = () => {
	// states
	const [isOpen, setIsOpen] = useState(false);

	// ref
	const anchorElRef = useRef<HTMLDivElement | null>(null);

	// redux
	const dispatch = useAppDispatch();
	const { email, appVersion, userName } = useAppSelector(
		selectNavbarEssentials
	);

	// event handlers
	const onClickDropdownButton = () => {
		setIsOpen((prev) => !prev);
	};

	const onClickMenuItem = () => {
		setIsOpen(false);
	};

	const onClickLogout = () => {
		setIsOpen(false);
		dispatch(authActions.logout.pending());
	};

	return (
		<>
			<Paper
				onClick={onClickDropdownButton}
				aria-controls="menu-appbar"
				sx={{
					display: {
						xs: 'none',
						md: 'flex',
					},
					px: 1,
					py: 0.5,
					minWidth: '5rem',
					justifyContent: 'space-between',
					height: '60%',
					alignItems: 'center',
					marginLeft: 'auto',
					cursor: 'pointer',
				}}
				ref={anchorElRef}
				data-testid="clientLogo"
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<object data="/ddive/api/company_logo/" height="24" type="image/png">
						<img
							src="/static/images/logos/newDefaultLogo.svg"
							alt="Client Logo"
							height="24"
						/>
					</object>
				</Box>
				{isOpen ? (
					<ArrowDropUpIcon fontSize="large" />
				) : (
					<ArrowDropDownIcon fontSize="large" />
				)}
			</Paper>

			<Menu
				id="navbar-menu"
				anchorEl={anchorElRef.current}
				keepMounted
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={isOpen}
				onClose={onClickMenuItem}
				sx={{
					mt: 2,
					'& .MuiPaper-root': {
						minWidth: '15rem',
					},
				}}
			>
				<MenuItem
					onClick={onClickMenuItem}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'start',
					}}
					data-testid="userDetails"
				>
					<span>{userName}</span>
					<Typography variant="caption">{email}</Typography>
				</MenuItem>

				<Divider />

				<MenuItem
					onClick={onClickMenuItem}
					component="a"
					href="https://orcablue.zendesk.com/hc/en-us"
					target="/blank"
					data-testid="zendesk"
				>
					<ListItemIcon>
						<LinkIcon />
					</ListItemIcon>
					Zendesk
				</MenuItem>

				<MenuItem
					data-testid="settings"
					onClick={onClickMenuItem}
					component={Link}
					to="/settings"
				>
					<ListItemIcon>
						<SettingsIcon />
					</ListItemIcon>
					Settings
				</MenuItem>

				<MenuItem onClick={onClickLogout} data-testid="logOut">
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					Logout
				</MenuItem>

				<Divider />

				<MenuItem
					data-testid="releaseNotes"
					onClick={onClickMenuItem}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'start',
					}}
					component={Link}
					to="/releaseNotes"
				>
					<Typography
						component="div"
						variant="caption"
						sx={{
							display: 'inline-block',
							alignItems: 'baseline',
							'& > *': {
								verticalAlign: 'middle',
							},
						}}
					>
						<span>Powered by</span>
						<OrcablueIcon
							color="primary"
							fontSize="small"
							sx={{ ml: 1, mr: 0.5 }}
						/>
						<span>Orcablue</span>
					</Typography>
					<Typography variant="caption">DDive v{appVersion}</Typography>
				</MenuItem>
			</Menu>
		</>
	);
};

export default NavbarDropdown;
