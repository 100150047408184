// libraries imports
import { useEffect, useRef, useMemo } from 'react';

// types
interface IProps {
	path: string;
}

// ===========================|| CONFIG-UI ||=================================//
const Config: React.FC<IProps> = ({ path }) => {
	// ref
	const queryParams = useRef(window.location.search);

	// hooks
	const rootUrl = useMemo(() => {
		const env = process.env.NODE_ENV;

		if (env === 'production') {
			return window?.location?.origin || '/';
		}

		return process.env.REACT_APP_CONFIG_UI_ORIGIN;
	}, []);

	// use effect
	useEffect(() => {
		window.location.replace(
			`${rootUrl}/configure/${path}${queryParams.current}`
		);
	}, [path, rootUrl]);

	return null;
};

export default Config;
