import { TWorkspaceReportType } from '../../models';
import { IReportScheduleDTO } from '../../models/dto';
import { IReportSchedule } from '../../models/schedule.model';
import {
	deserializeScheduleReport,
	serializeReportSchedule,
} from '../../serializers/schedule.serializer';
import axiosInstance from '../axios/axios';

export const scheduleReportApi = async (
	id: number,
	type: TWorkspaceReportType,
	data: IReportSchedule
) => {
	const body = deserializeScheduleReport(id, type, data);
	const scheduleDTO = await axiosInstance
		.post<IReportScheduleDTO>('ddive/api/schedule/', body)
		.then((response) => response.data);

	const schedule = serializeReportSchedule(scheduleDTO);

	return schedule;
};
