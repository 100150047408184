import React from 'react';
import { Subject } from 'rxjs';
import { AlertColor, SnackbarProps } from '@mui/material';

export interface ISnackbarProps extends SnackbarProps {
	severity?: AlertColor;
}

class SnackbarService {
	private showSnackbar = new Subject<ISnackbarProps>();

	showSnackbar$ = this.showSnackbar.asObservable();

	private createToast(
		message: React.ReactNode,
		options?: ISnackbarProps
	): void {
		this.showSnackbar.next({
			message,
			anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
			autoHideDuration: 4000,
			...options,
		});
	}

	showToast(message: React.ReactNode, options?: ISnackbarProps) {
		this.createToast(message, options);
	}

	showSuccessToast(message: React.ReactNode) {
		this.createToast(message, { severity: 'success' });
	}

	showErrorToast(message: React.ReactNode) {
		this.createToast(message, { severity: 'error' });
	}

	showInfoToast(message: React.ReactNode) {
		this.createToast(message, { severity: 'info' });
	}

	showWarningToast(message: React.ReactNode) {
		this.createToast(message, { severity: 'warning' });
	}
}

export const snackbarService = new SnackbarService();
