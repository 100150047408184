// libraries imports
import React from 'react';
import { Outlet } from 'react-router-dom';

// mui imports
import { Container } from '@mui/material';

// ==========================|| APP LAYOUT ||=================================//
const AppLayout: React.FC = () => {
	return (
		<Container
			maxWidth={false}
			sx={{ flexGrow: 1, mt: 8, padding: { xs: 1, sm: 2 }, overflowY: 'auto' }}
		>
			<Outlet />
		</Container>
	);
};

export default AppLayout;
