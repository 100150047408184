import { ITaskStatusResponse } from '../models/asyncTask.model';
import axiosInstance from './axios/axios';

export const asyncTaskApi = async (taskId: string, isPublic?: boolean) => {
	const endpoint = isPublic ? `getTaskStatusPublic` : `getTaskStatus`;
	const responseStatus = await axiosInstance
		.get<ITaskStatusResponse>(`ddive/api/${endpoint}/${taskId}/`)
		.then((response) => response.data);

	if (responseStatus.error && responseStatus.status === 'FAILURE') {
		throw new Error(responseStatus.error);
	}

	return responseStatus;
};
