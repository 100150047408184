import { createAction } from '@reduxjs/toolkit';
import {
	IWorkspaceCreateDashboardPayload,
	IWorkspaceFetchOptions,
	IWorkspaceFetchResult,
	IReport,
	IUserDetails,
	IWorkspaceDashboard,
	IUpdateChartDetailsPayload,
	IWorkspaceChart,
	IUpdateDashboardDetailsPayload,
} from '../../models';
import { IReportSchedule } from '../../models/schedule.model';

export const fetchReports = {
	fetch: createAction<IWorkspaceFetchOptions | undefined>(
		'workspace/fetchReports/fetch'
	),
	pending: createAction('workspace/fetchReports/pending'),
	rejected: createAction('workspace/fetchReports/rejected'),
	fulfilled: createAction<IWorkspaceFetchResult>(
		'workspace/fetchReports/fulfilled'
	),
};

export const createDashboard = {
	create: createAction<IWorkspaceCreateDashboardPayload>(
		'workspace/createDashboard/create'
	),
	pending: createAction('workspace/createDashboard/pending'),
	rejected: createAction('workspace/createDashboard/rejected'),
	fulfilled: createAction<IWorkspaceDashboard>(
		'workspace/createDashboard/fulfilled'
	),
};

export const shareReport = {
	share: createAction<{ uiId: string; userDetails: IUserDetails[] }>(
		'workspace/shareReport/share'
	),
	pending: createAction<{ uiId: string }>('workspace/shareReport/pending'),
	rejected: createAction<{ uiId: string }>('workspace/shareReport/rejected'),
	fulfilled: createAction<IReport>('workspace/shareReport/fulfilled'),
};

export const deleteReport = {
	delete: createAction<{ uiId: string }>('workspace/deleteReport/delete'),
	pending: createAction<{ uiId: string }>('workspace/deleteReport/pending'),
	rejected: createAction<{ uiId: string }>('workspace/deleteReport/rejected'),
	fulfilled: createAction<IReport>('workspace/deleteReport/fulfilled'),
};

export const scheduleReport = {
	schedule: createAction<{ uiId: string; data: IReportSchedule }>(
		'workspace/scheduleReport/schedule'
	),
	pending: createAction<{ uiId: string }>('workspace/scheduleReport/pending'),
	rejected: createAction<{ uiId: string }>('workspace/scheduleReport/rejected'),
	fulfilled: createAction<{ uiId: string; schedule: IReportSchedule }>(
		'workspace/scheduleReport/fulfilled'
	),
};

export const updateChartDetails = {
	update: createAction<IUpdateChartDetailsPayload>(
		'workspace/updateChartDetails/update'
	),
	pending: createAction('workspace/updateChartDetails/pending'),
	rejected: createAction('workspace/updateChartDetails/rejected'),
	fulfilled: createAction<IWorkspaceChart>(
		'workspace/updateChartDetails/fulfilled'
	),
};

export const updateDashboardDetails = {
	update: createAction<IUpdateDashboardDetailsPayload>(
		'workspace/updateDashboardDetails/update'
	),
	pending: createAction('workspace/updateDashboardDetails/pending'),
	rejected: createAction('workspace/updateDashboardDetails/rejected'),
	fulfilled: createAction<IWorkspaceDashboard>(
		'workspace/updateDashboardDetails/fulfilled'
	),
};
