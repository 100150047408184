import { Action, isAnyOf } from '@reduxjs/toolkit';
import { combineEpics, Epic } from 'redux-observable';
import { from, map, switchMap, filter, catchError, of, startWith } from 'rxjs';

import { authenticate, logout } from '../../api/auth.api';
import { IAppState } from '../../models/store';
import { appStarted } from '../actions';
import { authActions } from '../reducers/auth.reducer';

const checkLoginEpic: Epic<Action, Action, IAppState> = (action$) => {
	return action$.pipe(
		filter(isAnyOf(appStarted)),
		switchMap(() => {
			return from(authenticate()).pipe(
				map((response) => {
					return authActions.autoLogin.fulfilled(response);
				}),
				catchError(() => of(authActions.autoLogin.rejected())),
				startWith(authActions.autoLogin.pending)
			);
		})
	);
};

const logoutEpic: Epic<Action, Action, IAppState> = (action$) => {
	return action$.pipe(
		filter(isAnyOf(authActions.logout.pending)),
		switchMap(() => {
			return from(logout()).pipe(
				map(() => authActions.logout.fulfilled()),
				catchError(() => of(authActions.logout.rejected()))
			);
		})
	);
};

export const authEpic = combineEpics(checkLoginEpic, logoutEpic);
