import {
	IFiltersPayload,
	IWorkspaceDashboardPayload,
	IWorkSpaceDashboardUpdatePayload,
} from '../models';
import {
	IChartsDataDTO,
	IFiltersPayloadDTO,
	IPinnedChartDataDTO,
} from '../models/dto';
import {
	IAsyncTaskDashboardSubCallDTO,
	IAsyncTaskDTO,
} from '../models/dto/asyncTask.dto.model';
import {
	IWorkspaceDashboardDetailsDTO,
	IWorkSpaceDashboardUpdatePayloadDTO,
} from '../models/dto/workspaceDashboard.dto.model';
import {
	deserializeFilterPayload,
	serializeChartsData,
	serializePinnedChartsData,
} from '../serializers/charts.serializer';
import {
	deSerializeWorkspaceDashboardPayloadItems,
	deSerializeWorkspaceDashboardUpdatePayload,
	serializeWorkspaceDashboardDetails,
} from '../serializers/workspaceDashboard.serializer';
import axiosInstance from './axios/axios';

export const fetchWorkspaceDashboardDetailsApi = async (
	dashboardId: number
) => {
	const apiResponse = await axiosInstance
		.get<IWorkspaceDashboardDetailsDTO>(`ddive/api/dashboard/${dashboardId}/`)
		.then((response) => response.data);

	const result = serializeWorkspaceDashboardDetails(apiResponse);

	return result;
};

export const updateDashboardIsPublic = async ({
	dashboardId,
	isPublic = true,
}: {
	dashboardId: number;
	isPublic: boolean;
}) => {
	const apiResponse = await axiosInstance
		.patch(`ddive/api/dashboard/${dashboardId}/`, {
			is_public: isPublic,
		})
		.then((response) => response.data);
	return apiResponse;
};

export const getDashboardTaskIdsApi = async (payload: {
	dashboardId?: number;
	taskId?: string;
	filters?: IFiltersPayload[];
	secret?: string;
	isPublic?: boolean;
}) => {
	const { dashboardId, taskId, filters, secret, isPublic } = payload;
	const getDataEndpoint = isPublic ? 'get_data_public' : 'get_data';
	const taskStatusEndpoint = isPublic ? 'getTaskStatusPublic' : 'getTaskStatus';

	if (taskId) {
		const data = await axiosInstance
			.get<IChartsDataDTO>(`ddive/api/${taskStatusEndpoint}/${taskId}/`)
			.then((response) => response.data);
		return data;
	}

	if (filters?.length) {
		const filtersPayload: IFiltersPayloadDTO[] = filters.map((item) =>
			deserializeFilterPayload(item)
		);
		const postResponseTaskId = await axiosInstance
			.post<IAsyncTaskDTO>(
				`ddive/api/dashboard/${dashboardId}/${getDataEndpoint}/`,
				{
					filters: filtersPayload,
					shared_secret: secret,
				}
			)
			.then((response) => response.data);
		return postResponseTaskId;
	}

	const responseTaskId = await axiosInstance
		.get<IAsyncTaskDTO>(
			`ddive/api/dashboard/${dashboardId}/${getDataEndpoint}/`
		)
		.then((response) => response.data);

	return responseTaskId;
};

export const updateWorkspaceDashboardChartDetailsApi = async (
	payload: IWorkspaceDashboardPayload[],
	dashboardId: number
) => {
	const apiPayload = deSerializeWorkspaceDashboardPayloadItems(payload);
	const apiResponse = await axiosInstance
		.post<IPinnedChartDataDTO[]>(
			`ddive/api/dashboard/${dashboardId}/update/`,
			apiPayload
		)
		.then((response) => response.data);

	const result = serializePinnedChartsData(apiResponse);

	return result;
};

export const updateWorkspaceDashboardDetailsApi = async (
	payload: IWorkSpaceDashboardUpdatePayload
) => {
	const payloadItems: IWorkSpaceDashboardUpdatePayloadDTO =
		deSerializeWorkspaceDashboardUpdatePayload(payload);
	const apiResponse = await axiosInstance
		.patch(`ddive/api/dashboard/${payload.id}/`, payloadItems)
		.then((response) => response.data);

	const result = serializeWorkspaceDashboardDetails(apiResponse);

	return result;
};

export const dashboardSubCallApi = async (
	filters: IFiltersPayload[] | null,
	dashboardId: number,
	taskId: string,
	chartId: number
) => {
	if (filters) {
		const payloadItems = {
			filters: filters.map((item) => deserializeFilterPayload(item)),
		};

		const apiResponse = await axiosInstance
			.post<IAsyncTaskDashboardSubCallDTO>(
				`ddive/api/dashboard/${dashboardId}/subcall/`,
				payloadItems
			)
			.then((response) => response.data);

		return apiResponse;
	}
	if (taskId) {
		const data = await axiosInstance
			.get<IChartsDataDTO>(`ddive/api/getDataResponse/${taskId}/`)
			.then((response) => response.data);
		const serializedResult = serializeChartsData(data);
		return { data: serializedResult, chartId };
	}
	return '';
};
