import { IShareDiveChartPayload, IShareReport } from '../models';
import { IShareDiveChartPayloadDTO, IShareReportDTO } from '../models/dto';

export const deserializeShareReportData = (
	data: IShareReport
): IShareReportDTO => ({ shared_user_ids: [...data.userDetails] });

export const deserializeShareDiveChartPayload = (
	items: IShareDiveChartPayload
): IShareDiveChartPayloadDTO => ({
	id: items.id,
	shared_user_ids: items.sharedWith.map((user) => ({
		id: user.id,
		email: user.email,
	})),
});
