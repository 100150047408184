import { createAction } from '@reduxjs/toolkit';
import { IUsageLogsData, IUsageLogsParams } from '../../models/usageLogs.model';

const SLICE_NAME = 'usageLogs';

export const fetchUsageLogs = {
	fetch: createAction<IUsageLogsParams | undefined>(`${SLICE_NAME}/fetch`),
	pending: createAction(`${SLICE_NAME}/fetch/pending`),
	fulfilled: createAction<IUsageLogsData>(`${SLICE_NAME}/fetch/fulfilled`),
	rejected: createAction(`${SLICE_NAME}/fetch/rejected`),
};
