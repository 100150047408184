// libraries imports
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import posthog from 'posthog-js';

// mui imports
import {
	Box,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
	Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// project imports
import { links } from '../AppNavbar/AppNavbar';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { authActions } from '../../../store/reducers/auth.reducer';
import { selectNavbarEssentials } from '../../../store/selectors';

// types
interface ISideDrawerProps {
	open: boolean;
	handleOpen: () => void;
	handleClose: () => void;
}

// ========================|| SIDE DRAWER ||==================================//
const SideDrawer: React.FC<ISideDrawerProps> = ({
	open,
	handleOpen,
	handleClose,
}) => {
	// redux
	const dispatch = useAppDispatch();
	const { email, appVersion } = useAppSelector(selectNavbarEssentials);

	// hooks
	const location = useLocation();
	const navigate = useNavigate();
	const pathName = useMemo(() => {
		return location.pathname.split('/');
	}, [location.pathname]);

	// event handlers
	const onClickLogout = () => {
		dispatch(authActions.logout.pending());
		posthog.reset(true);
	};
	const handleClick = (path: string) => {
		handleClose();
		navigate(path);
	};

	// return null for public charts
	if (pathName[2] === 'chartWithFilters') {
		return null;
	}

	return (
		<SwipeableDrawer
			open={open}
			onOpen={handleOpen}
			onClose={handleClose}
			ModalProps={{
				keepMounted: true, // Better open performance on mobile.
			}}
			PaperProps={{
				sx: {
					width: 280,
				},
			}}
		>
			<List sx={{ flexGrow: 1, overflowY: 'auto' }}>
				<ListItem onClick={handleClose}>
					<ListItemIcon>
						<object
							data="/ddive/api/company_logo/"
							type="image/png"
							style={{ height: '48px', width: '48px' }}
						>
							<img
								data-testid="clientLogo"
								src="/static/images/logos/newDefaultLogo.svg"
								alt="Client Logo"
								style={{ height: '100%', width: '100%' }}
							/>
						</object>
					</ListItemIcon>
				</ListItem>

				<ListItem>
					<ListItemText primary={email} />
				</ListItem>

				<Divider />

				{links.map(({ title, Icon, path }) => {
					return (
						<ListItem
							onClick={() => handleClick(path)}
							disablePadding
							key={title}
						>
							<ListItemButton>
								<ListItemIcon>
									<Icon />
								</ListItemIcon>
								<ListItemText primary={title} />
							</ListItemButton>
						</ListItem>
					);
				})}

				<Divider />

				<ListItem onClick={() => handleClick('/settings')} disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText primary="Settings" />
					</ListItemButton>
				</ListItem>

				<ListItem onClick={onClickLogout} disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary="Logout" />
					</ListItemButton>
				</ListItem>
			</List>

			<Box
				component="footer"
				sx={{ display: 'flex', justifyContent: 'end', p: 1 }}
			>
				<Typography>v {appVersion}</Typography>
			</Box>
		</SwipeableDrawer>
	);
};

export default SideDrawer;
