import React from 'react';
import { createSvgIcon } from '@mui/material';

const FAQIcon = createSvgIcon(
	<svg viewBox="0 0 32 32">
		<path d="M14.8 23.2h2.4v-2.4h-2.4v2.4zM16 4C9.376 4 4 9.376 4 16s5.376 12 12 12 12-5.376 12-12S22.624 4 16 4zm0 21.6c-5.292 0-9.6-4.308-9.6-9.6s4.308-9.6 9.6-9.6 9.6 4.308 9.6 9.6-4.308 9.6-9.6 9.6zm0-16.8a4.799 4.799 0 0 0-4.8 4.8h2.4c0-1.32 1.08-2.4 2.4-2.4 1.32 0 2.4 1.08 2.4 2.4 0 2.4-3.6 2.1-3.6 6h2.4c0-2.7 3.6-3 3.6-6 0-2.652-2.148-4.8-4.8-4.8z" />
	</svg>,
	'FAQ'
);

export default FAQIcon;
