import React from 'react';
import { Box, Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { LoadingButton } from '@mui/lab';

const PageFallback = () => {
	const handleReload = () => {
		window.location.reload();
	};

	return (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				m: 2,
				p: 2,
				borderRadius: 2,
				flexDirection: 'column',
			}}
		>
			<Typography variant="h6" sx={{ color: 'secondary.main' }}>
				Something went wrong. Please try again.
			</Typography>
			<Box
				sx={{
					mt: 2,
					display: 'flex',
					flexDirection: { xs: 'column', md: 'row' },
				}}
			>
				<LoadingButton
					onClick={handleReload}
					startIcon={<ReplayIcon />}
					variant="outlined"
				>
					Reload page
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default PageFallback;
