import {
	IAxisDetailsItems,
	IDataModelGraph,
	IHierarchyDetailsItems,
	IMetricDetailsItems,
	INavigatorAxis,
	INavigatorMetric,
	INavigatorTable,
	IReservedKeywordsItems,
	ITableAxis,
	ITableDetailsData,
	ITableDiagramDetails,
	ITableMetrics,
	ITableReference,
} from '../models';
import {
	IAxisDetailsItemsDTO,
	IDataSummarySearchResponseDTO,
	IHierarchyDetailsItemDTO,
	IHierarchyDetailsItemsDTO,
	IMetricDetailsItemsDTO,
	IModelDetailsItemDTO,
	IModelGraphFetchResponse,
	IReferenceDetailsItemDTO,
	ITableDetailsDataDTO,
	ITableDetailsItemsDTO,
	ITableDiagramDetailsDTO,
} from '../models/dto';
import {
	serializeAxisDetailsItem,
	serializeAxisDetailsItems,
	serializeDataModelEdges,
	serializeDataModelNodes,
	serializeHierarchyDetailsItems,
	serializeMetricsDetailsItem,
	serializeMetricsDetailsItems,
	serializeModelDetailsItem,
	serializeNavigatorAxisItems,
	serializeNavigatorHierarchyItems,
	serializeNavigatorMetricItems,
	serializeNavigatorModelItems,
	serializeNavigatorReferenceItems,
	serializeNavigatorSearchResult,
	serializeNavigatorTableItems,
	serializeReferenceDetailsItem,
	serializeTableAxisItems,
	serializeTableDiagramEdges,
	serializeTableDiagramNodes,
	serializeTableDiagramReferences,
	serializeTableMetricItems,
	serializeTableReferenceItems,
	serializeTablesDetailsData,
} from '../serializers';
import axiosInstance from './axios/axios';

// DATA MODEL ------------------------------------------------------------------
export const fetchDataModels = async () => {
	const response = await axiosInstance.get<IModelDetailsItemDTO[]>(
		'api/data-summary/data_source/',
		{ baseURL: '/ddive' }
	);
	const modelItems = serializeNavigatorModelItems(response.data);
	return modelItems;
};

export const fetchDataModel = async (modelId: number) => {
	const response = await axiosInstance.get<IModelDetailsItemDTO>(
		`api/data-summary/data_source/${modelId}/`,
		{ baseURL: '/ddive' }
	);

	const model = serializeModelDetailsItem(response.data);
	return model;
};

export const fetchModelGraph = async (
	modelId: number
): Promise<IDataModelGraph> => {
	const result = await axiosInstance
		.get<IModelGraphFetchResponse>('api/data-summary/diagram/', {
			baseURL: '/ddive',
			params: { source: modelId },
		})
		.then((response) => response.data);

	const nodes = serializeDataModelNodes(result.tables);
	const edges = serializeDataModelEdges(result.references);
	const graph: IDataModelGraph = { nodes, edges };

	return graph;
};

export const searchDataSummary = async (query: string) => {
	const response = await axiosInstance.get<IDataSummarySearchResponseDTO>(
		'api/data-summary/search/',
		{ baseURL: '/ddive', params: { q: query } }
	);

	const searchResult = serializeNavigatorSearchResult(response.data);
	return searchResult;
};

export const fetchTableMetrics = async (
	tableId: number
): Promise<ITableMetrics[]> => {
	const response = await axiosInstance.get<IMetricDetailsItemsDTO[]>(
		`api/data-summary/measure_metadata/`,
		{ baseURL: '/ddive', params: { table: tableId } }
	);
	const metricItems = serializeTableMetricItems(response.data);
	return metricItems;
};

export const fetchTableAxes = async (
	tableId: number
): Promise<ITableAxis[]> => {
	const response = await axiosInstance.get<IAxisDetailsItemsDTO[]>(
		`api/data-summary/slice_metadata/`,
		{ baseURL: '/ddive', params: { table: tableId } }
	);
	const axisItems = serializeTableAxisItems(response.data);
	return axisItems;
};

export const fetchTableReferences = async (
	tableId: number,
	tableName: string
): Promise<ITableReference[]> => {
	const response = await axiosInstance.get<IReferenceDetailsItemDTO[]>(
		`api/data-summary/reference_metadata/`,
		{ baseURL: '/ddive', params: { table: tableId } }
	);

	const references = serializeTableReferenceItems(response.data, tableName);

	return references;
};
// END: DATA MODEL -------------------------------------------------------------

// TABLE -----------------------------------------------------------------------
export const fetchTables = async (): Promise<INavigatorTable[]> => {
	const response = await axiosInstance.get<ITableDetailsItemsDTO[]>(
		'api/data-summary/table_metadata/',
		{ baseURL: '/ddive' }
	);
	const tableItems = serializeNavigatorTableItems(response.data);
	return tableItems;
};

export const fetchTableDetails = async (
	id: number
): Promise<ITableDetailsData> => {
	const response = await axiosInstance.get<ITableDetailsDataDTO>(
		`api/data-summary/table_metadata/${id}/`,
		{ baseURL: '/ddive' }
	);

	const tableDetailsItem = serializeTablesDetailsData(response.data);

	return tableDetailsItem;
};

export const fetchTableDiagramDetails = async (
	id: number
): Promise<ITableDiagramDetails> => {
	const response = await axiosInstance.get<ITableDiagramDetailsDTO>(
		`api/data-summary/diagram/`,
		{ baseURL: '/ddive', params: { table: id } }
	);
	const nodes = serializeTableDiagramNodes(response.data);
	const edges = serializeTableDiagramEdges(response.data);
	const references = serializeTableDiagramReferences(response.data, id);

	return {
		nodes,
		edges,
		references,
	};
};
// END: TABLE ------------------------------------------------------------------

// AXES ------------------------------------------------------------------------
export const fetchAxes = async (): Promise<INavigatorAxis[]> => {
	const response = await axiosInstance.get<IAxisDetailsItemsDTO[]>(
		'api/data-summary/slice_metadata/',
		{ baseURL: '/ddive' }
	);
	const axisItems = serializeNavigatorAxisItems(response.data);
	return axisItems;
};

export const fetchAxisDetails = async (
	id: number
): Promise<IAxisDetailsItems> => {
	const response = await axiosInstance.get<{ axis: IAxisDetailsItemsDTO }>(
		`api/data-summary/slice_metadata/${id}/`,
		{ baseURL: '/ddive' }
	);
	const axisDetailsItem = serializeAxisDetailsItem(response.data.axis);
	return axisDetailsItem;
};

export const fetchAllAxesDetails = async (): Promise<IAxisDetailsItems[]> => {
	const response = await axiosInstance.get<IAxisDetailsItemsDTO[]>(
		'api/data-summary/slice_metadata/',
		{ baseURL: '/ddive' }
	);

	const allAxesDetailsItems = serializeAxisDetailsItems(response.data);

	return allAxesDetailsItems;
};
// END: AXES -------------------------------------------------------------------

// METRICS ---------------------------------------------------------------------
export const fetchMetrics = async (): Promise<INavigatorMetric[]> => {
	const response = await axiosInstance.get<IMetricDetailsItemsDTO[]>(
		'api/data-summary/measure_metadata/',
		{ baseURL: '/ddive' }
	);
	const metricItems = serializeNavigatorMetricItems(response.data);
	return metricItems;
};

export const fetchMetricDetails = async (
	id: number
): Promise<IMetricDetailsItems> => {
	const response = await axiosInstance.get<{
		measure: IMetricDetailsItemsDTO;
	}>(`api/data-summary/measure_metadata/${id}/`, { baseURL: '/ddive' });

	const metricDetailsItem = serializeMetricsDetailsItem(response.data.measure);

	return metricDetailsItem;
};

export const fetchAllMetricsDetails = async (): Promise<
	IMetricDetailsItems[]
> => {
	const response = await axiosInstance.get<IMetricDetailsItemsDTO[]>(
		'api/data-summary/measure_metadata/',
		{ baseURL: '/ddive' }
	);

	const allMetricDetailsItems = serializeMetricsDetailsItems(response.data);

	return allMetricDetailsItems;
};
// END: METRICS ----------------------------------------------------------------

// REFERENCES ------------------------------------------------------------------
export const fetchReferences = async () => {
	const response = await axiosInstance.get<IReferenceDetailsItemDTO[]>(
		'api/data-summary/reference_metadata/',
		{ baseURL: '/ddive' }
	);
	const referenceItems = serializeNavigatorReferenceItems(response.data);
	return referenceItems;
};

export const fetchReferenceDetails = async (referenceId: number) => {
	const response = await axiosInstance.get<IReferenceDetailsItemDTO>(
		`api/data-summary/reference_metadata/${referenceId}/`,
		{ baseURL: '/ddive' }
	);
	const reference = serializeReferenceDetailsItem(response.data);
	return reference;
};
// END: REFERENCES -------------------------------------------------------------

// HIERARCHY -------------------------------------------------------------------
export const fetchHierarchies = async () => {
	const response = await axiosInstance.get<IHierarchyDetailsItemDTO[]>(
		'api/data-summary/hierarchy_metadata/',
		{ baseURL: '/ddive' }
	);
	const hierarchyItems = serializeNavigatorHierarchyItems(response.data);
	return hierarchyItems;
};

export const fetchHierarchyDetails = async (
	id: number
): Promise<IHierarchyDetailsItems[]> => {
	const response = await axiosInstance.get<IHierarchyDetailsItemsDTO[]>(
		`api/data-summary/hierarchy_metadata/${id}/`,
		{ baseURL: '/ddive' }
	);

	const hierarchyDetailsItem = serializeHierarchyDetailsItems(response.data);

	return hierarchyDetailsItem;
};
// END: HIERARCHY  -------------------------------------------------------------

// RESERVED KEYWORDS  ----------------------------------------------------------
export const fetchReservedKeywords = async () => {
	const response = await axiosInstance.get<IReservedKeywordsItems[]>(
		'api/data-summary/reserved_keywords/',
		{ baseURL: '/ddive' }
	);

	return response.data;
};

// END: RESERVED KEYWORDS  -----------------------------------------------------
