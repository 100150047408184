// libraries imports
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import Cookie from 'js-cookie';
import posthog from 'posthog-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// mui imports
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
	CssBaseline,
	StyledEngineProvider,
	ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

// project imports
import { useAppDispatch, useAppSelector } from './store/hooks';
import { appStarted } from './store/actions';
import { authActions } from './store/reducers/auth.reducer';
import RouteConfig from './routes/RouteConfig/RouteConfig';
import { selectUserEmail } from './store/selectors';
import { appSettingsActions } from './store/reducers/appSettings.reducer';
import { adminPreferencesSelector } from './store/selectors/appSettings.selectors';
import customizedTheme from './theme/theme';

// variables
const env = process.env.NODE_ENV;
const queryClient = new QueryClient();

// ================================|| APP ||==================================//
const App: React.FC = () => {
	// redux
	const dispatch = useAppDispatch();
	const userEmail = useAppSelector(selectUserEmail);
	const { primaryColor, secondaryColor } = useAppSelector(
		adminPreferencesSelector
	);

	// hooks
	const isPublicChart = useMemo(() => {
		return window.location.pathname.split('/')[2] === 'chartWithFilters';
	}, []);

	// use effect
	useEffect(() => {
		// to capture posthog sessions
		const apiKey = process.env.REACT_APP_POSTHOG_PROJECT_KEY;
		const apiHost = process.env.REACT_APP_POSTHOG_API_URL;

		if (env === 'production' && apiKey && apiHost) {
			posthog.init(apiKey, {
				api_host: apiHost,
			});
			posthog.people.set({ email: userEmail });
		}
	}, [userEmail]);

	useEffect(() => {
		if (!isPublicChart) {
			const appVersion = Cookie.get('appVersion') || '';
			dispatch(authActions.cookiesRead({ appVersion }));
			// to fetch user login details
			dispatch(appStarted());
		}

		// to fetch global preferences
		dispatch(appSettingsActions.fetchAdminPreferences.fetch({ isPublicChart }));
	}, [dispatch, isPublicChart]);

	useEffect(() => {
		// to fetch user preferences - after successful login
		if (userEmail && !isPublicChart) {
			dispatch(appSettingsActions.fetchUserPreferences.fetch());
		}
	}, [dispatch, isPublicChart, userEmail]);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={customizedTheme({ primaryColor, secondaryColor })}>
				<QueryClientProvider client={queryClient}>
					<CssBaseline enableColorScheme />
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<Helmet>
							<meta
								name="viewport"
								content="width=device-width, initial-scale=1"
							/>
						</Helmet>

						<RouteConfig />
					</LocalizationProvider>
				</QueryClientProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default App;
