import { IUsageLogsDataDTO } from '../models/dto/usageLogs.dto.model';
import { IUsageLogsData, IUsageLogsParams } from '../models/usageLogs.model';
import { serializeUsageLogsItems } from '../serializers/usageLogs.serializer';
import axiosInstance from './axios/axios';

export const fetchUsageLogsApi = async (
	options?: IUsageLogsParams
): Promise<IUsageLogsData> => {
	const response = await axiosInstance.get<IUsageLogsDataDTO>(
		'/api/query-logs/',
		{
			baseURL: '/ddive',
			params: options,
		}
	);

	const usageLogsItems = {
		...response.data,
		results: serializeUsageLogsItems(response.data?.results),
	};
	return usageLogsItems;
};
