import React from 'react';
import { createSvgIcon } from '@mui/material';

const WorkspaceIcon = createSvgIcon(
	<svg viewBox="0 0 32 32">
		<path d="M11.757 6.514c.044-2.382 1.936-4.171 4.357-4.122 2.26.047 4.194 2.01 4.179 4.245-.016 2.296-2.041 4.242-4.357 4.188-2.43-.057-4.225-1.908-4.18-4.311M29.565 22.616c-.044 2.284-1.997 4.148-4.304 4.11-2.387-.04-4.286-1.972-4.232-4.304.053-2.285 1.987-4.123 4.316-4.101 2.384.022 4.265 1.937 4.22 4.295M10.916 22.479c.018 2.345-1.78 4.189-4.139 4.246-2.383.058-4.328-1.804-4.348-4.164-.02-2.371 1.862-4.242 4.266-4.24 2.362.004 4.202 1.816 4.22 4.158M8.847 26.94l2.16-1.982c3.302 1.912 6.682 1.853 9.952.036l2.155 1.885c-3.126 2.929-10.167 3.419-14.267.06M28.091 18.468l-2.77-.89c-.042-3.766-1.736-6.6-4.975-8.468l.578-2.766c4.071.703 7.756 6.856 7.167 12.124M6.695 17.568c-.991.318-1.868.6-2.746.88-1.145-4.31 2.482-10.546 7.093-12.19l.651 2.832c-3.292 1.818-4.934 4.678-4.998 8.478" />
	</svg>,
	'Workspace'
);

export default WorkspaceIcon;
