import { Action, isAnyOf } from '@reduxjs/toolkit';
import { combineEpics, Epic } from 'redux-observable';
import {
	catchError,
	filter,
	from,
	map,
	mergeMap,
	of,
	startWith,
	switchMap,
	tap,
} from 'rxjs';
import {
	fetchAdminPreferencesApi,
	fetchCustomerConfigApi,
	fetchCustomerSecretsApi,
	fetchUserPreferencesApi,
	resetAdminPreferencesApi,
	resetUserPreferencesApi,
	updateAdminPreferencesApi,
	updateCustomerConfigApi,
	updateCustomerSecretsApi,
	updateUserPreferencesApi,
} from '../../api/appSettings.api';
import {
	deSerializeCustomerConfigDetails,
	deSerializeCustomerSecretDetails,
} from '../../serializers/appSettings.serializer';
import { snackbarService } from '../../components/snackbar/snackbar.service';
import { IAppState } from '../../models/store';
import { appSettingsActions } from '../reducers/appSettings.reducer';

const fetchCustomerConfigEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isFetchAction = isAnyOf(appSettingsActions.fetchCustomerConfig.fetch);
	return action$.pipe(
		filter(isFetchAction),
		switchMap(() => {
			return from(fetchCustomerConfigApi()).pipe(
				map((items) => appSettingsActions.fetchCustomerConfig.fulfilled(items)),
				catchError((error) => {
					snackbarService.showErrorToast(
						error.response?.data.detail ||
							'Something went wrong. Failed to get details.'
					);
					return of(appSettingsActions.fetchCustomerConfig.rejected());
				}),
				startWith(appSettingsActions.fetchCustomerConfig.pending())
			);
		})
	);
};

const fetchCustomerSecretsEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isFetchAction = isAnyOf(appSettingsActions.fetchCustomerSecrets.fetch);
	return action$.pipe(
		filter(isFetchAction),
		switchMap(() => {
			return from(fetchCustomerSecretsApi()).pipe(
				map((items) =>
					appSettingsActions.fetchCustomerSecrets.fulfilled(items)
				),
				catchError((error) => {
					snackbarService.showErrorToast(
						error.response?.data.detail ||
							'Something went wrong. Failed to get details.'
					);
					return of(appSettingsActions.fetchCustomerSecrets.rejected());
				}),
				startWith(appSettingsActions.fetchCustomerSecrets.pending())
			);
		})
	);
};

const updateCustomerConfigEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isUpdateAction = isAnyOf(
		appSettingsActions.updateCustomerConfig.update
	);
	return action$.pipe(
		filter(isUpdateAction),
		switchMap((data) => {
			const formValues = data.payload;
			const formData = deSerializeCustomerConfigDetails(formValues);

			return from(updateCustomerConfigApi(formData)).pipe(
				mergeMap((items) => [
					appSettingsActions.updateCustomerConfig.fulfilled(items),
					appSettingsActions.updateCustomerSecrets.update(formValues),
				]),
				catchError((error) => {
					snackbarService.showErrorToast(
						error.response?.data.detail ||
							'Something went wrong. Failed to save your settings.'
					);
					return of(appSettingsActions.updateCustomerConfig.rejected());
				}),
				startWith(
					appSettingsActions.updateCustomerConfig.pending(),
					appSettingsActions.updateCustomerSecrets.pending()
				)
			);
		})
	);
};

const updateCustomerSecretsEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isUpdateAction = isAnyOf(
		appSettingsActions.updateCustomerSecrets.update
	);
	return action$.pipe(
		filter(isUpdateAction),
		switchMap((data) => {
			const formValues = data.payload;
			const formData = deSerializeCustomerSecretDetails(formValues);

			return from(updateCustomerSecretsApi(formData)).pipe(
				map((items) =>
					appSettingsActions.updateCustomerSecrets.fulfilled(items)
				),
				tap(() => {
					snackbarService.showSuccessToast('Your Settings have been saved.');
				}),
				catchError((error) => {
					snackbarService.showErrorToast(
						error.response?.data.detail ||
							'Something went wrong. Failed to save your settings.'
					);
					return of(appSettingsActions.updateCustomerSecrets.rejected());
				}),
				startWith(appSettingsActions.updateCustomerSecrets.pending())
			);
		})
	);
};

// User Preferences

const fetchUserPreferencesEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isFetchAction = isAnyOf(appSettingsActions.fetchUserPreferences.fetch);
	return action$.pipe(
		filter(isFetchAction),
		switchMap((data) => {
			return from(fetchUserPreferencesApi()).pipe(
				map((items) =>
					appSettingsActions.fetchUserPreferences.fulfilled(items)
				),
				catchError((error) => {
					if (!data.payload?.isPublicChart) {
						snackbarService.showWarningToast(
							error.response?.data.detail ||
								'Failed to fetch preferences, default options are being used.'
						);
					}

					return of(appSettingsActions.fetchUserPreferences.rejected());
				}),
				startWith(appSettingsActions.fetchUserPreferences.pending())
			);
		})
	);
};

const resetUserPreferencesEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isResetAction = isAnyOf(appSettingsActions.resetUserPreferences.reset);

	return action$.pipe(
		filter(isResetAction),
		switchMap(() => {
			return from(resetUserPreferencesApi()).pipe(
				map((items) =>
					appSettingsActions.resetUserPreferences.fulfilled(items)
				),
				catchError((error) => {
					snackbarService.showErrorToast(
						error.response?.data.detail ||
							'Something went wrong. Failed to reset details.'
					);
					return of(appSettingsActions.resetUserPreferences.rejected());
				}),
				startWith(appSettingsActions.resetUserPreferences.pending())
			);
		})
	);
};

const updateUserPreferencesEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isUpdateAction = isAnyOf(
		appSettingsActions.updateUserPreferences.update
	);
	return action$.pipe(
		filter(isUpdateAction),
		switchMap((data) => {
			const formValues = data.payload;

			return from(updateUserPreferencesApi(formValues)).pipe(
				map((items) =>
					appSettingsActions.updateUserPreferences.fulfilled(items)
				),
				tap(() => {
					snackbarService.showSuccessToast('Your Settings have been saved.');
				}),
				catchError((error) => {
					snackbarService.showErrorToast(
						error.response?.data.detail ||
							'Something went wrong. Failed to save your settings.'
					);
					return of(appSettingsActions.updateUserPreferences.rejected());
				}),
				startWith(appSettingsActions.updateUserPreferences.pending())
			);
		})
	);
};

// Admin Preferences

const fetchAdminPreferencesEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isFetchAction = isAnyOf(appSettingsActions.fetchAdminPreferences.fetch);
	return action$.pipe(
		filter(isFetchAction),
		switchMap((data) => {
			return from(fetchAdminPreferencesApi()).pipe(
				map((items) =>
					appSettingsActions.fetchAdminPreferences.fulfilled(items)
				),
				catchError((error) => {
					if (!data.payload?.isPublicChart) {
						snackbarService.showWarningToast(
							error.response?.data.detail ||
								'Failed to fetch preferences, default options are being used.'
						);
					}

					return of(appSettingsActions.fetchAdminPreferences.rejected());
				}),
				startWith(appSettingsActions.fetchAdminPreferences.pending())
			);
		})
	);
};

const resetAdminPreferencesEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isFetchAction = isAnyOf(appSettingsActions.resetAdminPreferences.reset);
	return action$.pipe(
		filter(isFetchAction),
		switchMap(() => {
			return from(resetAdminPreferencesApi()).pipe(
				map((items) =>
					appSettingsActions.resetAdminPreferences.fulfilled(items)
				),
				catchError((error) => {
					snackbarService.showErrorToast(
						error.response?.data.detail ||
							'Something went wrong. Failed to reset details.'
					);
					return of(appSettingsActions.resetAdminPreferences.rejected());
				}),
				startWith(appSettingsActions.resetAdminPreferences.pending())
			);
		})
	);
};

const updateAdminPreferencesEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isUpdateAction = isAnyOf(
		appSettingsActions.updateAdminPreferences.update
	);
	return action$.pipe(
		filter(isUpdateAction),
		switchMap((data) => {
			const formValues = data.payload;

			return from(updateAdminPreferencesApi(formValues)).pipe(
				map((items) =>
					appSettingsActions.updateAdminPreferences.fulfilled(items)
				),
				tap(() => {
					snackbarService.showSuccessToast('Your Settings have been saved.');
				}),
				catchError((error) => {
					snackbarService.showErrorToast(
						error.response?.data.detail ||
							'Something went wrong. Failed to save your settings.'
					);
					return of(appSettingsActions.updateAdminPreferences.rejected());
				}),
				startWith(appSettingsActions.updateAdminPreferences.pending())
			);
		})
	);
};

export const AppSettingsEpics = combineEpics(
	fetchCustomerConfigEpic,
	fetchCustomerSecretsEpic,
	updateCustomerConfigEpic,
	updateCustomerSecretsEpic,
	fetchUserPreferencesEpic,
	resetUserPreferencesEpic,
	updateUserPreferencesEpic,
	fetchAdminPreferencesEpic,
	updateAdminPreferencesEpic,
	resetAdminPreferencesEpic
);
