import React, { useMemo } from 'react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { TSettingType } from '../AppSettings/AppSettings';

interface IProps {
	subMenuItems: { id: string; label: string }[];
	handleMenuItemClick: (value: string, type: TSettingType) => void;
	type: TSettingType;
}

const SubMenuItems: React.FC<IProps> = (props) => {
	const { subMenuItems, handleMenuItemClick, type } = props;

	const location = useLocation();

	const sectionId = useMemo(() => {
		return location.hash.slice(1);
	}, [location.hash]);

	return (
		<List component="div" disablePadding sx={{ pt: 1 }}>
			{subMenuItems.map((item) => (
				<ListItemButton
					key={item.id}
					sx={{ pl: 4, mx: 5, borderRadius: 'shape.borderRadius' }}
					onClick={() => handleMenuItemClick(item.id, type)}
					selected={sectionId === item.id}
					dense
				>
					<ListItemText primary={item.label} />
				</ListItemButton>
			))}
		</List>
	);
};

export default SubMenuItems;
