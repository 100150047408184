import {
	configureStore,
	combineReducers,
	ThunkAction,
	Action,
} from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { IAppState } from '../models/store';
import { rootEpic } from './epics/root.epic';
import appSettingsReducer from './reducers/appSettings.reducer';
import asyncTaskReducer from './reducers/asyncTask.reducer';

import authReducer from './reducers/auth.reducer';
import chartsReducers from './reducers/charts.reducers';
import dataRefreshReducer from './reducers/dataRefresh.reducer';
import hierarchyValidationReducer from './reducers/hierarchyValidation.reducer';
import metricValidationReducer from './reducers/metricValidation.reducer';
import searchAccuracyReducer from './reducers/searchAccuracy.reducer';
import usageLogsReducer from './reducers/usageLogs.reducer';
import workspaceReducer from './reducers/workspace.reducer';
import workspaceDashboardReducer from './reducers/workspaceDashboard.reducer';

const epicMiddleware = createEpicMiddleware<Action, Action, IAppState>();

export const rootReducer = combineReducers<IAppState>({
	auth: authReducer,
	dataRefreshState: dataRefreshReducer,
	searchAccuracyState: searchAccuracyReducer,
	metricValidationState: metricValidationReducer,
	hierarchyValidationState: hierarchyValidationReducer,
	usageLogsState: usageLogsReducer,
	workspaceState: workspaceReducer,
	chartsState: chartsReducers,
	asyncTaskState: asyncTaskReducer,
	workspaceDashboardState: workspaceDashboardReducer,
	appSettingsState: appSettingsReducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(epicMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	IAppState,
	unknown,
	Action<string>
>;

epicMiddleware.run(rootEpic);
