import {
	IFeedbackData,
	IFeedbackPayload,
	IFiltersPayload,
	IOptionsPayload,
	IPreviousOrNextStatePayload,
	ISubCallPayload,
} from '../../models';
import { IAsyncTaskDTO } from '../../models/dto/asyncTask.dto.model';
import {
	IAllItemsDTO,
	IChartsDataDTO,
	IDisambiguationItemsDTO,
	IFiltersPayloadDTO,
	IPinnedChartDataDTO,
	ISearchedKeywordsDTO,
	ITimeSeriesModelDTO,
} from '../../models/dto/charts.dto.model';
import {
	deserializeFilterPayload,
	deserializeSubCallPayload,
	serializeAllItems,
	serializeChartsData,
	serializeOptionsResponse,
	serializePinnedChartData,
	serializeSearchedKeywords,
	serializeTimeSeriesModel,
} from '../../serializers/charts.serializer';
import axiosInstance from '../axios/axios';

export const searchQueryApi = async (searchQuery: string, taskId?: string) => {
	if (taskId) {
		const dataResponse = await axiosInstance
			.get<IDisambiguationItemsDTO | IChartsDataDTO>(
				`ddive/api/getDataResponse/${taskId}/`
			)
			.then((response) => response.data);
		// eslint-disable-next-line no-underscore-dangle
		if (dataResponse._type === 'OptionsResponse') {
			const serializedResult = serializeOptionsResponse(
				dataResponse as IDisambiguationItemsDTO
			);
			return serializedResult;
		}
		const serializedResult = serializeChartsData(
			dataResponse as IChartsDataDTO
		);
		return serializedResult;
	}
	const searchResponse = await axiosInstance
		.post<IAsyncTaskDTO>(`ddive/api/search/`, {
			model: 'predict',
			search_text: searchQuery,
			version: '1',
		})
		.then((response) => response.data);

	return searchResponse;
};

export const selectOptionApi = async (
	payload: IOptionsPayload | null,
	taskId?: string
) => {
	if (taskId) {
		const dataResponse = await axiosInstance
			.get<IChartsDataDTO>(`ddive/api/getDataResponse/${taskId}/`)
			.then((response) => response.data);

		const serializedResult = serializeChartsData(dataResponse);
		return serializedResult;
	}
	if (payload) {
		const responseTaskId = await axiosInstance
			.get<IAsyncTaskDTO>(
				`ddive/api/search/${payload.dagId}/select_option/${payload.subQuery}/`
			)
			.then((response) => response.data);

		return responseTaskId;
	}
	return '';
};

export const subCallApi = async (
	payload: ISubCallPayload | null,
	taskId?: string
) => {
	if (taskId) {
		const subCallData = await axiosInstance
			.get<IChartsDataDTO>(`ddive/api/getDataResponse/${taskId}/`)
			.then((response) => response.data);

		const serializedResult = serializeChartsData(subCallData);
		return serializedResult;
	}
	if (payload) {
		const url =
			payload.dagId !== -1
				? `ddive/api/search/${payload.dagId}/subcall/`
				: `ddive/api/search/create_from_items/`;

		const responseTaskId = await axiosInstance
			.post<IAsyncTaskDTO>(url, deserializeSubCallPayload(payload))
			.then((response) => response.data);

		return responseTaskId;
	}
	return '';
};

export const previousOrNextStateApi = async (
	payload: IPreviousOrNextStatePayload | null,
	taskId?: string
) => {
	if (taskId) {
		const previousOrNextStateData = await axiosInstance
			.get<IDisambiguationItemsDTO | IChartsDataDTO>(
				`ddive/api/getDataResponse/${taskId}/`
			)
			.then((response) => response.data);

		// eslint-disable-next-line no-underscore-dangle
		if (previousOrNextStateData._type === 'OptionsResponse') {
			const serializedResult = serializeOptionsResponse(
				previousOrNextStateData as IDisambiguationItemsDTO
			);
			return serializedResult;
		}
		const serializedResult = serializeChartsData(
			previousOrNextStateData as IChartsDataDTO
		);
		return serializedResult;
	}
	if (payload) {
		const responseTaskId = await axiosInstance
			.get<IAsyncTaskDTO>(`ddive/api/search/${payload.dagId}/${payload.type}/`)
			.then((response) => response.data);

		return responseTaskId;
	}
	return '';
};

export const fetchTimeSeriesModelApi = async () => {
	const timeSeriesModelObject = await axiosInstance
		.get<ITimeSeriesModelDTO>(`ddive/api/search/time_series_model_object/`)
		.then((response) => response.data);

	const serializedResult = serializeTimeSeriesModel(timeSeriesModelObject);
	return serializedResult;
};

export const getDataApi = async (payload: {
	chartId?: number;
	taskId?: string;
	filters?: IFiltersPayload[];
	secret?: string;
	isPublic?: boolean;
}) => {
	const { chartId, taskId, filters, secret, isPublic } = payload;

	if (taskId) {
		const endpoint = isPublic ? `getDataResponsePublic` : `getDataResponse`;
		const data = await axiosInstance
			.get<IChartsDataDTO>(`ddive/api/${endpoint}/${taskId}/`)
			.then((response) => response.data);
		const serializedResult = serializeChartsData(data);
		return serializedResult;
	}

	const endpoint = isPublic ? `get_data_public` : `get_data`;

	if (filters?.length) {
		const filtersPayload: IFiltersPayloadDTO[] = filters.map((item) =>
			deserializeFilterPayload(item)
		);
		const postResponseTaskId = await axiosInstance
			.post<IAsyncTaskDTO>(`ddive/api/chart/${chartId}/${endpoint}/`, {
				filters: filtersPayload,
				shared_secret: secret,
			})
			.then((response) => response.data);
		return postResponseTaskId;
	}

	const responseTaskId = await axiosInstance
		.get<IAsyncTaskDTO>(`ddive/api/chart/${chartId}/${endpoint}/`)
		.then((response) => response.data);

	return responseTaskId;
};

export const getChartDataApi = async (dagId: number, taskId?: string) => {
	if (taskId) {
		const data = await axiosInstance
			.get<IChartsDataDTO>(`ddive/api/getDataResponse/${taskId}/`)
			.then((response) => response.data);
		const serializedResult = serializeChartsData(data);
		return serializedResult;
	}
	const responseTaskId = await axiosInstance
		.get<IAsyncTaskDTO>(`ddive/api/search/${dagId}/`)
		.then((response) => response.data);

	return responseTaskId;
};

export const getAllItemsApi = async () => {
	const data = await axiosInstance
		.get<{ data: IAllItemsDTO }>(`ddive/api/api/v1/getAllItems/`)
		.then((response) => response.data.data);

	const serializedResult = serializeAllItems(data);
	return serializedResult;
};

export const sendFeedbackApi = async (payload: IFeedbackPayload) => {
	const data = await axiosInstance
		.post<{ data: IFeedbackData }>(`ddive/api/feedback/`, payload)
		.then((response) => response.data);

	return data;
};

export const getSearchedKeywordsApi = async (payload: {
	metaId?: string;
	searchText?: string;
}) => {
	let data = await axiosInstance
		.get<ISearchedKeywordsDTO[]>(`ddive/api/search/keyword/`, {
			params: { text: payload.searchText, meta_id: payload.metaId },
		})
		.then((response) => response.data);

	if (!Array.isArray(data)) {
		data = [data];
	}

	const serializedData = serializeSearchedKeywords(data);

	return serializedData;
};

export const updatePinnedChartIsPublicApi = async (
	chartId: number,
	isPublic: boolean
) => {
	const responseData = await axiosInstance
		.patch<IPinnedChartDataDTO>(`ddive/api/chart/${chartId}/`, {
			is_public: isPublic,
		})
		.then((response) => response.data);

	const serializedResult = serializePinnedChartData(responseData);

	return serializedResult;
};
