import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMetricValidationData } from '../../models';
import { IDashboardDTO, IMetricTableDTO, IUsersDTO } from '../../models/dto';

import { IMetricValidationStore } from '../../models/store/metricValidation.store.model';
import {
	bulkUpdateMetricValidationItems,
	fetchDashboards,
	fetchMetricValidationTables,
	fetchMetricValidationItems,
	fetchUsers,
	updateMetricValidationItem,
} from '../actions/metricValidation.actions';

const INITIAL_STATE: IMetricValidationStore = {
	fetchStatus: 'idle',
	fetchUserStatus: 'idle',
	fetchDashboardStatus: 'idle',
	fetchTableStatus: 'idle',
	singleUpdateStatus: 'idle',
	bulkUpdateStatus: 'idle',
	data: [],
	originalItemOrder: [],
	currentItemOrder: [],
	users: {},
	dashboards: {},
	tables: {},
};

const metricValidationSlice = createSlice({
	name: 'metricValidation',
	initialState: INITIAL_STATE,
	reducers: {
		onMetricSearch: (state, action: PayloadAction<IMetricValidationData[]>) => {
			const searchResult = action.payload;
			if (searchResult.length) {
				state.currentItemOrder = searchResult.map((result) => result.id);
			} else {
				state.currentItemOrder = [...state.originalItemOrder];
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchMetricValidationItems.pending, (state) => {
				state.fetchStatus = 'pending';
			})
			.addCase(fetchMetricValidationItems.rejected, (state) => {
				state.fetchStatus = 'rejected';
			})
			.addCase(fetchMetricValidationItems.fulfilled, (state, action) => {
				state.fetchStatus = 'fulfilled';

				const items = action.payload;

				state.data = {};
				state.originalItemOrder = [];
				state.currentItemOrder = [];
				items.forEach((item) => {
					state.data[item.id] = {
						...item,
						isUpdating: false,
					};
					state.originalItemOrder.push(item.id);
					state.currentItemOrder.push(item.id);
				});
			})
			.addCase(fetchUsers.pending, (state) => {
				state.fetchUserStatus = 'pending';
			})
			.addCase(fetchUsers.rejected, (state) => {
				state.fetchUserStatus = 'rejected';
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.fetchUserStatus = 'fulfilled';
				action.payload.forEach((user: IUsersDTO) => {
					state.users[user.id] = {
						id: user.id,
						userName: user.username,
					};
				});
			})
			.addCase(fetchDashboards.pending, (state) => {
				state.fetchUserStatus = 'pending';
			})
			.addCase(fetchDashboards.rejected, (state) => {
				state.fetchUserStatus = 'rejected';
			})
			.addCase(fetchDashboards.fulfilled, (state, action) => {
				state.fetchUserStatus = 'fulfilled';
				action.payload.forEach((dashboard: IDashboardDTO) => {
					if (dashboard.is_system) {
						state.dashboards[dashboard.id] = {
							id: dashboard.id,
							dashboardName: dashboard.dashboard_name,
						};
					}
				});
			})
			.addCase(fetchMetricValidationTables.pending, (state) => {
				state.fetchTableStatus = 'pending';
			})
			.addCase(fetchMetricValidationTables.rejected, (state) => {
				state.fetchTableStatus = 'rejected';
			})
			.addCase(fetchMetricValidationTables.fulfilled, (state, action) => {
				state.fetchTableStatus = 'fulfilled';
				const tables = action.payload;

				tables.forEach((table: IMetricTableDTO) => {
					state.tables[table.id] = {
						id: table.id,
						tableName: table.table_name,
					};
				});
			})
			.addCase(updateMetricValidationItem.pending, (state, action) => {
				state.data[action.payload.updatingRow].isUpdating = true;
				state.singleUpdateStatus = 'pending';
			})
			.addCase(updateMetricValidationItem.rejected, (state, action) => {
				state.data[action.payload.updatingRow].isUpdating = false;
				state.singleUpdateStatus = 'rejected';
			})
			.addCase(updateMetricValidationItem.fulfilled, (state, action) => {
				const id = action.payload.lastId;

				Object.keys(state.data).forEach((key) => {
					const dataMapKey = +key;
					if (dataMapKey === id) {
						delete state.data[dataMapKey];
						state.data[action.payload.id] = {
							...action.payload,
							isUpdating: false,
						};
					}
				});

				state.currentItemOrder.forEach((currentItem, index) => {
					if (currentItem === id) {
						state.currentItemOrder[index] = action.payload.id;
					}
				});
				state.originalItemOrder.forEach((currentItem, index) => {
					if (currentItem === id) {
						state.originalItemOrder[index] = action.payload.id;
					}
				});

				state.singleUpdateStatus = 'fulfilled';
			})
			.addCase(bulkUpdateMetricValidationItems.pending, (state, action) => {
				action.payload.forEach((rowId: number) => {
					state.data[rowId].isUpdating = true;
				});
				state.bulkUpdateStatus = 'pending';
			})
			.addCase(bulkUpdateMetricValidationItems.rejected, (state, action) => {
				action.payload.forEach((rowId: number) => {
					state.data[rowId].isUpdating = false;
				});
				state.bulkUpdateStatus = 'rejected';
			})
			.addCase(bulkUpdateMetricValidationItems.fulfilled, (state, action) => {
				Object.keys(state.data).forEach((key) => {
					const dataMapKey = +key;

					action.payload.forEach((payloadItem: IMetricValidationData) => {
						if (payloadItem.lastId === dataMapKey) {
							delete state.data[dataMapKey];
							state.data[payloadItem.id] = {
								...payloadItem,
								isUpdating: false,
							};
						}
					});
				});

				action.payload.forEach((payloadItem: IMetricValidationData) => {
					state.currentItemOrder.forEach((currentItem, index) => {
						if (currentItem === payloadItem.lastId) {
							state.currentItemOrder[index] = payloadItem.id;
						}
					});
					state.originalItemOrder.forEach((currentItem, index) => {
						if (currentItem === payloadItem.lastId) {
							state.originalItemOrder[index] = payloadItem.id;
						}
					});
				});

				state.bulkUpdateStatus = 'fulfilled';
			});
	},
});

export const metricValidationActions = {
	...metricValidationSlice.actions,
	fetchMetricValidationItems,
	fetchUsers,
	fetchMetricValidationTables,
	fetchDashboards,
	bulkUpdateMetricValidationItems,
	updateMetricValidationItem,
};
export default metricValidationSlice.reducer;
