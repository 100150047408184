import { IAutocompleteResultDTO } from '../models/dto';
import { serializeAutocompleteResult } from '../serializers';
import axiosInstance from './axios/axios';

export const searchAutocomplete = async (query: string) => {
	const response = await axiosInstance.get<IAutocompleteResultDTO>(
		'/ddive/api/autocomplete/',
		{
			params: { search_string: query },
		}
	);

	const result = response.data;
	const suggestions = serializeAutocompleteResult(result);

	return suggestions;
};
