import { EMonth, EWeekDay } from '.';

export enum EScheduleFrequency {
	DAILY = 'Daily',
	WEEKLY = 'Weekly',
	MONTHLY = 'Monthly',
	YEARLY = 'Yearly',
}

export interface IReportSchedule {
	startDate: string;
	endDate: string | null;
	frequency: EScheduleFrequency;
	time: string;
	weekDay: EWeekDay | null;
	dayOfMonth: number | null;
	month: EMonth | null;
	scheduledFor: string[];
}

export interface IScheduleReportForm {
	title: string;
	chartId: number | null;
	dashboardId: number | null;
	frequency: EScheduleFrequency;
	time: Date;
	weekDay?: EWeekDay | null;
	dayOfMonth?: number | null;
	month?: EMonth | null;
	startDate: Date;
	endDate?: Date | null;
	scheduledFor: string[];
}
