// libraries imports
import React, { useCallback } from 'react';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';

// mui imports
import {
	AppBar,
	Box,
	Button,
	Container,
	IconButton,
	Toolbar,
	Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import StorageIcon from '@mui/icons-material/Storage';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { SvgIconComponent } from '@mui/icons-material';

// project imports
import logo from '../../../assets/img/logo.svg';
import WorkspaceIcon from '../../icons/WorkspaceIcon';
import DiveIcon from '../../icons/DiveIcon';
import FAQIcon from '../../icons/FAQIcon';
import NavbarDropdown from './NavbarDropdown';
import { selectLoginStatus } from '../../../store/selectors';
import NavbarButton from './NavbarButton';
import SearchBar from '../../search/SearchBar/SearchBar';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { chartsActions } from '../../../store/reducers/charts.reducers';

// types
interface IAppNavbarProps {
	handleDrawerToggle: () => void;
}
interface ILinks {
	title: string;
	path: string;
	testId: string;
	Icon: SvgIconComponent;
}

// variables
export const links: ILinks[] = [
	{
		title: 'Data Summary',
		path: '/dataGovernance/summary',
		testId: 'navDataSummary',
		Icon: StorageIcon,
	},
	{
		title: 'Dive',
		path: '/dive',
		testId: 'navDive',
		Icon: DiveIcon,
	},
	{
		title: 'Workspace',
		path: '/workspace',
		testId: 'navWorkspace',
		Icon: WorkspaceIcon,
	},
	{
		title: 'Help',
		path: '/help',
		testId: 'navHelp',
		Icon: FAQIcon,
	},
];

// =========================|| APP NAVBAR ||==================================//
const AppNavbar: React.FC<IAppNavbarProps> = ({ handleDrawerToggle }) => {
	// redux
	const { isLoggedIn } = useAppSelector(selectLoginStatus);
	const dispatch = useAppDispatch();

	// hooks
	const navigate = useNavigate();

	// event handlers
	const onSearch = useCallback(
		(query: string) => {
			if (!query.length) {
				return;
			}
			dispatch(chartsActions.resetResponseType());
			dispatch(chartsActions.resetChartsData());
			dispatch(chartsActions.resetPinnedChartsData());

			navigate(
				{
					pathname: '/dive',
					search: `?${createSearchParams({ search: query })}`,
				},
				{ replace: true }
			);
		},
		[dispatch, navigate]
	);

	return (
		<AppBar position="fixed" color="primary" sx={{ height: '4rem' }}>
			<Container maxWidth={false} sx={{ height: '100%' }}>
				<Toolbar sx={{ padding: { xs: 0, height: '100%' } }}>
					{/*  orcablue logo */}
					<Box
						data-testid="orcablueIcon"
						component={Link}
						to="/"
						sx={{ mr: { md: 5, sm: 3, xs: 1 }, display: 'flex' }}
					>
						<img src={logo} alt="Logo" height="36" width="36" />
					</Box>

					{/* search bar */}
					<Box sx={{ width: '50%', maxWidth: '600px' }}>
						<SearchBar onSearch={onSearch} />
					</Box>

					{/* create new chart */}
					<Tooltip title="Create a new chart" placement="bottom">
						<IconButton
							color="inherit"
							sx={{ mr: 2 }}
							component={Link}
							to="/dive/charts/createNewChart"
							data-testid="navCreateNewChart"
						>
							<AddBoxIcon fontSize="large" />
						</IconButton>
					</Tooltip>

					<Box sx={{ flexGrow: 1 }} />

					{/* links for large screen sizes */}
					<Box
						sx={{
							display: {
								xs: 'none',
								md: 'flex',
								xl: 'none',
							},
						}}
					>
						{links.map(({ title, Icon, testId, path }) => {
							return (
								<Tooltip title={title} placement="bottom" key={title}>
									<IconButton
										to={path}
										component={Link}
										color="inherit"
										sx={{ mr: 2 }}
										data-testid={testId}
									>
										<Icon />
									</IconButton>
								</Tooltip>
							);
						})}
					</Box>

					{/* links for small screen sizes */}
					<Box
						sx={{
							display: {
								xs: 'none',
								xl: 'flex',
							},
						}}
					>
						{links.map(({ title, Icon, path }) => {
							return (
								<NavbarButton icon={Icon} path={path} key={title}>
									{title}
								</NavbarButton>
							);
						})}
					</Box>

					{/* customer logo / login button based on login status */}
					{isLoggedIn ? (
						<NavbarDropdown />
					) : (
						<Button
							variant="outlined"
							component={Link}
							to="/login"
							color="inherit"
						>
							Login
						</Button>
					)}

					{/* button to open side drawer */}
					{isLoggedIn && (
						<IconButton
							color="inherit"
							aria-label="button to open the drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{
								display: {
									md: 'none',
								},
							}}
						>
							<MenuIcon />
						</IconButton>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default AppNavbar;
