import { IPinnedChartData } from '../models';
import {
	IWorkspaceDashboardDetailsDTO,
	IWorkspaceDashboardPayloadDTO,
	IWorkSpaceDashboardUpdatePayloadDTO,
} from '../models/dto/workspaceDashboard.dto.model';
import {
	IWorkspaceDashboardDetails,
	IWorkspaceDashboardPayload,
	IWorkSpaceDashboardUpdatePayload,
} from '../models/workspaceDashboard.model';
import {
	deserializeAxisOrderSet,
	deserializeFilterPayload,
	deserializeMeasureOrderSet,
	serializePinnedChartsData,
	serializeSelectedFilterItems,
} from './charts.serializer';
import { serializeReportSchedule } from './schedule.serializer';
import { serializeUser, serializeUsers } from './user.serializer';

export const serializeWorkspaceDashboardDetails = (
	items: IWorkspaceDashboardDetailsDTO
): {
	pinnedChartsData: IPinnedChartData[];
	dashboardDetails: IWorkspaceDashboardDetails;
} => ({
	pinnedChartsData: serializePinnedChartsData(items.charts),
	dashboardDetails: {
		dashboardName: items.dashboard_name,
		id: items.id,
		isActive: items.is_active,
		isPublic: items.is_public,
		isFiltered: items.is_filtered,
		isScheduled: items.is_scheduled,
		isSystem: items.is_system,
		createdDate: items.created_date,
		modifiedDate: items.modified_date,
		sharedWithUsers: serializeUsers(items.shared_user_ids),
		user: serializeUser(items.user_id),
		schedule: items.schedule ? serializeReportSchedule(items.schedule) : null,
		dashboardFilters: items.dashboard_filters
			? serializeSelectedFilterItems(items.dashboard_filters)
			: null,
		dashboardAxes: items.dashboard_axes,
	},
});

// Deserializer  ---------------------------------------------------------------
export const deSerializeWorkspaceDashboardPayloadItem = (
	item: IWorkspaceDashboardPayload
): IWorkspaceDashboardPayloadDTO => {
	return {
		axisorder_set: item.axisOrderSet.map((axisOrderSetItem) =>
			deserializeAxisOrderSet(axisOrderSetItem)
		),
		measureorder_set: item.metricOrderSet.map((metricOrderSetItem) =>
			deserializeMeasureOrderSet(metricOrderSetItem)
		),
		chart_title: item.chartTitle,
		chart_type: item.chartType,
		show_X_axis: item.showXAxis === undefined ? null : !!item.showXAxis,
		show_Y_axis: item.showYAxis === undefined ? null : !!item.showYAxis,
		show_legends: item.showLegends === undefined ? null : !!item.showLegends,
		show_title: item.showTitle === undefined ? null : !!item.showTitle,
		...(!!item.id && { id: item.id }),
		...(!!item.chartData && { chart_data: item.chartData }),
		...((!!item.position || item.position === 0) && {
			position: item.position,
		}),
		...(!!item.height && { height: item.height }),
		...(!!item.width && { width: item.width }),
		...(!!item.startCoordinates && {
			start_coordinates: item.startCoordinates,
		}),
		...(!!item.subTotalFlag !== undefined && {
			subtotal_flag: item.subTotalFlag,
		}),
		...(!!item.showDataPoints !== undefined && {
			show_data_points: item.showDataPoints,
		}),
		...(item.isActive !== undefined && { is_active: item.isActive }),
	};
};

export const deSerializeWorkspaceDashboardPayloadItems = (
	items: IWorkspaceDashboardPayload[]
): IWorkspaceDashboardPayloadDTO[] =>
	items.map((item) => deSerializeWorkspaceDashboardPayloadItem(item));

export const deSerializeWorkspaceDashboardUpdatePayload = (
	item: IWorkSpaceDashboardUpdatePayload
): IWorkSpaceDashboardUpdatePayloadDTO => ({
	...(!!item.isSystem !== undefined && { is_system: item.isSystem }),
	...(!!item.dashboardName && { dashboard_name: item.dashboardName }),
	...(!!item.isGranularReport !== undefined && {
		is_filtered: item.isGranularReport,
	}),
	...(!!item.granularDashboardAxes && {
		dashboard_axes: item.granularDashboardAxes,
	}),
	...(!!item.dashboardFilters && {
		dashboard_filters: item.dashboardFilters.map((filter) =>
			deserializeFilterPayload(filter)
		),
	}),
});

// END: Deserializer  ----------------------------------------------------------
