import axiosInstance from './axios/axios';
import { IDataRefreshItemDTO } from '../models/dto';
import { serializeDataRefreshItems } from '../serializers';

export const fetchDataRefreshData = async () => {
	const response = await axiosInstance.get<IDataRefreshItemDTO[]>(
		'/configure/api/refreshDetails/'
	);
	const dataRefreshItem = serializeDataRefreshItems(response.data);
	return dataRefreshItem;
};
