import { IPinChartPayload } from '../../models';
import { IPinnedChartDataDTO } from '../../models/dto';
import {
	deserializePinChartPayload,
	deserializePinChartsPayload,
	serializePinnedChartData,
	serializePinnedChartsData,
} from '../../serializers/charts.serializer';
import axiosInstance from '../axios/axios';

export const pinChartApi = async (
	payload: IPinChartPayload | IPinChartPayload[]
) => {
	const deserializedPayload = Array.isArray(payload)
		? deserializePinChartsPayload(payload)
		: deserializePinChartPayload(payload);

	const pinnedChart = await axiosInstance
		.post<IPinnedChartDataDTO[]>(`ddive/api/chart/`, deserializedPayload)
		.then((response) => response.data);

	const serializedResult = Array.isArray(pinnedChart)
		? serializePinnedChartsData(pinnedChart)
		: serializePinnedChartData(pinnedChart);

	return serializedResult;
};

export const getPinnedChartDataApi = async (chartId: number) => {
	const data = await axiosInstance
		.get<IPinnedChartDataDTO>(`ddive/api/chart/${chartId}/`)
		.then((response) => response.data);

	const serializedResult = serializePinnedChartData(data);
	return serializedResult;
};

export const updatePinnedChartDataApi = async (
	chartId: number,
	payload: IPinChartPayload
) => {
	const pinnedChart = await axiosInstance
		.patch<IPinnedChartDataDTO>(
			`ddive/api/chart/${chartId}/`,
			deserializePinChartPayload(payload)
		)
		.then((response) => response.data);

	const serializedResult = serializePinnedChartData(pinnedChart);

	return serializedResult;
};
