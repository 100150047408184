import React from 'react';
import { createSvgIcon } from '@mui/material';

const OrcablueIcon = createSvgIcon(
	<svg viewBox="0 0 48 57">
		<path d="M11.273 11.98c1.937-1.747 2.868-2.425 3.266-3.595.273-3.207-2.1-4.642-3.412-5.182-1.312-.54-2.706-1.676-.42-2.602 2.286-.927 4.744-.77 8.994.39 4.25 1.162 5.767 2.284 11.624 6.706 1.305.986 2.74 2.142 3.66 2.814 2.643 1.93 2.746 2.03 3.202 2.453C44.137 17.299 48 24.29 48 32.179 48 45.335 37.255 56 24 56S0 45.335 0 32.18c0-8.517 4.502-15.989 11.273-20.2zM24 44.299c6.744 0 12.21-5.427 12.21-12.12S30.745 20.06 24 20.06c-6.744 0-12.21 5.427-12.21 12.12S17.255 44.3 24 44.3z" />
	</svg>,
	'Orcablue'
);

export default OrcablueIcon;
