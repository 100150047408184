// libraries imports
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// mui imports
import { TreeItem, TreeView } from '@mui/lab';
import DnsIcon from '@mui/icons-material/Dns';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LinkIcon from '@mui/icons-material/Link';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';

// project imports
import NavigatorTreeItem from './NavigatorTreeItem/NavigatorTreeItem';
import {
	INavigatorMetric,
	INavigatorAxis,
	INavigatorModel,
	INavigatorTable,
	INavigatorReference,
	INavigatorHierarchy,
} from '../../../../../models/dataSummary.model';
import { TRequestStatus } from '../../../../../models';

// types
interface IProps {
	models: INavigatorModel[];
	tables: INavigatorTable[];
	axes: INavigatorAxis[];
	metrics: INavigatorMetric[];
	references: INavigatorReference[];
	hierarchies: INavigatorHierarchy[];
	searchTerm?: string | null;
	tablesFetchStatus: TRequestStatus;
	axesFetchStatus: TRequestStatus;
	metricsFetchStatus: TRequestStatus;
	referencesFetchStatus: TRequestStatus;
	hierarchiesFetchStatus: TRequestStatus;
}

// =====================|| NAVIGATOR TREE VIEW ||=============================//
const NavigatorTreeView: React.FC<IProps> = (props) => {
	const {
		axes,
		hierarchies,
		metrics,
		models,
		references,
		tables,
		axesFetchStatus,
		hierarchiesFetchStatus,
		metricsFetchStatus,
		referencesFetchStatus,
		tablesFetchStatus,
		searchTerm,
	} = props;

	// states
	const [expandedNodeIds, setExpandedNodeIds] = useState<string[]>([
		`model_${models?.[0]?.id}`,
	]);
	const [selectedNode, setSelectedNode] = useState<string>('');

	// hooks
	const navigate = useNavigate();
	const location = useLocation();

	// refs
	const isFirstRenderRef = useRef<boolean>(true);

	// event handlers
	const onClickModelNode = useCallback(
		(modelId: number) => {
			navigate({
				pathname: `/dataGovernance/summary/models/${modelId}/diagram`,
				search: searchTerm ? `?search=${searchTerm}` : '',
			});
			setSelectedNode(`model_${modelId}`);
		},
		[navigate, searchTerm]
	);

	const onClickTableNode = useCallback(
		(tableId: number, modelId: number) => {
			navigate({
				pathname: `/dataGovernance/summary/models/${modelId}/tables/${tableId}/details`,
				search: searchTerm ? `?search=${searchTerm}` : '',
			});
			setSelectedNode(`table_${tableId}`);
		},
		[navigate, searchTerm]
	);

	const onClickAxisNode = useCallback(
		(axisId: number, tableId: number, modelId: number) => {
			navigate({
				pathname: `/dataGovernance/summary/models/${modelId}/tables/${tableId}/axes/${axisId}`,
				search: searchTerm ? `?search=${searchTerm}` : '',
			});
			setSelectedNode(`axis_${axisId}`);
		},
		[navigate, searchTerm]
	);

	const onClickMetricNode = useCallback(
		(metricId: number, tableId: number, modelId: number) => {
			navigate({
				pathname: `/dataGovernance/summary/models/${modelId}/tables/${tableId}/metrics/${metricId}`,
				search: searchTerm ? `?search=${searchTerm}` : '',
			});
			setSelectedNode(`metric_${metricId}`);
		},
		[navigate, searchTerm]
	);

	const onClickReferenceNode = useCallback(
		(referenceId: number, tableId: number, modelId: number) => {
			navigate({
				pathname: `/dataGovernance/summary/models/${modelId}/tables/${tableId}/references/${referenceId}`,
				search: searchTerm ? `?search=${searchTerm}` : '',
			});
			setSelectedNode(`reference_${referenceId}`);
		},
		[navigate, searchTerm]
	);

	const onClickHierarchyNode = useCallback(
		(hierarchyId: number, modelId: number) => {
			navigate({
				pathname: `/dataGovernance/summary/models/${modelId}/hierarchies/${hierarchyId}/details`,
				search: searchTerm ? `?search=${searchTerm}` : '',
			});
			setSelectedNode(`hierarchy_${hierarchyId}`);
		},
		[navigate, searchTerm]
	);

	const onClickKeywordNode = useCallback(
		(modelId: number) => {
			navigate({
				pathname: `/dataGovernance/summary/models/${modelId}/reservedKeywords`,
				search: searchTerm ? `?search=${searchTerm}` : '',
			});

			setSelectedNode(`reservedKeywordsFolder_${modelId}`);
		},
		[navigate, searchTerm]
	);

	const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
		setExpandedNodeIds(nodeIds);
	};

	// use effects
	useEffect(() => {
		if (searchTerm) {
			const nodeIds = [`model_${models?.[0]?.id}`];
			models.forEach((model) => {
				nodeIds.push(`model_${model.id}`);
				nodeIds.push(`tablesFolder_${model.id}`);
				nodeIds.push(`hierarchiesFolder_${model.id}`);
			});

			tables.forEach((table) => {
				if (
					metrics.filter((metric) => metric.tableId === table.id).length ||
					axes.filter((axis) => axis.tableId === table.id).length ||
					references.filter((reference) => reference.tableId === table.id)
						.length
				) {
					nodeIds.push(`table_${table.id}`);
					nodeIds.push(`axesFolder_${table.id}`);
					nodeIds.push(`metricsFolder_${table.id}`);
					nodeIds.push(`referencesFolder_${table.id}`);
				}
			});

			axes.forEach((axis) => {
				nodeIds.push(`axis_${axis.id}`);
			});

			metrics.forEach((metric) => {
				nodeIds.push(`metric_${metric.id}`);
			});

			references.forEach((reference) => {
				nodeIds.push(`reference_${reference.id}`);
			});

			hierarchies.forEach((hierarchy) => {
				nodeIds.push(`hierarchy_${hierarchy.id}`);
			});

			setExpandedNodeIds(nodeIds);
		}
	}, [axes, hierarchies, metrics, models, references, searchTerm, tables]);

	useEffect(() => {
		if (isFirstRenderRef.current) {
			const pathNames = location.pathname.split('/');
			const modelId = models?.[0]?.id;

			let activeNode = `model_${modelId}`;
			const expanded: string[] = [];

			switch (pathNames[5]) {
				case 'tables':
					{
						const tableId = pathNames[6];
						const id = pathNames[pathNames.length - 1];

						// update active nodes
						if (pathNames.includes('axes')) {
							activeNode = `axis_${id}`;
						} else if (pathNames.includes('metrics')) {
							activeNode = `metric_${id}`;
						} else if (pathNames.includes('references')) {
							activeNode = `reference_${id}`;
						} else {
							activeNode = `table_${tableId}`;
						}

						// update expanded folder
						expanded.push(`tablesFolder_${modelId}`);
						expanded.push(`table_${tableId}`);
						if (pathNames.includes('axes')) {
							expanded.push(`axesFolder_${tableId}`);
						} else if (pathNames.includes('metrics')) {
							expanded.push(`metricsFolder_${tableId}`);
						} else if (pathNames.includes('references')) {
							expanded.push(`referencesFolder_${tableId}`);
						}
					}

					break;

				case 'hierarchies':
					activeNode = `hierarchy_${pathNames[pathNames.length - 2]}`;
					expanded.push(`hierarchiesFolder_${modelId}`);
					break;

				case 'reservedKeywords':
					activeNode = `reservedKeywordsFolder_${modelId}`;
					break;

				default:
					activeNode = `model_${modelId}`;
					break;
			}

			setSelectedNode(activeNode);
			setExpandedNodeIds((prev) => [...prev, ...expanded]);
			isFirstRenderRef.current = false;
		}
	}, [location.pathname, models]);

	return (
		<TreeView
			defaultCollapseIcon={<ArrowDropDownIcon />}
			defaultExpandIcon={<ArrowRightIcon />}
			expanded={expandedNodeIds}
			selected={selectedNode}
			onNodeToggle={handleToggle}
			data-testid="navigatorTreeRoot"
		>
			{models.map((model) => (
				<NavigatorTreeItem
					key={`model_${model.id}`}
					nodeId={`model_${model.id}`}
					data-testid="dataModelLevel1"
					labelIcon={DnsIcon}
					labelText={model.name}
					onClick={() => onClickModelNode(model.id)}
				>
					<NavigatorTreeItem
						nodeId={`tablesFolder_${model.id}`}
						labelIcon={BackupTableOutlinedIcon}
						labelText="Tables"
						labelInfo={tables
							.filter((table) => table.modelId === model.id)
							.length.toString()}
						data-testid="dataModelLevel2"
					>
						{tablesFetchStatus === 'pending' && (
							<TreeItem
								nodeId={`fetchingTables_${model.id}`}
								label="Fetching tables..."
							/>
						)}
						{(tablesFetchStatus === 'fulfilled' || searchTerm) &&
							!!tables.length &&
							tables
								.filter((table) => table.modelId === model.id)
								.map((table) => (
									<NavigatorTreeItem
										key={`table_${table.id}`}
										data-testid="dataModelLevel3Table"
										nodeId={`table_${table.id}`}
										labelIcon={BackupTableOutlinedIcon}
										labelText={table.name}
										onClick={() => onClickTableNode(table.id, model.id)}
									>
										<NavigatorTreeItem
											nodeId={`axesFolder_${table.id}`}
											labelIcon={ArrowForwardIcon}
											labelText="Dimensions"
											labelInfo={axes
												.filter((axis) => axis.tableId === table.id)
												.length.toString()}
											data-testid="dataModelLevel4Dim"
										>
											{axesFetchStatus === 'pending' && (
												<TreeItem
													nodeId={`fetchingAxes_${table.id}`}
													label="Fetching dimensions..."
												/>
											)}
											{(axesFetchStatus === 'fulfilled' || searchTerm) &&
												!!axes.length &&
												axes
													.filter((axis) => axis.tableId === table.id)
													.map((axis) => (
														<NavigatorTreeItem
															nodeId={`axis_${axis.id}`}
															key={`axis_${axis.id}`}
															data-testid="dataModelLevel5Dim"
															labelIcon={ArrowForwardIcon}
															labelText={axis.name}
															onClick={() =>
																onClickAxisNode(axis.id, table.id, model.id)
															}
														/>
													))}
										</NavigatorTreeItem>
										<NavigatorTreeItem
											nodeId={`metricsFolder_${table.id}`}
											labelIcon={EqualizerIcon}
											labelText="Metrics"
											labelInfo={metrics
												.filter((metric) => metric.tableId === table.id)
												.length.toString()}
											data-testid="dataModelLevel4Metrics"
										>
											{metricsFetchStatus === 'pending' && (
												<TreeItem
													nodeId={`fetchingMetrics_${table.id}`}
													label="Fetching metrics..."
												/>
											)}
											{(metricsFetchStatus === 'fulfilled' || searchTerm) &&
												!!metrics.length &&
												metrics
													.filter((metric) => metric.tableId === table.id)
													.map((metric) => (
														<NavigatorTreeItem
															nodeId={`metric_${metric.id}`}
															key={`metric_${metric.id}`}
															data-testid="dataModelLevel5Metric"
															labelIcon={EqualizerIcon}
															labelText={metric.name}
															onClick={() =>
																onClickMetricNode(metric.id, table.id, model.id)
															}
														/>
													))}
										</NavigatorTreeItem>
										<NavigatorTreeItem
											nodeId={`referencesFolder_${table.id}`}
											labelIcon={LinkIcon}
											labelText="References"
											labelInfo={references
												.filter((reference) => reference.tableId === table.id)
												.length.toString()}
											data-testid="dataModelLevel4Ref"
										>
											{referencesFetchStatus === 'pending' && (
												<TreeItem
													nodeId={`fetchingReferences_${table.id}`}
													label="Fetching references..."
												/>
											)}
											{(referencesFetchStatus === 'fulfilled' || searchTerm) &&
												!!references.length &&
												references
													.filter((reference) => reference.tableId === table.id)
													.map((reference) => (
														<NavigatorTreeItem
															data-testid="dataModelLevel5Ref"
															nodeId={`reference_${reference.id}`}
															key={`reference_${reference.id}`}
															labelIcon={LinkIcon}
															labelText={reference.name}
															onClick={() =>
																onClickReferenceNode(
																	reference.id,
																	table.id,
																	model.id
																)
															}
														/>
													))}
										</NavigatorTreeItem>
									</NavigatorTreeItem>
								))}
					</NavigatorTreeItem>

					<NavigatorTreeItem
						nodeId={`hierarchiesFolder_${model.id}`}
						labelIcon={AccountTreeIcon}
						labelText="Hierarchies"
						labelInfo={hierarchies.length.toString()}
						data-testid="dataModelLevel2"
					>
						{hierarchiesFetchStatus === 'pending' && (
							<TreeItem
								nodeId={`fetchingHierarchies_${model.id}`}
								label="Fetching hierarchies..."
							/>
						)}
						{(hierarchiesFetchStatus === 'fulfilled' || searchTerm) &&
							!!hierarchies.length &&
							hierarchies.map((hierarchy) => (
								<NavigatorTreeItem
									data-testid="dataModelLevel3Hierarchy"
									key={`hierarchy_${hierarchy.id}`}
									nodeId={`hierarchy_${hierarchy.id}`}
									labelIcon={AccountTreeIcon}
									labelText={hierarchy.name}
									onClick={() => onClickHierarchyNode(hierarchy.id, model.id)}
								/>
							))}
					</NavigatorTreeItem>
					<NavigatorTreeItem
						data-testid="dataModelLevel2"
						nodeId={`reservedKeywordsFolder_${model.id}`}
						labelIcon={SpellcheckIcon}
						labelText="Reserved Keywords"
						onClick={() => onClickKeywordNode(model.id)}
						aria-label="navigatorTreeReservedKeywords"
					/>
				</NavigatorTreeItem>
			))}
		</TreeView>
	);
};

NavigatorTreeView.defaultProps = { searchTerm: null };

export default NavigatorTreeView;
