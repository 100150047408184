import { Action, isAnyOf } from '@reduxjs/toolkit';
import { combineEpics, Epic } from 'redux-observable';
import {
	catchError,
	filter,
	from,
	map,
	mergeMap,
	of,
	startWith,
	switchMap,
	tap,
} from 'rxjs';
import {
	fetchTimeSeriesModelApi,
	selectOptionApi,
	previousOrNextStateApi,
	searchQueryApi,
	subCallApi,
	getDataApi,
	getChartDataApi,
	getAllItemsApi,
} from '../../api/ddive/charts.api';
import { getPinnedChartDataApi } from '../../api/reportActions/pin.api';
import { scheduleReportApi } from '../../api/reportActions/schedule.api';
import { shareDiveChartApi } from '../../api/reportActions/share.api';
import { snackbarService } from '../../components/snackbar/snackbar.service';
import { IChartsData, IDisambiguationItems } from '../../models';
import { EAsyncTask } from '../../models/asyncTask.model';
import { IAsyncTaskDTO } from '../../models/dto/asyncTask.dto.model';
import { IAppState } from '../../models/store';
import { getErrorMessage } from '../../utils/functions/errorMessages';
import {
	getAllItems,
	getChartData,
	getData,
	getPinnedChartData,
	previousOrNextState,
	scheduleChart,
	searchQuery,
	selectedOption,
	shareChart,
	subCall,
	timeSeriesModel,
} from '../actions/charts.actions';
import { asyncTaskActions } from '../reducers/asyncTask.reducer';

const searchQueryEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isSearchResultFetchAction = isAnyOf(searchQuery.search);

	return action$.pipe(
		filter(isSearchResultFetchAction),
		switchMap((items) => {
			const api = items.payload.taskId
				? searchQueryApi('', items.payload.taskId)
				: searchQueryApi(items.payload.value);

			return from(api).pipe(
				mergeMap((data) => {
					if (items.payload.taskId) {
						const response = data as IChartsData;
						return of(searchQuery.fulfilled(response));
					}
					const result = data as IAsyncTaskDTO;
					return [
						asyncTaskActions.poll({
							name: EAsyncTask.SEARCH,
							id: result.task_id || '',
						}),
					];
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Sorry, that action could not be completed. Please try again later or contact your administrator.'
					);

					snackbarService.showErrorToast(message);
					return of(searchQuery.rejected());
				}),
				startWith(searchQuery.pending())
			);
		})
	);
};

const selectedOptionEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isSelectOptionEpicAction = isAnyOf(selectedOption.fetch);

	return action$.pipe(
		filter(isSelectOptionEpicAction),
		switchMap((selectOptionData) => {
			const api = selectOptionData.payload.taskId
				? selectOptionApi(null, selectOptionData.payload.taskId)
				: selectOptionApi(selectOptionData.payload.items);

			return from(api).pipe(
				mergeMap((data) => {
					if (selectOptionData.payload.taskId) {
						const response = data as IChartsData;
						return of(selectedOption.fulfilled(response));
					}
					const result = data as IAsyncTaskDTO;
					return [
						asyncTaskActions.poll({
							name: EAsyncTask.SELECT_OPTIONS,
							id: result.task_id || '',
						}),
					];
				}),
				catchError((error) => {
					snackbarService.showErrorToast(
						error.response?.data.detail ||
							'Sorry, that action could not be completed. Please try again later or contact your administrator.'
					);
					return of(selectedOption.rejected());
				}),
				startWith(selectedOption.pending())
			);
		})
	);
};

const subCallEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isSubCallEpicAction = isAnyOf(subCall.update);

	return action$.pipe(
		filter(isSubCallEpicAction),
		switchMap((items) => {
			const api = items.payload.taskId
				? subCallApi(null, items.payload.taskId)
				: subCallApi(items.payload.items);

			return from(api).pipe(
				mergeMap((data) => {
					if (items.payload.taskId) {
						const response = data as IChartsData;
						if (response.truncated) {
							snackbarService.showWarningToast(
								'Data is truncated. Please download excel to view full data.'
							);
						}

						return of(subCall.fulfilled(response));
					}
					const result = data as IAsyncTaskDTO;
					return [
						asyncTaskActions.poll({
							name: EAsyncTask.SUB_CALL,
							id: result.task_id || '',
						}),
					];
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Sorry, that action could not be completed. Please try again later or contact your administrator.'
					);

					snackbarService.showErrorToast(message);
					return of(subCall.rejected());
				}),
				startWith(subCall.pending())
			);
		})
	);
};

const previousOrNextStateEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isValidEpicAction = isAnyOf(previousOrNextState.fetch);

	return action$.pipe(
		filter(isValidEpicAction),
		switchMap((items) => {
			const api = items.payload.taskId
				? previousOrNextStateApi(null, items.payload.taskId)
				: previousOrNextStateApi(items.payload.items);

			return from(api).pipe(
				mergeMap((data) => {
					if (items.payload.taskId) {
						const response = data as IDisambiguationItems | IChartsData;
						return of(previousOrNextState.fulfilled(response));
					}
					const result = data as IAsyncTaskDTO;
					return [
						asyncTaskActions.poll({
							name: EAsyncTask.PREVIOUS_OR_NEXT_STATE,
							id: result.task_id || '',
						}),
					];
				}),
				catchError((error) => {
					snackbarService.showErrorToast(
						error.response?.data.detail ||
							'Sorry, that action could not be completed. Please try again later or contact your administrator.'
					);
					return of(previousOrNextState.rejected());
				}),
				startWith(previousOrNextState.pending())
			);
		})
	);
};

const timeSeriesModelEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isValidEpicAction = isAnyOf(timeSeriesModel.fetch);

	return action$.pipe(
		filter(isValidEpicAction),
		switchMap(() =>
			from(fetchTimeSeriesModelApi()).pipe(
				map((data) => timeSeriesModel.fulfilled(data)),
				catchError(() => {
					return of(timeSeriesModel.rejected());
				}),
				startWith(timeSeriesModel.pending())
			)
		)
	);
};

const getDataEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isGetDataEpicAction = isAnyOf(getData.fetch);

	return action$.pipe(
		filter(isGetDataEpicAction),
		mergeMap((items) => {
			const api = items.payload.taskId
				? getDataApi({
						taskId: items.payload.taskId,
						isPublic: items.payload.isPublic,
				  })
				: getDataApi({
						chartId: items.payload.id,
						filters: items.payload.filters,
						secret: items.payload.secret,
						isPublic: items.payload.isPublic,
				  });

			return from(api).pipe(
				mergeMap((data) => {
					if (items.payload.taskId) {
						const response = data as IChartsData;
						if (response.truncated) {
							snackbarService.showWarningToast(
								'Data is truncated. Please download excel to view full data.'
							);
						}

						return of(
							getData.fulfilled({
								data: response,
								chartId: items.payload.chartId || -1,
							})
						);
					}
					const result = data as IAsyncTaskDTO;
					return [
						asyncTaskActions.poll({
							name: EAsyncTask.GET_DATA,
							id: result.task_id || '',
							chartId: items.payload.chartId,
							isPublic: items.payload.isPublic,
						}),
					];
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to fetch chart data.'
					);
					snackbarService.showErrorToast(message);
					return of(getData.rejected(items.payload.chartId || -1));
				}),
				startWith(getData.pending(items.payload.chartId || -1))
			);
		})
	);
};

const getChartDataEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isGetDataEpicAction = isAnyOf(getChartData.fetch);

	return action$.pipe(
		filter(isGetDataEpicAction),
		switchMap((items) => {
			const api = items.payload.taskId
				? getChartDataApi(-1, items.payload.taskId)
				: getChartDataApi(items.payload.id);

			return from(api).pipe(
				mergeMap((data) => {
					if (items.payload.taskId) {
						const response = data as IChartsData;
						if (response.truncated) {
							snackbarService.showWarningToast(
								'Data is truncated. Please download excel to view full data.'
							);
						}
						return of(getChartData.fulfilled(response));
					}
					const result = data as IAsyncTaskDTO;
					return [
						asyncTaskActions.poll({
							name: EAsyncTask.GET_CHART_DATA,
							id: result.task_id || '',
						}),
					];
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to fetch chart data.'
					);
					snackbarService.showErrorToast(message);
					return of(getChartData.rejected());
				}),
				startWith(getChartData.pending())
			);
		})
	);
};

const getAllItemsEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isAllItemsEpicAction = isAnyOf(getAllItems.fetch);

	return action$.pipe(
		filter(isAllItemsEpicAction),
		switchMap(() =>
			from(getAllItemsApi()).pipe(
				map((response) => getAllItems.fulfilled(response)),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to fetch chart data.'
					);
					snackbarService.showErrorToast(message);
					return of(getAllItems.rejected());
				}),
				startWith(getAllItems.pending())
			)
		)
	);
};

const getPinnedChartDataEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isGetPinnedChartDataEpicAction = isAnyOf(getPinnedChartData.fetch);

	return action$.pipe(
		filter(isGetPinnedChartDataEpicAction),
		switchMap((data) =>
			from(getPinnedChartDataApi(data.payload)).pipe(
				map((response) => getPinnedChartData.fulfilled(response)),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to fetch chart data.'
					);
					snackbarService.showErrorToast(message);
					return of(getPinnedChartData.rejected());
				}),
				startWith(getPinnedChartData.pending())
			)
		)
	);
};

const scheduleChartEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isScheduleChartEpicAction = isAnyOf(scheduleChart.schedule);

	return action$.pipe(
		filter(isScheduleChartEpicAction),
		switchMap((items) => {
			const { id, type, data } = items.payload;
			return from(scheduleReportApi(id, type, data)).pipe(
				map((response) => scheduleChart.fulfilled(response)),
				tap(() => {
					snackbarService.showSuccessToast('Chart has been scheduled.');
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to schedule report.'
					);
					snackbarService.showErrorToast(message);
					return of(scheduleChart.rejected());
				}),
				startWith(scheduleChart.pending())
			);
		})
	);
};

const shareChartEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isShareChartEpicAction = isAnyOf(shareChart.share);

	return action$.pipe(
		filter(isShareChartEpicAction),
		switchMap((items) => {
			return from(shareDiveChartApi(items.payload)).pipe(
				map((response) => shareChart.fulfilled(response)),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to share chart.'
					);
					snackbarService.showErrorToast(message);
					return of(shareChart.rejected());
				}),
				startWith(shareChart.pending())
			);
		})
	);
};

export const chartsEpic = combineEpics(
	searchQueryEpic,
	subCallEpic,
	previousOrNextStateEpic,
	timeSeriesModelEpic,
	selectedOptionEpic,
	getDataEpic,
	getChartDataEpic,
	getAllItemsEpic,
	getPinnedChartDataEpic,
	scheduleChartEpic,
	shareChartEpic
);
