import React, { useState } from 'react';
import { Box, Chip, Grid, IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface IProps {
	currentSearchTerm?: string | null;
	onSubmit: (text: string | null) => void;
}

const NavigatorSearch: React.FC<IProps> = (props) => {
	const { onSubmit, currentSearchTerm } = props;

	const [searchValue, setSearchValue] = useState<string>('');

	const onChangeSearchValue = (value: string) => {
		setSearchValue(value);
	};

	return (
		<Box mt={0.5}>
			<form
				noValidate
				autoComplete="off"
				onSubmit={(e) => {
					e.preventDefault();
					onSubmit(searchValue);
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<TextField
							id="navigator-search"
							data-testid="navigatorSearch"
							label="Search"
							name="searchInput"
							variant="outlined"
							color="primary"
							margin="dense"
							size="small"
							fullWidth
							value={searchValue}
							onChange={(e) => {
								onChangeSearchValue(e.target.value);
							}}
							InputProps={{
								endAdornment: (
									<IconButton
										type="submit"
										data-testid="navigatorSearchSubmit"
										aria-label="search"
										sx={{ p: 0.5 }}
									>
										<SearchIcon />
									</IconButton>
								),
							}}
						/>
					</Grid>
				</Grid>
			</form>

			{currentSearchTerm ? (
				<Box my={2}>
					<Chip
						color="secondary"
						label={<strong>{`Search: ${currentSearchTerm}`}</strong>}
						onDelete={() => {
							onSubmit(null);
						}}
						data-testid="navigatorSearchChip"
					/>
				</Box>
			) : null}
		</Box>
	);
};

NavigatorSearch.defaultProps = {
	currentSearchTerm: '',
};

export default NavigatorSearch;
