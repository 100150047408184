import axiosInstance from './axios/axios';
import { serializeLoginCheckResponse } from '../serializers/auth.serializer';
import { ILoginCheckResponseDTO } from '../models/dto';

export const authenticate = async () => {
	const response = await axiosInstance.get<ILoginCheckResponseDTO>(
		'/ddive/api/users/current_user/'
	);
	const loginCheckResponse = serializeLoginCheckResponse(response.data);

	return loginCheckResponse;
};

export const logout = () => {
	return axiosInstance
		.get('/ddive/api/auth/logout/?redirect=false')
		.then((response) => response.data);
};
