import { ILoginCheckResponse } from '../models';
import { ILoginCheckResponseDTO } from '../models/dto';

export const serializeLoginCheckResponse = (
	response: ILoginCheckResponseDTO
): ILoginCheckResponse => ({
	email: response.email,
	firstName: response.first_name,
	lastName: response.last_name,
	role: response.profile,
	username: response.username,
	id: response.id,
});
