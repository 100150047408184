import React from 'react';
import { createSvgIcon } from '@mui/material';

const DiveIcon = createSvgIcon(
	<svg viewBox="0 0 32 32">
		<path
			id="a"
			d="M20.667 19.667h-1.054l-.373-.36a8.628 8.628 0 0 0 2.093-5.64A8.666 8.666 0 0 0 12.667 5 8.666 8.666 0 0 0 4 13.667a8.666 8.666 0 0 0 8.667 8.666c2.146 0 4.12-.786 5.64-2.093l.36.373v1.054l6.666 6.653 1.987-1.987-6.653-6.666zm-8 0c-3.32 0-6-2.68-6-6s2.68-6 6-6 6 2.68 6 6-2.68 6-6 6z"
		/>
	</svg>,
	'Dive'
);

export default DiveIcon;
