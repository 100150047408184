import { createSlice } from '@reduxjs/toolkit';
import {
	ENumberSystem,
	ETableImageSize,
	IAdminPreferences,
} from '../../models';
import { IAppSettingsState } from '../../models/store';
import { themeDefaultValues } from '../../theme/theme';
import {
	fetchAdminPreferences,
	fetchCustomerConfig,
	fetchCustomerSecrets,
	fetchUserPreferences,
	resetAdminPreferences,
	resetUserPreferences,
	updateAdminPreferences,
	updateCustomerConfig,
	updateCustomerSecrets,
	updateUserPreferences,
} from '../actions/appSettings.action';

export const adminPreferencesInitialValue: IAdminPreferences = {
	primaryColor: themeDefaultValues.primaryDefaultColor,
	secondaryColor: themeDefaultValues.secondaryDefaultColor,
	axisKeywordsLimit: 10000,
	numberSystem: ENumberSystem.INTERNATIONAL,
	numberFormatDecimals: 2,
	queryTimeout: 200,
	dataLimit: 100000,
	growthInSimpleCation: true,
	tableImageSize: ETableImageSize.MEDIUM,
	keywordRefreshTime: 5,
};

const INITIAL_STATE: IAppSettingsState = {
	userPreferences: {
		numberFormatDecimals: 2,
		numberSystem: ENumberSystem.INTERNATIONAL,
		tableImageSize: ETableImageSize.MEDIUM,
	},
	adminPreferences: adminPreferencesInitialValue,
	appConfigurations: {
		customerConfigDetails: null,
		customerSecretDetails: null,
	},

	userPreferencesStatuses: {
		fetchUserPreferencesStatus: 'idle',
		updateUserPreferencesStatus: 'idle',
		resetUserPreferencesStatus: 'idle',
	},
	appConfigurationsStatuses: {
		fetchCustomerConfigStatus: 'idle',
		fetchCustomerSecretStatus: 'idle',
		updateCustomerConfigStatus: 'idle',
		updateCustomerSecretStatus: 'idle',
	},
	adminPreferencesStatuses: {
		fetchAdminPreferencesStatus: 'idle',
		updateAdminPreferencesStatus: 'idle',
		resetAdminPreferencesStatus: 'idle',
	},
};

const appSettingsSlice = createSlice({
	name: 'usageLogs',
	initialState: INITIAL_STATE,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCustomerConfig.pending, (state) => {
				state.appConfigurationsStatuses.fetchCustomerConfigStatus = 'pending';
			})
			.addCase(fetchCustomerConfig.rejected, (state) => {
				state.appConfigurationsStatuses.fetchCustomerConfigStatus = 'rejected';
			})
			.addCase(fetchCustomerConfig.fulfilled, (state, action) => {
				state.appConfigurations.customerConfigDetails = action.payload;
				state.appConfigurationsStatuses.fetchCustomerConfigStatus = 'fulfilled';
			})
			.addCase(fetchCustomerSecrets.pending, (state) => {
				state.appConfigurationsStatuses.fetchCustomerSecretStatus = 'pending';
			})
			.addCase(fetchCustomerSecrets.rejected, (state) => {
				state.appConfigurationsStatuses.fetchCustomerSecretStatus = 'rejected';
			})
			.addCase(fetchCustomerSecrets.fulfilled, (state, action) => {
				state.appConfigurations.customerSecretDetails = action.payload;
				state.appConfigurationsStatuses.fetchCustomerSecretStatus = 'fulfilled';
			})
			.addCase(updateCustomerConfig.pending, (state) => {
				state.appConfigurationsStatuses.updateCustomerConfigStatus = 'pending';
			})
			.addCase(updateCustomerConfig.rejected, (state) => {
				state.appConfigurationsStatuses.updateCustomerConfigStatus = 'rejected';
			})
			.addCase(updateCustomerConfig.fulfilled, (state, action) => {
				state.appConfigurations.customerConfigDetails = action.payload;
				state.appConfigurationsStatuses.updateCustomerConfigStatus =
					'fulfilled';
			})
			.addCase(updateCustomerSecrets.pending, (state) => {
				state.appConfigurationsStatuses.updateCustomerSecretStatus = 'pending';
			})
			.addCase(updateCustomerSecrets.rejected, (state) => {
				state.appConfigurationsStatuses.updateCustomerSecretStatus = 'rejected';
			})
			.addCase(updateCustomerSecrets.fulfilled, (state, action) => {
				state.appConfigurations.customerSecretDetails = action.payload;
				state.appConfigurationsStatuses.updateCustomerSecretStatus =
					'fulfilled';
			})

			// USER Preferences  -----------------------------------------------
			.addCase(fetchUserPreferences.pending, (state) => {
				state.userPreferencesStatuses.fetchUserPreferencesStatus = 'pending';
			})
			.addCase(fetchUserPreferences.rejected, (state) => {
				state.userPreferencesStatuses.fetchUserPreferencesStatus = 'rejected';
			})
			.addCase(fetchUserPreferences.fulfilled, (state, action) => {
				state.userPreferences = action.payload;
				state.userPreferencesStatuses.fetchUserPreferencesStatus = 'fulfilled';
			})
			.addCase(resetUserPreferences.pending, (state) => {
				state.userPreferencesStatuses.resetUserPreferencesStatus = 'pending';
			})
			.addCase(resetUserPreferences.rejected, (state) => {
				state.userPreferencesStatuses.resetUserPreferencesStatus = 'rejected';
			})
			.addCase(resetUserPreferences.fulfilled, (state, action) => {
				state.userPreferences = action.payload;
				state.userPreferencesStatuses.resetUserPreferencesStatus = 'fulfilled';
			})
			.addCase(updateUserPreferences.pending, (state) => {
				state.userPreferencesStatuses.updateUserPreferencesStatus = 'pending';
			})
			.addCase(updateUserPreferences.rejected, (state) => {
				state.userPreferencesStatuses.updateUserPreferencesStatus = 'rejected';
			})
			.addCase(updateUserPreferences.fulfilled, (state, action) => {
				state.userPreferences = action.payload;
				state.userPreferencesStatuses.updateUserPreferencesStatus = 'fulfilled';
			})

			// ADMIN Preferences  ----------------------------------------------
			.addCase(fetchAdminPreferences.pending, (state) => {
				state.adminPreferencesStatuses.fetchAdminPreferencesStatus = 'pending';
			})
			.addCase(fetchAdminPreferences.rejected, (state) => {
				state.adminPreferencesStatuses.fetchAdminPreferencesStatus = 'rejected';
			})
			.addCase(fetchAdminPreferences.fulfilled, (state, action) => {
				state.adminPreferences = action.payload;
				state.adminPreferencesStatuses.fetchAdminPreferencesStatus =
					'fulfilled';
			})
			.addCase(resetAdminPreferences.pending, (state) => {
				state.adminPreferencesStatuses.resetAdminPreferencesStatus = 'pending';
			})
			.addCase(resetAdminPreferences.rejected, (state) => {
				state.adminPreferencesStatuses.resetAdminPreferencesStatus = 'rejected';
			})
			.addCase(resetAdminPreferences.fulfilled, (state, action) => {
				state.adminPreferences = action.payload;
				state.adminPreferencesStatuses.resetAdminPreferencesStatus =
					'fulfilled';
			})
			.addCase(updateAdminPreferences.pending, (state) => {
				state.adminPreferencesStatuses.updateAdminPreferencesStatus = 'pending';
			})
			.addCase(updateAdminPreferences.rejected, (state) => {
				state.adminPreferencesStatuses.updateAdminPreferencesStatus =
					'rejected';
			})
			.addCase(updateAdminPreferences.fulfilled, (state, action) => {
				state.adminPreferences = action.payload;
				state.adminPreferencesStatuses.updateAdminPreferencesStatus =
					'fulfilled';
			});
	},
});

export const appSettingsActions = {
	...appSettingsSlice.actions,
	fetchCustomerConfig,
	fetchCustomerSecrets,
	updateCustomerSecrets,
	updateCustomerConfig,
	fetchUserPreferences,
	resetUserPreferences,
	updateUserPreferences,
	fetchAdminPreferences,
	updateAdminPreferences,
	resetAdminPreferences,
};

export default appSettingsSlice.reducer;
