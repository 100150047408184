// libraries imports
import React, { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';

// mui imports
import { Box } from '@mui/material';

// project imports
import AppNavbar from '../../components/navigation/AppNavbar/AppNavbar';
import LazyLoader from '../../components/common/LazyLoader/LazyLoader';
import useScreenSize from '../../hooks/useScreenSize';
import SideDrawer from '../../components/navigation/SideDrawer/SideDrawer';

// lazy imports
const SnackbarContainer = React.lazy(
	() =>
		import(
			/* webpackChunkName: "Home" */ '../../components/snackbar/SnackbarContainer'
		)
);

const FeedbackButton = React.lazy(
	() =>
		import(
			/* webpackChunkName: "FeedbackButton" */ '../../components/common/Feedback/FeedbackButton'
		)
);

// ========================|| MAIN LAYOUT ||==================================//
const MainLayout: React.FC = () => {
	// states
	const [open, setOpen] = useState(false);

	// hooks and variables
	const { isTabletOrLess } = useScreenSize();
	const isPublicChartOrDashboard = [
		'chartWithFilters',
		'dashboardWithFilters',
	].includes(window.location.pathname.split('/')[2]);

	// event handlers
	const handleOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	return (
		<>
			<Box sx={{ display: 'flex', height: '100%' }}>
				{/* hide navbar and sidebar for public charts and dashboards  */}
				{!isPublicChartOrDashboard && (
					<AppNavbar handleDrawerToggle={handleOpen} />
				)}
				{!isPublicChartOrDashboard && isTabletOrLess && (
					<SideDrawer
						open={open}
						handleOpen={handleOpen}
						handleClose={handleClose}
					/>
				)}
				<Outlet />
			</Box>

			<LazyLoader>
				{!isPublicChartOrDashboard && <FeedbackButton />}
				<SnackbarContainer />
			</LazyLoader>
		</>
	);
};

export default MainLayout;
