/* eslint-disable no-underscore-dangle */
import {
	IFiltersPayload,
	IAxisItems,
	IChartsData,
	IDisambiguationItems,
	IFilterItems,
	IJoinPathItems,
	IMetricItems,
	IOtherItems,
	ISubQueriesItems,
	ISubQueryAxes,
	ISubQueryFilters,
	ISubQueryJoinPaths,
	ISubQueryMetrics,
	ITableItems,
	ITimeSeriesModel,
	IMetricsPayload,
	ISubCallItemsPayload,
	ISubCallPayload,
	IContextFiltersPayload,
	IJoinPathsPayload,
	ISortingPayload,
	ITopBottomFiltersPayload,
	IPinChartPayload,
	IAxisOrderSet,
	IMetricOrderSet,
	IPinnedChartData,
	IAllItems,
	IDownloadPayload,
	ISearchedKeywords,
} from '../models';
import {
	IAxesPayloadDTO,
	IContextFiltersPayloadDTO,
	IFiltersPayloadDTO,
	IAxesDTO,
	IChartsDataDTO,
	IDisambiguationItemsDTO,
	IFiltersDTO,
	IJoinPathsDTO,
	IMetricsDTO,
	IOtherItemsDTO,
	IRelatedAxesDTO,
	IRelatedFiltersDTO,
	IRelatedMetricDTO,
	ISubQueriesDTO,
	ITableDTO,
	ITimeSeriesModelDTO,
	IMetricsPayloadDTO,
	subCallPayloadDTO,
	IJoinPathsPayloadDTO,
	ISortingPayloadDTO,
	ITopBottomFiltersPayloadDTO,
	IPinChartPayloadDTO,
	IAxisOrderSetDTO,
	IMeasureOrderSetDTO,
	IPinnedChartDataDTO,
	IMeasureOrderSetPayloadDTO,
	IAllItemsDTO,
	IDownloadPayloadDTO,
	ISearchedKeywordsDTO,
} from '../models/dto';
import { serializeDataSourceDetails } from './dataSource.serializer';
import { serializeReportSchedule } from './schedule.serializer';
import { serializeUser } from './user.serializer';

type TSubQuery = {
	subQueryKey: string;
	name: string;
	joinPaths: ISubQueryJoinPaths[];
	metrics: ISubQueryMetrics[];
	axes: ISubQueryAxes[];
	filters: ISubQueryFilters[];
};

const axisItems = [
	'SQLItem',
	'AxisItem',
	'ComparisonItem',
	'FromItem',
	'JoinPath',
	'OtherItem',
	'SelectItem',
	'TimeItem',
	'DynamicDateItem',
];

// Options Response Serializers  -----------------------------------------------
const serializeOptionsJoinPaths = (
	items: IDisambiguationItemsDTO,
	key: string
) => {
	const serializedJoinPaths: ISubQueryJoinPaths[] = [];
	items.options[key][0].join_paths.forEach((joinPath) => {
		const serializedJoinPath: ISubQueryJoinPaths = {
			fromKey: '',
			toKey: '',
			aliases: [],
		};

		serializedJoinPath.fromKey = joinPath[0].from_key;
		serializedJoinPath.toKey = joinPath[0].to_key;
		serializedJoinPath.aliases = joinPath[0].aliases;

		serializedJoinPaths.push(serializedJoinPath);
	});
	return serializedJoinPaths;
};

const serializeOptionsItems = (items: IDisambiguationItemsDTO, key: string) => {
	const serializedMetrics: ISubQueryMetrics[] = [];
	const serializedAxes: ISubQueryAxes[] = [];
	const serializedFilters: ISubQueryFilters[] = [];

	items.options[key][0].sql_items.forEach((sqlItem) => {
		const serializedMetric: ISubQueryMetrics = {
			name: '',
			table: '',
			alias: '',
			aggregation: '',
		};
		const serializedAxis: ISubQueryAxes = {
			name: '',
			alias: '',
			table: '',
		};
		const serializedFilter: ISubQueryFilters = {
			alias: '',
			name: '',
			table: '',
			filterOperator: '',
			filterValue: '',
		};

		if (axisItems.includes(sqlItem.type)) {
			serializedAxis.alias = sqlItem.alias;
			serializedAxis.name = sqlItem.name;
			serializedAxis.table = sqlItem.table;
			serializedAxes.push(serializedAxis);
		} else if (sqlItem.type === 'MeasureItem') {
			serializedMetric.name = sqlItem.name;
			serializedMetric.table = sqlItem.table;
			serializedMetric.alias = sqlItem.alias;
			serializedMetric.aggregation = sqlItem.aggregation;
			serializedMetrics.push(serializedMetric);
		} else if (sqlItem.type === 'FilterItem') {
			serializedFilter.name = sqlItem.name;
			serializedFilter.table = sqlItem.table;
			serializedFilter.alias = sqlItem.alias;
			serializedFilter.filterOperator = sqlItem.filter_ops;
			serializedFilter.filterValue = sqlItem.filter_value1;
			serializedFilters.push(serializedFilter);
		}
	});
	return { serializedMetrics, serializedAxes, serializedFilters };
};

export const serializeOptionsResponse = (
	items: IDisambiguationItemsDTO
): IDisambiguationItems => {
	const serializedItems: IDisambiguationItems = {
		dagId: -1,
		responseType: '',
		subQueries: [],
		stateChangeable: [],
		modelResponse: {
			biTag: '',
			columnTag: '',
			correctedQuestion: '',
			query: '',
			questionTag: '',
			typeTag: '',
		},
	};
	// prepare serialized subQueries
	Object.keys(items.options).forEach((key) => {
		const subQuery: TSubQuery = {
			subQueryKey: '',
			name: '',
			joinPaths: [],
			metrics: [],
			axes: [],
			filters: [],
		};
		// prepare serialized join paths
		const serializedJoinPaths = serializeOptionsJoinPaths(items, key);

		// prepare serialized metrics, axes and filters
		const { serializedMetrics, serializedAxes, serializedFilters } =
			serializeOptionsItems(items, key);

		Object.assign(subQuery, {
			subQueryKey: key,
			name: items.options[key][0].name,
			joinPaths: serializedJoinPaths,
			metrics: serializedMetrics,
			axes: serializedAxes,
			filters: serializedFilters,
		});
		serializedItems.subQueries.push(subQuery);
	});

	serializedItems.dagId = items.dag_id;
	serializedItems.responseType = items._type;
	serializedItems.stateChangeable = items.state_changeable;
	serializedItems.modelResponse = {
		biTag: items.model_response.biTag,
		columnTag: items.model_response.columnTag,
		correctedQuestion: items.model_response.corrected_question,
		query: items.model_response.query,
		questionTag: items.model_response.questionTag,
		typeTag: items.model_response.typeTag,
	};

	return serializedItems;
};

// END: Options Response Serializers  ------------------------------------------

// Metrics Serializers  --------------------------------------------------------
const serializeRelatedMetricsItem = (
	item: IRelatedMetricDTO
): IMetricItems => ({
	aggregation: item.aggregation,
	aggregationList: item.aggregation_list || [],
	alias: item.alias,
	customSort: item.custom_sort || false,
	dataType: item.data_type,
	metaId: item.meta_id,
	name: item.name,
	table: item.table,
	type: item.type,
	sortOperations: {
		ascending: item.sort_operations?.ascending,
		measureColumn: item.sort_operations?.measure_column,
	},
	queryName: '',
	dagId: -1,
	unitsOfMeasure: {
		label: item.uom.label,
		uomType: item.uom.uom_type,
		uom: item.uom.uom,
	},
	dataSource: serializeDataSourceDetails(item.data_source || []),
	aggregateAxisId: item.aggregate_axis_id,
});

const serializeRelatedMetricsItems = (
	items: IRelatedMetricDTO[]
): IMetricItems[] => {
	return items.map((metric) => serializeRelatedMetricsItem(metric));
};

const serializeSelectedMetricItems = (
	items: IMetricsDTO,
	dagId: number
): IMetricItems => ({
	aggregation: items.item.aggregation,
	aggregationList: items.item.aggregation_list,
	alias: items.item.alias,
	customSort: items.item.custom_sort,
	dataType: items.item.data_type,
	metaId: items.item.meta_id,
	name: items.item.name,
	table: items.item.table,
	type: items.item.type,
	sortOperations: {
		ascending: items.sort_operation?.ascending,
		measureColumn: items.sort_operation?.measure_column,
	},
	queryName: items.item.query_name,
	dagId,
	unitsOfMeasure: {
		label: items.item.units_of_measure.label,
		uomType: items.item.units_of_measure.uom_type,
		uom: items.item.units_of_measure.uom,
	},
	dataSource: serializeDataSourceDetails(items.item.data_source || []),
	aggregateAxisId: items.item.aggregate_axis_id,
});

const serializeSelectedMetricAllItems = (
	items: IMetricsDTO[],
	dagId: number
): IMetricItems[] =>
	items.map((item) => serializeSelectedMetricItems(item, dagId));

// END: Metrics Serializers  ---------------------------------------------------

// Axes Serializers  -----------------------------------------------------------
const serializeRelatedAxesItem = (item: IRelatedAxesDTO): IAxisItems => ({
	axisPriority: item.axis_priority,
	alias: item.alias,
	customSort: item.custom_sort,
	dataType: item.data_type,
	metaId: item.meta_id,
	name: item.name,
	tokenFormat: item.token_format,
	table: item.table,
	category: item.category,
	type: item.type,
	sortOperation: {
		ascending: item.sort_operation?.ascending,
		measureColumn: item.sort_operation?.measure_column,
	},
	keywords: item.keywords,
	keywordsFromData: [],
	isContinuous: item.is_continous_axis,
});
const serializeRelatedAxesItems = (items: IRelatedAxesDTO[]): IAxisItems[] => {
	return items.map((axis) => serializeRelatedAxesItem(axis));
};
const serializeSelectedAxisItem = (items: IAxesDTO): IAxisItems => ({
	axisPriority: items.axis_priority,
	alias: items.item.alias,
	customSort: items.item.custom_sort,
	dataType: items.item.data_type,
	metaId: items.item.meta_id,
	name: items.item.name,
	tokenFormat: items.item.token_format,
	table: items.item.table,
	type: items.item.type,
	sortOperation: {
		ascending: items.sort_operation?.ascending,
		measureColumn: items.sort_operation?.measure_column,
	},
	keywords: items.item.keywords.map((item) => ({
		keywords: item[0],
		aliases: [...item[1]],
	})),
	keywordsFromData: items.keywords,
	isContinuous: items.item.is_continous_axis,
});
const serializeSelectedAxisItems = (items: IAxesDTO[]): IAxisItems[] => {
	return items.map((axis) => {
		return serializeSelectedAxisItem(axis);
	});
};

// END: Axes Serializers  ------------------------------------------------------

// Filters Serializers  --------------------------------------------------------

const serializeRelatedFiltersItem = (
	item: IRelatedFiltersDTO
): IFilterItems => ({
	isTime: item.is_time,
	alias: item.alias,
	filterOperator: item.filter_ops,
	filterValue1: item.filter_value1,
	filterValue2: item.filter_value2,
	metaId: item.meta_id,
	name: item.name,
	table: item.table,
	type: item.type,
	system: item.system,
	keywords: item.keywords,
	word: item?.word,
	category: item.category,
});

const serializeRelatedFiltersItems = (
	items: IRelatedFiltersDTO[]
): IFilterItems[] => {
	return items.map((filter) => serializeRelatedFiltersItem(filter));
};

const serializeSelectedFilterItem = (items: IFiltersDTO): IFilterItems => ({
	isTime: items.is_time,
	alias: items.item.alias,
	filterOperator: items.item.filter_ops,
	filterValue1: items.item.filter_value1,
	filterValue2: items.item.filter_value2,
	metaId: items.item.meta_id,
	name: items.item.name,
	table: items.item.table,
	type: items.item.type,
	system: items.system,
	keywords: items.keywords || [],
	word: items.item.word || '',
	queryName: items.subquery_name,
});

export const serializeSelectedFilterItems = (
	items: IFiltersDTO[]
): IFilterItems[] => {
	return items.map((filter) => serializeSelectedFilterItem(filter));
};

// END: Filters Serializers  ---------------------------------------------------

const serializeTableItems = (items: ITableDTO): ITableItems => ({
	catalog: items.catalog,
	db: items.db,
	schema: items.schema,
	tableName: items.table_name,
	tableType: items._type,
});

const serializeJoinPaths = (items: IJoinPathsDTO): IJoinPathItems => ({
	aliases: items.aliases,
	fromKey: items.from_key,
	toKey: items.to_key,
	isSelected: items.is_selected,
	joinPathType: items._type,
	fromTable: serializeTableItems(items.from_table),
	toTable: serializeTableItems(items.to_table),
	innerJoinPath: items._join_path,
	pathDef: items.path_def,
});

const serializeOtherItems = (items: IOtherItemsDTO): IOtherItems => ({
	limit: items.item.limit,
	parentNodeId: items.item.parent_node_id,
	relatedMeasureName: items.item.related_measure_name,
	topOfSliceName: items.item.top_of_slice_name,
	type: items.item.type,
	measureMetaId: items.item.measure_meta_id,
});

const serializeSubQueries = (items: ISubQueriesDTO): ISubQueriesItems => ({
	alias: items.alias,
	contextFilters: items.context_filters.map((contextFilterItems) =>
		serializeSelectedFilterItem(contextFilterItems)
	),
	queryName: items.query_name,
	sql: items.sql,
	joinPaths: items.join_paths.map((joinPathItems) =>
		serializeJoinPaths(joinPathItems)
	),
	otherItems: items.other_items.map((otherItem) =>
		serializeOtherItems(otherItem)
	),
});

export const serializeChartsData = (items: IChartsDataDTO): IChartsData => {
	return {
		chartTitle: items.chart_title,
		chartId: items.chart_id,
		chartTypes: items.chart_types,
		correctedText: items.corrected_text,
		dagId: items.dag_id,
		searchText: items.search_text,
		stateChangeable: items.state_changeable,
		responseType: items._type,
		data: items.data,
		selectedAxes: serializeSelectedAxisItems(items.axes),
		selectedSeries: serializeSelectedAxisItems(items.axes.slice(1)),
		selectedFilters: serializeSelectedFilterItems(items.filters),
		selectedMetrics: serializeSelectedMetricAllItems(
			items.measures,
			items.dag_id
		),
		drillDownAxes: serializeRelatedAxesItems(items.drilldown_axes),
		axes: items.related_axes
			? serializeRelatedAxesItems(items.related_axes)
			: [],
		filters: items.related_filters
			? serializeRelatedFiltersItems(items.related_filters)
			: [],
		metrics: items.related_measures
			? serializeRelatedMetricsItems(items.related_measures)
			: [],
		subQueries: items.subqueries.map((subQuery) =>
			serializeSubQueries(subQuery)
		),
		truncated: items.truncated,
	};
};

export const serializeAllItems = (items: IAllItemsDTO): IAllItems => ({
	axes: serializeRelatedAxesItems(items.related_axes),
	filters: serializeRelatedFiltersItems(items.related_filters),
	metrics: serializeRelatedMetricsItems(items.related_measures),
});

export const serializeAxisOrderSet = (
	items: IAxisOrderSetDTO
): IAxisOrderSet => ({
	metaId: items.meta_id,
	order: items.order,
	isColumn: items.is_column,
	alias: items.alias,
});

export const serializeMeasureOrderSet = (
	items: IMeasureOrderSetDTO
): IMetricOrderSet => ({
	metaId: items.meta_id,
	measureName: items.measure_name,
	order: items.order,
});

export const serializePinnedChartData = (
	items: IPinnedChartDataDTO
): IPinnedChartData => ({
	axisOrderSet: items.axisorder_set.map((axisOrderSetItems) =>
		serializeAxisOrderSet(axisOrderSetItems)
	),
	measureOrderSet: items.measureorder_set.map((measureOrderSetItems) =>
		serializeMeasureOrderSet(measureOrderSetItems)
	),
	chartData: items.chart_data,
	chartTitle: items.chart_title,
	chartType: items.chart_type,
	collection: items.collection,
	createdDate: items.created_date,
	dashboardId: items.dashboard_id,
	explore: items.explore,
	exploreTemplate: items.explore_template,
	height: items.height,
	width: items.width,
	id: items.id,
	isActive: items.is_active,
	isScheduled: items.is_scheduled,
	isSystem: items.is_system,
	isPublic: items.is_public,
	modifiedDate: items.modified_date,
	position: items.position,
	schedule: items.schedule ? serializeReportSchedule(items.schedule) : null,
	showDataPoints: items.show_data_points,
	showXAxis: items.show_X_axis === undefined ? true : items.show_X_axis,
	showYAxis: items.show_Y_axis === undefined ? true : items.show_Y_axis,
	showTitle: items.show_title === undefined ? true : items.show_title,
	showLegends: items.show_legends === undefined ? true : items.show_legends,
	startCoordinates: items.start_coordinates,
	userId: serializeUser(items.user_id),
	sharedWith: items.shared_user_ids.map((item) => serializeUser(item)),
	subtotalFlag: items.subtotal_flag,
	totalFlag: items.total_flag,
	origChartData: items.orig_chart_data,
});

export const serializePinnedChartsData = (
	items: IPinnedChartDataDTO[]
): IPinnedChartData[] => items.map((item) => serializePinnedChartData(item));

export const serializeTimeSeriesModel = (
	items: ITimeSeriesModelDTO
): ITimeSeriesModel => ({
	active: items.active,
	alias: items.alias,
	metaId: items.meta_id,
	name: items.name,
	table: items.table,
	type: items.type,
});

export const serializeSearchedKeyword = (
	items: ISearchedKeywordsDTO
): ISearchedKeywords => ({
	alias: items.alias,
	metaId: items.meta_id,
	name: items.name,
	table: items.table,
	type: items.type,
	keywords: items.keywords,
	category: items.category,
});

export const serializeSearchedKeywords = (
	dataDto: ISearchedKeywordsDTO[]
): ISearchedKeywords[] =>
	dataDto.map((dataItem) => serializeSearchedKeyword(dataItem));

// Deserializer  ---------------------------------------------------------------
export const deserializeMetricPayload = (
	item: IMetricsPayload
): IMetricsPayloadDTO => ({
	change_type: item.changeType,
	item: {
		meta_id: item.metaId,
		type: item.type,
		aggregation: item.aggregation,
		aggregate_axis_id: item.aggregateAxisId,
	},
	query_name: item.queryName,
});

export const deserializeFilterPayload = (
	item: IFiltersPayload
): IFiltersPayloadDTO => ({
	change_type: item.changeType,
	item: {
		meta_id: item.metaId,
		type: item.type,
		filter_ops: item.filterOperator,
		filter_value1: item.filterValue1,
		filter_value2: item.filterValue2,
		word: item.word,
	},
	...(item.queryName && { query_name: item.queryName }),
});

export const deserializeContextFilterPayload = (
	item: IContextFiltersPayload
): IContextFiltersPayloadDTO => ({
	change_type: item.changeType,
	item: {
		meta_id: item.metaId,
		type: item.type,
		filter_ops: item.filterOperator,
		filter_value1: item.filterValue1,
		filter_value2: item.filterValue2,
		word: item.word,
	},
	query_name: item.queryName,
	measure_meta_id: item.measureMetaId,
});

export const deserializeAxisPayload = (
	item: ISubCallItemsPayload
): IAxesPayloadDTO => ({
	change_type: item.changeType,
	item: {
		meta_id: item.metaId,
		type: item.type,
	},
});

export const deserializeJoinPathTables = (item: ITableItems): ITableDTO => ({
	catalog: item.catalog,
	db: item.db,
	schema: item.schema,
	table_name: item.tableName,
	_type: item.tableType,
});

export const deserializeJoinPaths = (
	payload: IJoinPathsPayload
): IJoinPathsPayloadDTO => ({
	change_type: 'change',
	item: {
		from_table: deserializeJoinPathTables(payload.fromTable),
		from_key: payload.fromKey,
		to_table: deserializeJoinPathTables(payload.toTable),
		to_Key: payload.toKey,
		join_path: payload.innerJoinPath,
	},
	query_name: payload.queryName,
	measure_meta_id: payload.measureMetaId,
});

export const deserializeSortingPayload = (
	item: ISortingPayload
): ISortingPayloadDTO => ({
	item: {
		ascending: item.ascending,
		custom_sort_def: item.customSortDef,
		axes: item.axes,
		measure_column: item.metricColumn,
		sort_column: item.sortColumn,
	},
});

export const deserializeTopBottomFiltersPayload = (
	item: ITopBottomFiltersPayload
): ITopBottomFiltersPayloadDTO => ({
	change_type: item.changeType,
	query_name: item.queryName,
	item: {
		limit: item.limit,
		parent_node_id: item.parentNodeId,
		related_measure_name: item.relatedMeasureName,
		top_of_slice_name: item.topOfSliceName,
		type: item.type,
	},
	measure_meta_id: item.measureMetaId,
});

export const deserializeDownloadPayload = (
	items: IDownloadPayload
): IDownloadPayloadDTO => ({
	chart_title: items.chartTitle,
	columns: items.columns,
	dag_id: items.dagId,
	disable_subtotals: items.disableSubtotals,
	measures: items.measures,
	rows: items.rows,
});

export const deserializeAxisOrderSet = (
	items: IAxisOrderSet
): IAxisOrderSetDTO => ({
	meta_id: items.metaId,
	order: items.order,
	is_column: items.isColumn,
	alias: items.alias,
});

export const deserializeMeasureOrderSet = (
	items: IMetricOrderSet
): IMeasureOrderSetPayloadDTO => ({
	meta_id: items.metaId,
	name: items.measureName,
	order: items.order,
});

export const deserializeSubCallPayload = (
	items: ISubCallPayload
): subCallPayloadDTO => ({
	measures: items?.metrics?.map((metric) => deserializeMetricPayload(metric)),
	axes: items?.axes?.map((axis) => deserializeMetricPayload(axis)),
	filters: items?.filters?.map((filter) => deserializeFilterPayload(filter)),
	context_filters: items?.contextFilters?.map((filter) =>
		deserializeContextFilterPayload(filter)
	),
	join_paths: items?.joinPaths?.map((joinPath) =>
		deserializeJoinPaths(joinPath)
	),
	dag_operations: items?.sortingData?.map((sortingDataItem) =>
		deserializeSortingPayload(sortingDataItem)
	),
	other_items: items?.topBottomFilters?.map((item) =>
		deserializeTopBottomFiltersPayload(item)
	),
	axisorder_set:
		items?.axisOrderSet?.map((axisOrder) =>
			deserializeAxisOrderSet(axisOrder)
		) || [],
	measureorder_set:
		items?.measureOrderSet?.map((measureOrder) =>
			deserializeMeasureOrderSet(measureOrder)
		) || [],
});

export const deserializePinChartPayload = (
	items: IPinChartPayload
): IPinChartPayloadDTO => ({
	axes: items.axes,
	axisorder_set: items.axisOrderSet.map((axisOrderSetItems) =>
		deserializeAxisOrderSet(axisOrderSetItems)
	),
	measure: items.measures,
	measureorder_set: items.measureOrderSet.map((measureOrderSetItems) =>
		deserializeMeasureOrderSet(measureOrderSetItems)
	),
	chart_data: items.chartData,
	chart_type: items.chartType,
	chart_title: items.chartTitle,
	dashboard_id: items.dashboardId,
	start_coordinates: items.startCoordinates,
	filter_count: items.filterCount,
	is_swapped: items.isSwapped,
	show_X_axis: items.showXAxis,
	show_Y_axis: items.showYAxis,
	show_legends: items.showLegends,
	is_system: items.isSystem,
	position: items.position,
	show_data_points: items.showDataPoints,
	subtotal_flag: items.subTotalFlag,
	total_flag: items.totalFlag,
});

export const deserializePinChartsPayload = (
	items: IPinChartPayload[]
): IPinChartPayloadDTO[] =>
	items.map((item) => deserializePinChartPayload(item));
// END: Deserializer  ----------------------------------------------------------
