// libraries imports
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// mui imports
import { Box, Grid } from '@mui/material';

// =======================|| DATA SUMMARY ||==================================//
const DataSummary: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>DDive: Data Summary</title>
				<meta name="viewport" content="width=device-width, initial-scale=0.5" />
			</Helmet>
			<Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
				<Grid container spacing={0} sx={{ height: '100%' }}>
					<Box sx={{ height: '100%', width: '100%', p: 3, overflow: 'auto' }}>
						<Outlet />
					</Box>
				</Grid>
			</Box>
		</>
	);
};

export default DataSummary;
