import { ILicenseInfoDTO } from '../models/dto';
import { ILicenseInfo } from '../models/licenseInfo.model';

export const serializeLicenseInfo = (
	licenseInfo: ILicenseInfoDTO
): ILicenseInfo => ({
	customerName: licenseInfo.customer_name,
	customerUUID: licenseInfo.customer_uuid,
	licenseKey: licenseInfo.license_key,
	maxNormalUsers: licenseInfo.max_normal_user,
	maxReaderUsers: licenseInfo.max_reader_user,
	licenseExpiryDate: licenseInfo.license_expiry_date,
	lastCheckedDate: licenseInfo.last_check_date,
});
