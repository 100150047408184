import {
	ISearchAccuracyBulkUpdate,
	ISearchAccuracyItem,
	ISearchAccuracyUpdate,
} from '../models';
import {
	ISearchAccuracyBulkUpdateDTO,
	ISearchAccuracyItemDTO,
	ISearchAccuracyUpdateDTO,
} from '../models/dto';

export const serializeSearchAccuracyItem = (
	item: ISearchAccuracyItemDTO
): ISearchAccuracyItem => ({
	id: item.id,
	isAccurate: item.is_accurate,
	alias: item.alias,
	item: {
		name: item.item.name,
		table: item.item.table,
		axisName: item.item.axis_name,
	},
	type: item.type,
});

export const serializeSearchAccuracyItems = (
	items: ISearchAccuracyItemDTO[]
): ISearchAccuracyItem[] =>
	items.map((item) => serializeSearchAccuracyItem(item));

export const deserializeSearchAccuracyUpdateItem = (
	item: ISearchAccuracyUpdate
): ISearchAccuracyUpdateDTO => ({ is_accurate: item.isAccurate });

export const deserializeSearchAccuracyBulkItem = (
	item: ISearchAccuracyBulkUpdate
): ISearchAccuracyBulkUpdateDTO => ({
	id: item.id,
	is_accurate: item.isAccurate,
});

export const deserializeSearchAccuracyBulkItems = (
	items: ISearchAccuracyBulkUpdate[]
): ISearchAccuracyBulkUpdateDTO[] =>
	items.map((item) => deserializeSearchAccuracyBulkItem(item));
