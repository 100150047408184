// libraries imports
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	createSearchParams,
	useNavigate,
	useLocation,
	useSearchParams,
} from 'react-router-dom';

// mui imports
import { Box, CircularProgress, Typography } from '@mui/material';

// project imports
import {
	INavigatorAxis,
	INavigatorHierarchy,
	INavigatorMetric,
	INavigatorModel,
	INavigatorReference,
	INavigatorTable,
} from '../../../../models/dataSummary.model';
import { TRequestStatus } from '../../../../models';
import NavigatorSearch from './NavigatorSearch/NavigatorSearch';
import NavigatorTreeView from './NavigatorTreeView/NavigatorTreeView';
import {
	fetchAxes,
	fetchDataModels,
	fetchHierarchies,
	fetchMetrics,
	fetchReferences,
	fetchTables,
	searchDataSummary,
} from '../../../../api/dataSummary.api';

// types
interface IProps {
	showList: boolean;
}

// =========================|| NAVIGATOR ||===================================//
const Navigator: React.FC<IProps> = ({ showList }) => {
	// states
	const [modelsFetchStatus, setModelsFetchStatus] =
		useState<TRequestStatus>('idle');
	const [tablesFetchStatus, setTablesFetchStatus] =
		useState<TRequestStatus>('idle');
	const [axesFetchStatus, setAxesFetchStatus] =
		useState<TRequestStatus>('idle');
	const [metricsFetchStatus, setMetricsFetchStatus] =
		useState<TRequestStatus>('idle');
	const [referencesFetchStatus, setReferencesFetchStatus] =
		useState<TRequestStatus>('idle');
	const [hierarchiesFetchStatus, setHierarchiesFetchStatus] =
		useState<TRequestStatus>('idle');
	const [searchStatus, setSearchStatus] = useState<TRequestStatus>('idle');

	const [models, setModels] = useState<INavigatorModel[]>([]);
	const [tables, setTables] = useState<INavigatorTable[]>([]);
	const [axes, setAxes] = useState<INavigatorAxis[]>([]);
	const [metrics, setMetrics] = useState<INavigatorMetric[]>([]);
	const [references, setReferences] = useState<INavigatorReference[]>([]);
	const [hierarchies, setHierarchies] = useState<INavigatorHierarchy[]>([]);

	const [searchTerm, setSearchTerm] = useState<string | null>(null);

	// hooks and variable
	const location = useLocation();
	const navigate = useNavigate();
	const [queryParams] = useSearchParams();

	const modelId = useMemo(() => {
		return location.pathname.split('/')[3];
	}, [location]);

	// event handlers
	const onSearch = useCallback(
		(text: string | null) => {
			navigate(
				{
					pathname: location.pathname,
					search: text ? `?${createSearchParams({ search: text })}` : '',
				},
				{ replace: true }
			);
		},
		[navigate, location]
	);

	// use effects
	useEffect(() => {
		if (!modelId && models.length) {
			navigate(`/dataGovernance/summary/models/${models[0].id}/diagram`, {
				replace: true,
			});
		}
	}, [modelId, models, navigate]);

	useEffect(() => {
		const searchText = queryParams.get('search');
		setSearchTerm(searchText);
	}, [queryParams]);

	useEffect(() => {
		if (searchTerm) {
			setSearchStatus('pending');
			setModelsFetchStatus('idle');
			setTablesFetchStatus('idle');
			setAxesFetchStatus('idle');
			setMetricsFetchStatus('idle');
			setReferencesFetchStatus('idle');
			setHierarchiesFetchStatus('idle');

			searchDataSummary(searchTerm)
				.then((searchResult) => {
					setModels(searchResult.models);
					setAxes(searchResult.axes);
					setMetrics(searchResult.metrics);
					setTables(searchResult.tables);
					setReferences(searchResult.references);
					setHierarchies(searchResult.hierarchies);
					setSearchStatus('fulfilled');
				})
				.catch(() => {
					setSearchStatus('rejected');
				});
		} else {
			setModelsFetchStatus('pending');
			setTablesFetchStatus('pending');
			setMetricsFetchStatus('pending');
			setAxesFetchStatus('pending');
			setReferencesFetchStatus('pending');
			setHierarchiesFetchStatus('pending');
			setSearchStatus('idle');

			fetchDataModels()
				.then((modelItems) => {
					setModels(modelItems);
					setModelsFetchStatus('fulfilled');
				})
				.catch(() => {
					setModelsFetchStatus('rejected');
				});

			fetchTables()
				.then((tableItems) => {
					setTables(tableItems);
					setTablesFetchStatus('fulfilled');
				})
				.catch(() => {
					setTablesFetchStatus('rejected');
				});

			fetchMetrics()
				.then((metricItems) => {
					setMetrics(metricItems);
					setMetricsFetchStatus('fulfilled');
				})
				.catch(() => {
					setMetricsFetchStatus('rejected');
				});

			fetchAxes()
				.then((axisItems) => {
					setAxes(axisItems);
					setAxesFetchStatus('fulfilled');
				})
				.catch(() => {
					setAxesFetchStatus('rejected');
				});

			fetchReferences()
				.then((referenceItems) => {
					setReferences(referenceItems);
					setReferencesFetchStatus('fulfilled');
				})
				.catch(() => {
					setReferencesFetchStatus('rejected');
				});

			fetchHierarchies()
				.then((hierarchyItems) => {
					setHierarchies(hierarchyItems);
					setHierarchiesFetchStatus('fulfilled');
				})
				.catch(() => {
					setHierarchiesFetchStatus('rejected');
				});
		}
	}, [searchTerm]);

	if (!showList) {
		return null;
	}

	return (
		<Box component="div" sx={{ width: '100%' }}>
			<NavigatorSearch onSubmit={onSearch} currentSearchTerm={searchTerm} />

			{(searchTerm
				? searchStatus === 'fulfilled'
				: searchStatus === 'fulfilled' ||
				  modelsFetchStatus === 'fulfilled') && (
				<NavigatorTreeView
					models={models}
					tables={tables}
					axes={axes}
					metrics={metrics}
					references={references}
					hierarchies={hierarchies}
					tablesFetchStatus={tablesFetchStatus}
					axesFetchStatus={axesFetchStatus}
					metricsFetchStatus={metricsFetchStatus}
					referencesFetchStatus={referencesFetchStatus}
					hierarchiesFetchStatus={hierarchiesFetchStatus}
					searchTerm={searchTerm}
				/>
			)}

			{searchStatus === 'rejected' && (
				<Typography variant="caption">
					Failed to fetch search results.
				</Typography>
			)}

			{searchStatus === 'pending' || modelsFetchStatus === 'pending' ? (
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'middle',
						justifyContent: 'center',
					}}
				>
					<Box>
						<CircularProgress sx={{ mt: 2 }} size={30} />
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default Navigator;
