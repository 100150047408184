import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import CircularSpinner from '../CircularSpinner/CircularSpinner';
import PageFallback from '../SuspenseErrorFallbacks/PageFallback';

const LazyLoader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<ErrorBoundary FallbackComponent={PageFallback}>
			<Suspense fallback={<CircularSpinner />}>{children}</Suspense>
		</ErrorBoundary>
	);
};

export default LazyLoader;
