import {
	IUsageLogsResultDTO,
	IUsageLogsStagesDTO,
} from '../models/dto/usageLogs.dto.model';
import { IUsageLogsResult, IUsageLogsStages } from '../models/usageLogs.model';

export const serializeUsageLogsStage = (
	item: IUsageLogsStagesDTO
): IUsageLogsStages => {
	return {
		stageName: item.stage_name,
		isSuccessful: item.is_successful,
		exceptionName: item.exception_name,
		exceptionArgs: item.exception_args,
		duration: item.time_taken,
		output: item.context_message,
	};
};

export const serializeUsageLogsStages = (
	items: IUsageLogsStagesDTO[]
): IUsageLogsStages[] => {
	return items.map((item) => serializeUsageLogsStage(item));
};

export const serializeUsageLogsItem = (
	item: IUsageLogsResultDTO
): IUsageLogsResult => {
	return {
		userId: item.user_id,
		searchQuery: item.search_query,
		startedAt: item.started_at,
		completedAt: item.completed_at,
		stages: serializeUsageLogsStages(item.stages),
	};
};

export const serializeUsageLogsItems = (
	items: IUsageLogsResultDTO[]
): IUsageLogsResult[] => {
	return items.map((item) => serializeUsageLogsItem(item));
};
