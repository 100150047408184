import { IReleaseNotes } from '../models/releaseNotes.model';
import axiosInstance from './axios/axios';

export const fetchReleaseNotesApi = async () => {
	const responseData = await axiosInstance
		.get<IReleaseNotes[]>(`/static/changelog.json`)
		.then((response) => {
			return response.data;
		});

	return responseData;
};
