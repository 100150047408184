import { TRequestStatus } from './misc.model';

export interface ICustomerConfigDetails {
	id: number;
	authAllowedDomains: string[];
	ctTaggerAddress: string;
	qtTaggerAddress: string;
	customerLogo?: File | string | null;
	emailHost: string;
	emailPort: number;
	emailUseTLS: boolean;
	queryCacheTimeout: number;
}

export interface ICustomerSecretsDetails {
	id: number;
	emailHostPassword: string;
	emailHostUser: string;

	isFlowzEnabled: boolean;
	flowzClientId?: string | null;
	flowzClientSecret?: string | null;

	isGoogleEnabled: boolean;
	googleOauth2Key?: string | null;
	googleOauth2Secret?: string | null;

	isMicrosoftSSOEnabled: boolean;
	microsoftSSOKey?: string | null;
	microsoftSSOSecret?: string | null;
}

export interface IAppConfigurationsStatuses {
	fetchCustomerConfigStatus: TRequestStatus;
	fetchCustomerSecretStatus: TRequestStatus;
	updateCustomerConfigStatus: TRequestStatus;
	updateCustomerSecretStatus: TRequestStatus;
}
export interface IAppConfigurations {
	customerConfigDetails: ICustomerConfigDetails | null;
	customerSecretDetails: ICustomerSecretsDetails | null;
}

export enum ETableImageSize {
	SMALL = 48,
	MEDIUM = 64,
	LARGE = 96,
}

export interface IAdminPreferences {
	primaryColor: string;
	secondaryColor: string;
	axisKeywordsLimit: number;
	numberSystem: string;
	numberFormatDecimals: number;
	queryTimeout: number; // in seconds
	dataLimit: number;
	growthInSimpleCation: boolean;
	tableImageSize: ETableImageSize;
	keywordRefreshTime: number; // in minutes
}
export interface IAdminPreferencesStatuses {
	fetchAdminPreferencesStatus: TRequestStatus;
	updateAdminPreferencesStatus: TRequestStatus;
	resetAdminPreferencesStatus: TRequestStatus;
}

export interface IUserPreferencesStatuses {
	fetchUserPreferencesStatus: TRequestStatus;
	updateUserPreferencesStatus: TRequestStatus;
	resetUserPreferencesStatus: TRequestStatus;
}

export interface IUserPreferences {
	numberSystem: string;
	numberFormatDecimals: number;
	tableImageSize: ETableImageSize;
}

export type TAppConfigurationsForm = Omit<
	ICustomerConfigDetails & ICustomerSecretsDetails,
	'customerLogo' | 'id'
>;

export interface TAppConfigurationsFormWithFile extends TAppConfigurationsForm {
	customerLogo?: File | string | null;
}
