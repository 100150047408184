// libraries imports
import React from 'react';

// mui imports
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	SvgIconProps,
	Tooltip,
	Typography,
} from '@mui/material';
import { TreeItem, TreeItemProps } from '@mui/lab';

// types
interface IProps extends TreeItemProps {
	labelText: React.ReactNode;
	labelIcon: React.ElementType<SvgIconProps>;
	labelInfo?: React.ReactNode;
	dataTestId?: string;
}

// =================||  METRIC STORE NAVIGATOR TREE ITEM ||===================//
const MetricStoreNavigatorTreeItem: React.FC<IProps> = ({
	labelIcon: LabelIcon,
	labelText,
	labelInfo,
	dataTestId,
	...treeItemProps
}) => {
	// render elements
	const label = (
		<List disablePadding>
			<ListItem dense sx={{ pl: 0 }}>
				<ListItemIcon
					sx={{
						minWidth: 'unset !important',
						marginRight: 1,
					}}
				>
					<LabelIcon sx={{ height: '0.95rem' }} />
				</ListItemIcon>

				<ListItemText
					disableTypography
					sx={{
						display: 'flex',
					}}
				>
					<Tooltip title={<Typography>{labelText}</Typography>}>
						<Typography
							data-testid={dataTestId}
							sx={{
								flexGrow: 1,
								textOverflow: 'ellipsis',
								overflowX: 'hidden',
								whiteSpace: 'nowrap',
							}}
						>
							{labelText}
						</Typography>
					</Tooltip>
					<Typography sx={{ mr: 1 }}>{labelInfo}</Typography>
				</ListItemText>
			</ListItem>
		</List>
	);

	return <TreeItem label={label} {...treeItemProps} />;
};

// default props
MetricStoreNavigatorTreeItem.defaultProps = { labelInfo: null, dataTestId: '' };

export default React.memo(MetricStoreNavigatorTreeItem);
