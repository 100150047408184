import { IDataSourceDetails } from '../models/dataSource.model';
import { IDataSourceDetailsDTO } from '../models/dto/dataSource.dto.model';

export const serializeDataSourceDetail = (
	items: IDataSourceDetailsDTO
): IDataSourceDetails => ({
	id: items.id,
	sourceName: items.source_name,
	attribute: items.attribute,
	lastUpdated: items.last_updated_on,
	relatedTables: items.related_tables,
	sourceUrl:
		items.source_url.slice(0, 5) === 'https' ||
		items.source_url.slice(0, 4) === 'http'
			? items.source_url
			: `https://${items.source_url}`,
	sourceLogo: items.source_logo,
	logoUrl: items.logo_url,
	showSourceName: items.show_source_name,
});

export const serializeDataSourceDetails = (
	items: IDataSourceDetailsDTO[]
): IDataSourceDetails[] => items.map((item) => serializeDataSourceDetail(item));
