// libraries imports
import React, { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// mui imports
import { Box, CircularProgress } from '@mui/material';

// project imports
import { selectLoginStatus } from '../../store/selectors';
import { useAppSelector } from '../../store/hooks';

// ==========================|| AUTH GUARD ||=================================//
const AuthGuard: React.FC = () => {
	// redux
	const { isLoggedIn, loginCheckStatus } = useAppSelector(selectLoginStatus);

	// hooks
	const shouldAllowNavigation = useMemo(() => {
		return !(
			(loginCheckStatus === 'fulfilled' && !isLoggedIn) ||
			loginCheckStatus === 'rejected'
		);
	}, [isLoggedIn, loginCheckStatus]);

	return (
		<>
			{shouldAllowNavigation ? <Outlet /> : <Navigate to="/login" />}
			{loginCheckStatus === 'pending' && (
				<Box sx={{ height: '100%', position: 'relative' }}>
					<CircularProgress
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
						}}
						color="primary"
					/>
				</Box>
			)}
		</>
	);
};

export default AuthGuard;
