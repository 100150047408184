import { Action, isAnyOf } from '@reduxjs/toolkit';
import { combineEpics, Epic } from 'redux-observable';
import {
	catchError,
	filter,
	from,
	map,
	mergeMap,
	of,
	startWith,
	switchMap,
	tap,
} from 'rxjs';
import { getAllItemsApi, getDataApi } from '../../api/ddive/charts.api';
import { scheduleReportApi } from '../../api/reportActions/schedule.api';
import { shareDashboardApi } from '../../api/reportActions/share.api';
import {
	dashboardSubCallApi,
	fetchWorkspaceDashboardDetailsApi,
	getDashboardTaskIdsApi,
	updateWorkspaceDashboardChartDetailsApi,
	updateWorkspaceDashboardDetailsApi,
} from '../../api/workspaceDashboard.api';
import { snackbarService } from '../../components/snackbar/snackbar.service';
import { IChartsData } from '../../models';
import { EAsyncTask } from '../../models/asyncTask.model';
import {
	IAsyncTaskDashboardSubCallDTO,
	IAsyncTaskDTO,
} from '../../models/dto/asyncTask.dto.model';
import { IAppState } from '../../models/store';
import { getErrorMessage } from '../../utils/functions/errorMessages';
import {
	fetchDashboardAllItemsData,
	fetchDashboardFiltersSubCall,
	fetchDashboardGetData,
	scheduleDashboard,
	shareDashboard,
	updateWorkspaceDashboardChartDetails,
	updateWorkspaceDashboardDetails,
} from '../actions/workspaceDashboard.actions';
import { asyncTaskActions } from '../reducers/asyncTask.reducer';
import { workspaceDashboardAction } from '../reducers/workspaceDashboard.reducer';

const fetchWorkspaceDashboardDetailsEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const { fetchWorkspaceDashboardDetails } = workspaceDashboardAction;

	return action$.pipe(
		filter(isAnyOf(fetchWorkspaceDashboardDetails.fetch)),
		switchMap((action) => {
			return from(fetchWorkspaceDashboardDetailsApi(action.payload)).pipe(
				map((response) => {
					return fetchWorkspaceDashboardDetails.fulfilled(response);
				}),
				catchError((err) => {
					const message = getErrorMessage(err, 'Failed to fetch dashboard');
					snackbarService.showErrorToast(message);
					return of(fetchWorkspaceDashboardDetails.rejected());
				}),
				startWith(fetchWorkspaceDashboardDetails.pending())
			);
		})
	);
};

const fetchDashboardTaskIdsEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const { fetchDashboardTaskIds } = workspaceDashboardAction;
	const isFetchPublicDashboard = isAnyOf(fetchDashboardTaskIds.fetch);

	return action$.pipe(
		filter(isFetchPublicDashboard),
		mergeMap((items) => {
			const api = items.payload.taskId
				? getDashboardTaskIdsApi({
						dashboardId: items.payload.dashboardId,
						taskId: items.payload.taskId,
				  })
				: getDashboardTaskIdsApi({
						dashboardId: items.payload.dashboardId,
						filters: items.payload?.filters,
						secret: items.payload?.secret,
						isPublic: items.payload?.isPublic,
				  });

			return from(api).pipe(
				mergeMap((data) => {
					if (items.payload.taskId) {
						return of(fetchDashboardTaskIds.fulfilled());
					}
					const result = data as IAsyncTaskDTO;

					return [
						asyncTaskActions.poll({
							name: EAsyncTask.GET_DASHBOARD_TASK_IDS,
							id: result.task_id || '',
							dashboardId: items.payload.dashboardId,
							isPublic: items.payload?.isPublic, // public
						}),
					];
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to fetch chart data.'
					);
					snackbarService.showErrorToast(message);
					return of(fetchDashboardTaskIds.rejected());
				}),
				startWith(fetchDashboardTaskIds.pending())
			);
		})
	);
};

const fetchDashboardGetDataEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isGetDataEpicAction = isAnyOf(fetchDashboardGetData.fetch);

	return action$.pipe(
		filter(isGetDataEpicAction),
		mergeMap((items) => {
			const api = items.payload.taskId
				? getDataApi({
						taskId: items.payload.taskId,
						isPublic: items.payload?.isPublic,
				  })
				: getDataApi({ chartId: items.payload.id });

			return from(api).pipe(
				mergeMap((data) => {
					if (items.payload.taskId) {
						const response = data as IChartsData;
						if (response.truncated) {
							snackbarService.showWarningToast(
								'Data is truncated. Please download excel to view full data.'
							);
						}

						return of(
							fetchDashboardGetData.fulfilled({
								data: response,
								chartId: items.payload.chartId || -1,
							})
						);
					}
					const result = data as IAsyncTaskDTO;
					return [
						asyncTaskActions.poll({
							name: EAsyncTask.DASHBOARD_GET_DATA,
							id: result.task_id || '',
							chartId: items.payload.chartId,
						}),
					];
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to fetch chart data.'
					);
					snackbarService.showErrorToast(message);
					return of(
						fetchDashboardGetData.rejected(items.payload.chartId || -1)
					);
				}),
				startWith(fetchDashboardGetData.pending(items.payload.chartId || -1))
			);
		})
	);
};

const fetchDashboardAllItemsEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isAllItemsEpicAction = isAnyOf(fetchDashboardAllItemsData.fetch);

	return action$.pipe(
		filter(isAllItemsEpicAction),
		switchMap(() =>
			from(getAllItemsApi()).pipe(
				map((response) => fetchDashboardAllItemsData.fulfilled(response)),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to fetch chart data.'
					);
					snackbarService.showErrorToast(message);
					return of(fetchDashboardAllItemsData.rejected());
				}),
				startWith(fetchDashboardAllItemsData.pending())
			)
		)
	);
};

const updateWorkspaceDashboardDetailsEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isUpdateAction = isAnyOf(updateWorkspaceDashboardDetails.update);

	return action$.pipe(
		filter(isUpdateAction),
		switchMap((action) => {
			return from(updateWorkspaceDashboardDetailsApi(action.payload)).pipe(
				map((response) => updateWorkspaceDashboardDetails.fulfilled(response)),
				tap(() => {
					if (action.payload.dashboardName) {
						snackbarService.showSuccessToast(
							'Dashboard name has been updated.'
						);
					} else if (action.payload.isSystem) {
						snackbarService.showSuccessToast(
							'Dashboard has been set as published.'
						);
					} else if (action.payload.isGranularReport) {
						snackbarService.showSuccessToast(
							'Dashboard has been set as granular.'
						);
					} else {
						snackbarService.showSuccessToast('Dashboard has been updated.');
					}
				}),
				catchError((err) => {
					if (action.payload.dashboardName) {
						snackbarService.showErrorToast(
							'Something went wrong, Failed to update dashboard name.'
						);
					} else if (action.payload.isSystem) {
						snackbarService.showErrorToast(
							'Something went wrong, Failed to set dashboard as published.'
						);
					} else if (action.payload.isGranularReport) {
						snackbarService.showErrorToast(
							'Something went wrong, Failed to set dashboard as granular.'
						);
					} else {
						const message = getErrorMessage(
							err,
							'Something went wrong, Failed to update dashboard.'
						);
						snackbarService.showErrorToast(message);
					}
					return of(updateWorkspaceDashboardDetails.rejected());
				}),
				startWith(updateWorkspaceDashboardDetails.pending())
			);
		})
	);
};

const updateWorkspaceDashboardChartDetailsEpic: Epic<
	Action,
	Action,
	IAppState
> = (action$) => {
	const isUpdateAction = isAnyOf(updateWorkspaceDashboardChartDetails.update);

	return action$.pipe(
		filter(isUpdateAction),
		switchMap(({ payload }) => {
			return from(
				updateWorkspaceDashboardChartDetailsApi(
					payload.data,
					payload.dashboardId
				)
			).pipe(
				map((response) => {
					snackbarService.showSuccessToast('Dashboard has been updated.');
					return updateWorkspaceDashboardChartDetails.fulfilled({
						data: response,
						isSaveAsNew: payload.isSaveAsNew,
					});
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to update dashboard.'
					);
					snackbarService.showErrorToast(message);
					return of(updateWorkspaceDashboardChartDetails.rejected());
				}),
				startWith(updateWorkspaceDashboardChartDetails.pending())
			);
		})
	);
};

const scheduleDashboardEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isScheduleAction = isAnyOf(scheduleDashboard.schedule);

	return action$.pipe(
		filter(isScheduleAction),
		switchMap((data) =>
			from(
				scheduleReportApi(
					data.payload.dashboardId,
					'dashboard',
					data.payload.data
				)
			).pipe(
				map((response) => {
					snackbarService.showSuccessToast('Dashboard has been scheduled.');
					return scheduleDashboard.fulfilled(response);
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to schedule dashboard.'
					);
					snackbarService.showErrorToast(message);
					return of(scheduleDashboard.rejected());
				}),
				startWith(scheduleDashboard.pending())
			)
		)
	);
};

const shareDashboardEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isShareAction = isAnyOf(shareDashboard.share);

	return action$.pipe(
		filter(isShareAction),
		switchMap((data) =>
			from(
				shareDashboardApi(data.payload.dashboardId, data.payload.userDetails)
			).pipe(
				map((response) => {
					snackbarService.showSuccessToast('Dashboard has been shared.');
					return shareDashboard.fulfilled(response);
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to fetch share dashboard.'
					);
					snackbarService.showErrorToast(message);
					return of(shareDashboard.rejected());
				}),
				startWith(shareDashboard.pending())
			)
		)
	);
};

const dashboardFiltersSubCallEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isGetDataEpicAction = isAnyOf(fetchDashboardFiltersSubCall.fetch);

	return action$.pipe(
		filter(isGetDataEpicAction),
		mergeMap((items) => {
			const api = items.payload.filters
				? dashboardSubCallApi(
						items.payload.filters,
						items.payload.dashboardId,
						'',
						-1
				  )
				: dashboardSubCallApi(
						null,
						-1,
						items.payload.taskId,
						items.payload.chartId
				  );

			return from(api).pipe(
				mergeMap((data) => {
					if (items.payload.taskId) {
						const response = data as { data: IChartsData; chartId: number };
						return of(fetchDashboardFiltersSubCall.fulfilled(response));
					}
					const result = data as IAsyncTaskDashboardSubCallDTO;

					return Object.entries(result.task_ids).map((item) => {
						return asyncTaskActions.poll({
							name: EAsyncTask.DASHBOARD_SUBCALL_GET_DATA,
							id: item[1],
							chartId: +item[0],
						});
					});
				}),
				catchError((err) => {
					const message = getErrorMessage(
						err,
						'Something went wrong, Failed to fetch dashboard chart data.'
					);
					snackbarService.showErrorToast(message);

					return of(
						fetchDashboardFiltersSubCall.rejected(items.payload.chartId || -1)
					);
				}),
				startWith(fetchDashboardFiltersSubCall.pending(!!items.payload.filters))
			);
		})
	);
};

export const workspaceDashboardEpic = combineEpics(
	fetchWorkspaceDashboardDetailsEpic,
	fetchDashboardGetDataEpic,
	fetchDashboardAllItemsEpic,
	dashboardFiltersSubCallEpic,
	updateWorkspaceDashboardDetailsEpic,
	updateWorkspaceDashboardChartDetailsEpic,
	scheduleDashboardEpic,
	shareDashboardEpic,
	fetchDashboardTaskIdsEpic
);
