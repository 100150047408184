import { Action, isAnyOf } from '@reduxjs/toolkit';
import { combineEpics, Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import {
	catchError,
	filter,
	map,
	mergeMap,
	startWith,
	tap,
} from 'rxjs/operators';

import { IAppState } from '../../models/store';
import {
	bulkUpdateSearchAccuracy,
	fetchSearchAccuracyData,
	updateSearchAccuracyItem,
} from '../../api/searchAccuracy.api';
import { searchAccuracyActions } from '../reducers/searchAccuracy.reducer';
import { snackbarService } from '../../components/snackbar/snackbar.service';

const searchAccuracyFetchEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isFetchAction = isAnyOf(searchAccuracyActions.fetchItems.fetch);

	return action$.pipe(
		filter(isFetchAction),
		mergeMap(() => {
			return from(fetchSearchAccuracyData()).pipe(
				map((items) => searchAccuracyActions.fetchItems.fulfilled(items)),
				catchError(() => of(searchAccuracyActions.fetchItems.rejected())),
				startWith(searchAccuracyActions.fetchItems.pending())
			);
		})
	);
};

const searchAccuracyUpdateEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isUpdateAction = isAnyOf(searchAccuracyActions.updateItem.update);

	return action$.pipe(
		filter(isUpdateAction),
		mergeMap((action) => {
			const { id, data } = action.payload;

			return from(updateSearchAccuracyItem(id, data)).pipe(
				map((updatedItem) =>
					searchAccuracyActions.updateItem.fulfilled(updatedItem)
				),
				tap((updateAction) => {
					const updatedItem = updateAction.payload;
					snackbarService.showSuccessToast(
						`${updatedItem.item.name} marked ${
							updatedItem.isAccurate ? 'accurate' : 'inaccurate'
						}.`
					);
				}),
				catchError(() => of(searchAccuracyActions.updateItem.rejected(id))),
				startWith(searchAccuracyActions.updateItem.pending(id))
			);
		})
	);
};

const searchAccuracyBulkUpdateEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isBulkUpdateAction = isAnyOf(searchAccuracyActions.bulkUpdate.update);

	return action$.pipe(
		filter(isBulkUpdateAction),
		mergeMap((action) => {
			const items = action.payload;
			const itemIds = items.map((item) => item.id);
			return from(bulkUpdateSearchAccuracy(items)).pipe(
				map((updatedItems) =>
					searchAccuracyActions.bulkUpdate.fulfilled(updatedItems)
				),
				tap((updateAction) => {
					const updatedItems = updateAction.payload;
					let message = `${updatedItems.length} items updated.`;

					if (updatedItems.length) {
						const { isAccurate } = updatedItems[0];
						message = `${updatedItems.length} items marked ${
							isAccurate ? 'accurate' : 'inaccurate'
						}.`;
					}

					snackbarService.showSuccessToast(message);
				}),
				catchError(() =>
					of(searchAccuracyActions.bulkUpdate.rejected(itemIds))
				),
				startWith(searchAccuracyActions.bulkUpdate.pending(itemIds))
			);
		})
	);
};

export const searchAccuracyEpic = combineEpics(
	searchAccuracyFetchEpic,
	searchAccuracyUpdateEpic,
	searchAccuracyBulkUpdateEpic
);
