// libraries imports
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// mui imports
import { TreeItem, TreeView } from '@mui/lab';
import DnsIcon from '@mui/icons-material/Dns';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// project imports
import MetricStoreNavigatorTreeItem from './MetricStoreNavigatorTreeItem/MetricStoreNavigatorTreeItem';
import {
	INavigatorModel,
	INavigatorTable,
} from '../../../../../models/dataSummary.model';
import { TRequestStatus } from '../../../../../models';

// types
interface IProps {
	models: INavigatorModel[];
	tables: INavigatorTable[];
	searchTerm?: string | null;
	tablesFetchStatus: TRequestStatus;
}

// ======================|| NAVIGATOR TREE VIEW ||============================//
const NavigatorTreeView: React.FC<IProps> = ({
	models,
	tables,
	tablesFetchStatus,
	searchTerm,
}) => {
	// states
	const [expandedNodeIds, setExpandedNodeIds] = useState<string[]>([]);
	const [selectedNode, setSelectedNode] = useState<string>('');

	// hooks
	const navigate = useNavigate();
	const location = useLocation();

	// refs
	const isFirstRenderRef = useRef<boolean>(true);

	// event handlers
	const onClickMSModelNode = useCallback(
		(modelId: number) => {
			navigate({
				pathname: `/dataGovernance/metricStore/models/${modelId}/metrics`,
				search: searchTerm ? `?search=${searchTerm}` : '',
			});
			setSelectedNode(`model_${modelId}`);
		},
		[navigate, searchTerm]
	);

	const onClickMSTableNode = useCallback(
		(tableId: number, modelId: number) => {
			navigate({
				pathname: `/dataGovernance/metricStore/models/${modelId}/tables/${tableId}/metrics`,
				search: searchTerm ? `?search=${searchTerm}` : '',
			});

			setSelectedNode(`table_${tableId}`);
		},
		[navigate, searchTerm]
	);

	const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
		setExpandedNodeIds(nodeIds);
	};

	// use effect
	useEffect(() => {
		if (searchTerm) {
			const nodeIds = [`model_${models?.[0]?.id}`];
			models.forEach((model) => {
				nodeIds.push(`model_${model.id}`);
				nodeIds.push(`tablesFolder_${model.id}`);
			});

			setExpandedNodeIds(nodeIds);
		}
	}, [models, searchTerm, tables]);

	useEffect(() => {
		const expandedIds: string[] = [];

		if (tables?.length) {
			expandedIds.push(`table_${tables[0].id}`);
		}
		if (models?.length) {
			expandedIds.push(`model_${models?.[0]?.id}`);
		}
		setExpandedNodeIds(expandedIds);
	}, [models, tables]);

	useEffect(() => {
		if (isFirstRenderRef.current) {
			setSelectedNode(
				location.pathname.split('/')[6]
					? `table_${location.pathname.split('/')[6]}`
					: `model_${models?.[0]?.id}`
			);
			isFirstRenderRef.current = false;
		}
	}, [location.pathname, models]);

	return (
		<TreeView
			defaultCollapseIcon={<ArrowDropDownIcon />}
			defaultExpandIcon={<ArrowRightIcon />}
			expanded={expandedNodeIds}
			selected={selectedNode}
			onNodeToggle={handleToggle}
			data-testid="navigatorTreeRoot"
		>
			{models.map((model) => (
				<MetricStoreNavigatorTreeItem
					key={`model_${model.id}`}
					classes={{
						content: 'metricStoreTreeOuter',
					}}
					dataTestId="metricStoreTreeOuter"
					nodeId={`model_${model.id}`}
					labelIcon={DnsIcon}
					labelText={model.customerName || model.name}
					onClick={() => onClickMSModelNode(model.id)}
				>
					{tablesFetchStatus === 'pending' && (
						<TreeItem
							nodeId={`fetchingTables_${model.id}`}
							label="Fetching tables..."
						/>
					)}
					{(tablesFetchStatus === 'fulfilled' || searchTerm) &&
						!!tables.length &&
						tables
							.filter((table) => table.modelId === model.id)
							.map((table) => (
								<MetricStoreNavigatorTreeItem
									classes={{
										content: 'metricStoreTreeInner',
									}}
									dataTestId="metricStoreTreeInner"
									key={`table_${table.id}`}
									nodeId={`table_${table.id}`}
									labelIcon={BackupTableOutlinedIcon}
									labelText={table.alias || table.name}
									onClick={() => onClickMSTableNode(table.id, model.id)}
								/>
							))}
				</MetricStoreNavigatorTreeItem>
			))}
		</TreeView>
	);
};

// default props
NavigatorTreeView.defaultProps = { searchTerm: null };

export default NavigatorTreeView;
