import { createAction } from '@reduxjs/toolkit';
import { IDataRefreshItems } from '../../models';

const SLICE_NAME = 'dataRefresh';

export const fetchDataRefreshItems = {
	fetch: createAction(`${SLICE_NAME}/fetch`),
	pending: createAction(`${SLICE_NAME}/pending`),
	rejected: createAction(`${SLICE_NAME}/rejected`),
	fulfilled: createAction<IDataRefreshItems[]>(`${SLICE_NAME}/fulfilled`),
};
