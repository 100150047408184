import { createAction } from '@reduxjs/toolkit';
import {
	IChartsData,
	IDisambiguationItems,
	ITimeSeriesModel,
	IPreviousOrNextStatePayload,
	ISubCallPayload,
	IOptionsPayload,
	IPinnedChartData,
	IScheduleChartPayload,
	IShareDiveChartPayload,
	IAllItems,
	IFiltersPayload,
} from '../../models';
import { IReportSchedule } from '../../models/schedule.model';

export const searchQuery = {
	search:
		createAction<{ value: string; taskId?: string }>(`searchQuery/search`),
	pending: createAction(`searchQuery/search/pending`),
	rejected: createAction(`searchQuery/search/rejected`),
	fulfilled: createAction<IDisambiguationItems | IChartsData>(
		`searchQuery/search/fulfilled`
	),
};

export const selectedOption = {
	fetch:
		createAction<{ items: IOptionsPayload | null; taskId?: string }>(
			`selectOption/fetch`
		),
	pending: createAction(`selectOption/fetch/pending`),
	rejected: createAction(`selectOption/fetch/rejected`),
	fulfilled: createAction<IChartsData>(`selectOption/fetch/fulfilled`),
};

export const subCall = {
	update: createAction<{
		items: ISubCallPayload | null;
		taskId?: string;
	}>('subCall/update'),
	pending: createAction('subCall/update/pending'),
	rejected: createAction('subCall/update/rejected'),
	fulfilled: createAction<IChartsData>(`subCall/update/fulfilled`),
};

export const previousOrNextState = {
	fetch: createAction<{
		items: IPreviousOrNextStatePayload | null;
		taskId?: string;
	}>(`previousOrNextState/fetch`),
	pending: createAction(`previousOrNextState/fetch/pending`),
	rejected: createAction(`previousOrNextState/fetch/rejected`),
	fulfilled: createAction<IDisambiguationItems | IChartsData>(
		`previousOrNextState/fetch/fulfilled`
	),
};

export const timeSeriesModel = {
	fetch: createAction(`timeSeriesModel/fetch`),
	pending: createAction(`timeSeriesModel/fetch/pending`),
	rejected: createAction(`timeSeriesModel/fetch/rejected`),
	fulfilled: createAction<ITimeSeriesModel>(`timeSeriesModel/fetch/fulfilled`),
};

export const getData = {
	fetch: createAction<{
		id: number;
		taskId?: string;
		chartId?: number;
		filters?: IFiltersPayload[];
		secret?: any;
		isPublic?: boolean;
	}>(`getData/fetch`),
	pending: createAction<number>(`getData/fetch/pending`),
	rejected: createAction<number>(`getData/fetch/rejected`),
	fulfilled: createAction<{ data: IChartsData; chartId: number }>(
		`getData/fetch/fulfilled`
	),
};
export const getPinnedChartData = {
	fetch: createAction<number>(`pinnedChartData/fetch`),
	pending: createAction(`pinnedChartData/fetch/pending`),
	rejected: createAction(`pinnedChartData/fetch/rejected`),
	fulfilled: createAction<IPinnedChartData>(`pinnedChartData/fetch/fulfilled`),
};

export const getChartData = {
	fetch: createAction<{ id: number; taskId?: string }>(`chartData/fetch`),
	pending: createAction(`chartData/fetch/pending`),
	rejected: createAction(`chartData/fetch/rejected`),
	fulfilled: createAction<IChartsData>(`chartData/fetch/fulfilled`),
};

export const getAllItems = {
	fetch: createAction(`allItems/fetch`),
	pending: createAction(`allItems/fetch/pending`),
	rejected: createAction(`allItems/fetch/rejected`),
	fulfilled: createAction<IAllItems>(`allItems/fetch/fulfilled`),
};

export const scheduleChart = {
	schedule: createAction<IScheduleChartPayload>('scheduleChart/schedule'),
	pending: createAction('scheduleChart/schedule/pending'),
	rejected: createAction('scheduleChart/schedule/rejected'),
	fulfilled: createAction<IReportSchedule>('scheduleChart/schedule/fulfilled'),
};

export const shareChart = {
	share: createAction<IShareDiveChartPayload>('shareChart/share'),
	pending: createAction('shareChart/share/pending'),
	rejected: createAction('shareChart/share/rejected'),
	fulfilled: createAction<IPinnedChartData>('shareChart/share/fulfilled'),
};
