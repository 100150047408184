import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Layout } from 'react-grid-layout';
import {
	IAxisItems,
	IChartsData,
	IDashboardNewChart,
	ISelectedOption,
	IMetricItems,
	IPinnedChartData,
	ISelectedDashboardFilters,
	ITimeFilters,
} from '../../models';
import {
	IWorkspaceDashboardSettings,
	IWorkspaceDashboardState,
} from '../../models/store/workspaceDashboard.store.model';
import { ILayouts } from '../../routes/Dashboards/WorkspaceDashboard/WorkspaceDashboard';
import {
	fetchDashboardAllItemsData,
	fetchDashboardFiltersSubCall,
	fetchDashboardGetData,
	fetchWorkspaceDashboardDetails,
	fetchDashboardTaskIds,
	scheduleDashboard,
	shareDashboard,
	updateWorkspaceDashboardChartDetails,
	updateWorkspaceDashboardDetails,
} from '../actions/workspaceDashboard.actions';

const INITIAL_STATE: IWorkspaceDashboardState = {
	fetchDetailsStatus: 'idle',
	fetchAllItemsStatus: 'idle',
	updateDashboardDetailsStatus: 'idle',
	updateDashboardChartDetailsStatus: 'idle',
	scheduleStatus: 'idle',
	fetchDashboardTaskIdsStatus: 'idle',
	shareStatus: 'idle',
	fetchChartDataStatus: {},
	dashboardDetails: null,
	pinnedChartsData: {},
	dashboardChartsData: {},
	granularSelectedFilter: null,
	dashboardSelectedFilters: [],
	dashboardSelectedTimeFilters: [],
	relatedItems: {
		axes: [],
		filters: [],
	},
	updatedCharts: {},
	updatedPinnedCharts: {},
	newCharts: {},
	deletedChartsIds: [],
	isChangeAvailable: false,
	isFiltersChanged: false,
	isChangesDiscarded: false,
	layouts: {
		currentLayout: [],
		allLayouts: {},
	},
	dashboardSettings: {
		showQuickFilters: true,
	},
};

const workspaceDashboardSlice = createSlice({
	name: 'workspaceDashboard',
	initialState: INITIAL_STATE,
	reducers: {
		resetDashboardData: (state) => {
			state.dashboardDetails = null;
			state.dashboardChartsData = {};
			state.pinnedChartsData = {};
			state.updatedCharts = {};
			state.updatedPinnedCharts = {};
			state.newCharts = {};
			state.deletedChartsIds = [];
			state.fetchDashboardTaskIdsStatus = 'idle';
			state.fetchChartDataStatus = {};
			state.fetchDetailsStatus = 'idle';
			state.layouts = { allLayouts: {}, currentLayout: [] };
		},
		reorderAxes: (
			state,
			action: PayloadAction<{ axes: string[]; chartId: number }>
		) => {
			const { axes, chartId } = action.payload;
			const orderedAxes: IAxisItems[] = [];
			axes.forEach((item) => {
				const axis = state.dashboardChartsData[chartId].selectedAxes.filter(
					(axisItem) => axisItem.metaId === item
				);
				if (axis?.length) {
					orderedAxes.push(axis[0]);
				}
			});
			if (Object.keys(state.dashboardChartsData)?.length) {
				state.dashboardChartsData[chartId].selectedAxes = orderedAxes;
			}
		},
		reorderMetrics: (
			state,
			action: PayloadAction<{ metrics: string[]; chartId: number }>
		) => {
			const { metrics, chartId } = action.payload;
			const reorderedMetrics: IMetricItems[] = [];
			metrics.forEach((item) => {
				const metric = state.dashboardChartsData[
					chartId
				].selectedMetrics.filter(
					(metricItem) => `${metricItem.metaId}_${metricItem.name}` === item
				);
				if (metric?.length) {
					reorderedMetrics.push(metric[0]);
				}
			});
			if (Object.keys(state.dashboardChartsData)?.length) {
				state.dashboardChartsData[chartId].selectedMetrics = reorderedMetrics;
			}
		},
		updateChart: (
			state,
			action: PayloadAction<{
				chartsData: IChartsData;
				isLayoutChanged?: boolean;
				id: number;
			}>
		) => {
			const { chartsData, id, isLayoutChanged = true } = action.payload;
			state.updatedCharts[id] = chartsData;
			state.isChangeAvailable = isLayoutChanged;
		},
		updatePinnedChart: (
			state,
			action: PayloadAction<{
				chartData: IPinnedChartData;
				isLayoutChanged?: boolean;
			}>
		) => {
			const { chartData, isLayoutChanged = true } = action.payload;
			state.updatedPinnedCharts[chartData.id] = chartData;
			state.isChangeAvailable = isLayoutChanged;
		},
		resetUpdatedCharts: (state) => {
			state.updatedCharts = {};
		},
		resetUpdatedPinnedChart: (state) => {
			state.updatedPinnedCharts = {};
		},
		addNewChart: (
			state,
			action: PayloadAction<{
				payload: IDashboardNewChart;
				isLayoutChanged?: boolean;
			}>
		) => {
			const { payload, isLayoutChanged = true } = action.payload;
			state.newCharts[payload.data.dagId] = payload;
			state.isChangeAvailable = isLayoutChanged;
		},
		deleteNewChart: (state, action: PayloadAction<number>) => {
			const dagId = action.payload;
			const newChartsCopy = { ...state.newCharts };
			if (newChartsCopy[dagId]) {
				delete newChartsCopy[dagId];
			}
			state.newCharts = newChartsCopy;
		},
		resetNewCharts: (state) => {
			state.newCharts = {};
		},
		deleteChart: (state, action: PayloadAction<number>) => {
			const chartId = action.payload;
			if (!state.deletedChartsIds.includes(chartId)) {
				state.deletedChartsIds.push(chartId);
			}
			state.isChangeAvailable = true;
		},
		resetDeletedCharts: (state) => {
			state.deletedChartsIds = [];
		},
		resetUpdateDashboardChartDetailsStatus: (state) => {
			state.updateDashboardChartDetailsStatus = 'idle';
		},
		updateIsChangeAvailable: (state, action: PayloadAction<boolean>) => {
			const value = action.payload;
			state.isChangeAvailable = value;
		},
		updateIsFiltersChanged: (state, action: PayloadAction<boolean>) => {
			const value = action.payload;
			state.isFiltersChanged = value;
		},
		updateGranularSelectedFilter: (
			state,
			action: PayloadAction<ISelectedDashboardFilters | null>
		) => {
			state.granularSelectedFilter = action.payload;
		},
		updateDashboardSelectedFilters: (
			state,
			action: PayloadAction<ISelectedOption[]>
		) => {
			state.dashboardSelectedFilters = action.payload;
		},
		updateDashboardSelectedTimeFilters: (
			state,
			action: PayloadAction<ITimeFilters[]>
		) => {
			state.dashboardSelectedTimeFilters = action.payload;
		},
		updateIsChangesDiscarded: (state, action: PayloadAction<boolean>) => {
			const value = action.payload;
			state.isChangesDiscarded = value;
		},

		updateCurrentLayout: (state, action: PayloadAction<Layout[]>) => {
			state.layouts = {
				...state.layouts,
				currentLayout: action.payload,
			};
		},
		updateAllLayout: (state, action: PayloadAction<ILayouts>) => {
			state.layouts = {
				...state.layouts,
				allLayouts: action.payload,
			};
		},
		updateDashboardSettings: (
			state,
			action: PayloadAction<{
				field: keyof IWorkspaceDashboardSettings;
				value: boolean;
			}>
		) => {
			const { field, value } = action.payload;
			state.dashboardSettings[field] = value;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDashboardTaskIds.pending, (state) => {
				state.fetchDashboardTaskIdsStatus = 'pending';
			})
			.addCase(fetchDashboardTaskIds.rejected, (state) => {
				state.fetchDashboardTaskIdsStatus = 'rejected';
			})
			.addCase(fetchDashboardTaskIds.fulfilled, (state) => {
				state.fetchDashboardTaskIdsStatus = 'fulfilled';
			})
			.addCase(fetchWorkspaceDashboardDetails.pending, (state) => {
				state.fetchDetailsStatus = 'pending';
			})
			.addCase(fetchWorkspaceDashboardDetails.fulfilled, (state, actions) => {
				const { dashboardDetails, pinnedChartsData } = actions.payload;
				state.dashboardDetails = dashboardDetails;

				// sort data based on position
				const sortedData = pinnedChartsData.sort((item) => item.position);

				sortedData.forEach((item) => {
					state.pinnedChartsData[item.id] = item;
				});
				state.fetchDetailsStatus = 'fulfilled';
			})
			.addCase(fetchWorkspaceDashboardDetails.rejected, (state) => {
				state.fetchDetailsStatus = 'rejected';
			})
			.addCase(fetchDashboardGetData.pending, (state, actions) => {
				const chartId = actions.payload;
				if (chartId && chartId !== -1) {
					state.fetchChartDataStatus[chartId] = 'pending';
				}
			})
			.addCase(fetchDashboardGetData.fulfilled, (state, actions) => {
				const { data, chartId } = actions.payload;
				const chartsData = { ...state.dashboardChartsData };

				if (chartId && chartId !== -1) {
					chartsData[chartId] = data;
					state.dashboardChartsData = chartsData;
					state.fetchChartDataStatus[chartId] = 'fulfilled';
				}
			})
			.addCase(fetchDashboardGetData.rejected, (state, actions) => {
				const chartId = actions.payload;
				if (chartId && chartId !== -1) {
					state.fetchChartDataStatus[chartId] = 'rejected';
				}
			})
			.addCase(fetchDashboardAllItemsData.pending, (state) => {
				state.fetchAllItemsStatus = 'pending';
			})
			.addCase(fetchDashboardAllItemsData.fulfilled, (state, actions) => {
				const data = actions.payload;
				state.relatedItems = {
					axes: data.axes,
					filters: data.filters,
				};
				state.fetchAllItemsStatus = 'fulfilled';
			})
			.addCase(fetchDashboardAllItemsData.rejected, (state) => {
				state.fetchAllItemsStatus = 'rejected';
			})
			.addCase(updateWorkspaceDashboardDetails.pending, (state) => {
				state.updateDashboardDetailsStatus = 'pending';
			})
			.addCase(updateWorkspaceDashboardDetails.fulfilled, (state, action) => {
				const { dashboardDetails } = action.payload;
				state.dashboardDetails = dashboardDetails;
				state.isChangeAvailable = false;

				state.updateDashboardDetailsStatus = 'fulfilled';
			})
			.addCase(updateWorkspaceDashboardDetails.rejected, (state) => {
				state.updateDashboardDetailsStatus = 'rejected';
			})
			.addCase(updateWorkspaceDashboardChartDetails.pending, (state) => {
				state.updateDashboardChartDetailsStatus = 'pending';
			})
			.addCase(
				updateWorkspaceDashboardChartDetails.fulfilled,
				(state, action) => {
					const { data, isSaveAsNew } = action.payload;

					if (isSaveAsNew) {
						state.updateDashboardChartDetailsStatus = 'fulfilled';
					} else {
						const newPinnedChartsDetails: {
							[chartId: number]: IPinnedChartData;
						} = {};

						data
							.filter((item) => item.isActive)
							.forEach((ele) => {
								newPinnedChartsDetails[ele.id] = ele;
							});

						// create map for response to update dashboardChartsData
						const dataMap: { [id: string]: IPinnedChartData } = {};
						data.forEach((item) => {
							dataMap[item.origChartData] = item;
						});

						//  updating dashboardChartsData based on newly added chart
						Object.entries(state.newCharts).forEach(([dagId, chart]) => {
							state.dashboardChartsData[dataMap[dagId].id] = chart.data;
						});

						state.pinnedChartsData = newPinnedChartsDetails;
						state.newCharts = {};
						state.deletedChartsIds = [];
						state.isChangeAvailable = false;
						state.layouts = {
							...state.layouts,
							allLayouts: {},
						};
						state.updateDashboardChartDetailsStatus = 'fulfilled';
					}
				}
			)
			.addCase(updateWorkspaceDashboardChartDetails.rejected, (state) => {
				state.updateDashboardChartDetailsStatus = 'rejected';
			})
			.addCase(scheduleDashboard.pending, (state) => {
				state.scheduleStatus = 'pending';
			})
			.addCase(scheduleDashboard.fulfilled, (state, action) => {
				const data = action.payload;
				if (state.dashboardDetails) {
					state.dashboardDetails.schedule = data;
				}
				state.scheduleStatus = 'fulfilled';
			})
			.addCase(scheduleDashboard.rejected, (state) => {
				state.scheduleStatus = 'rejected';
			})
			.addCase(shareDashboard.pending, (state) => {
				state.shareStatus = 'pending';
			})
			.addCase(shareDashboard.fulfilled, (state, action) => {
				const { dashboardDetails } = action.payload;
				if (state.dashboardDetails) {
					state.dashboardDetails.sharedWithUsers =
						dashboardDetails.sharedWithUsers;
				}
				state.shareStatus = 'fulfilled';
			})
			.addCase(shareDashboard.rejected, (state) => {
				state.shareStatus = 'rejected';
			})
			.addCase(fetchDashboardFiltersSubCall.pending, (state, action) => {
				if (action.payload) {
					Object.values(state.pinnedChartsData).forEach((item) => {
						state.fetchChartDataStatus[item.id] = 'pending';
					});
				}
			})
			.addCase(fetchDashboardFiltersSubCall.fulfilled, (state, action) => {
				const { data, chartId } = action.payload;
				state.dashboardChartsData[chartId] = data;
				state.fetchChartDataStatus[chartId] = 'fulfilled';
			})
			.addCase(fetchDashboardFiltersSubCall.rejected, (state, action) => {
				const chartId = action.payload;
				state.fetchChartDataStatus[chartId] = 'rejected';
				state.granularSelectedFilter = null;
				state.dashboardSelectedFilters = [];
			});
	},
});

export const workspaceDashboardAction = {
	...workspaceDashboardSlice.actions,
	fetchWorkspaceDashboardDetails,
	fetchDashboardGetData,
	fetchDashboardAllItemsData,
	fetchDashboardFiltersSubCall,
	fetchDashboardTaskIds,
	updateWorkspaceDashboardDetails,
	updateWorkspaceDashboardChartDetails,
	scheduleDashboard,
	shareDashboard,
};

export default workspaceDashboardSlice.reducer;
