import { IMetricItemDTO, IMetricResponseItemDTO } from '../models/dto';
import { IMetricValidationData, IMetricPayloadItem } from '../models';

export const serializeMetricValidationItem = (
	item: IMetricItemDTO
): IMetricValidationData => ({
	id: item.id,
	lastId: item.last_id,
	metricName: item.measure_name,
	table: item.table,
	liveDataStatus: item.live_data_status,
	historicalDataStatus: item.hist_data_status,
	assignedTo: item.assigned_to,
	updatedOn: item.updated_on,
	eta: item.eta,
	comments: item.comments,
	dashboard: item.dashboard,
	itemType: item.item_type,
	columnId: item.column_detail ? item.column_detail.id : item.column_detail,
	aliases: item.aliases,
	isUpdating: false,
});

export const serializeMetricValidationItems = (
	items: IMetricItemDTO[]
): IMetricValidationData[] =>
	items.map((item) => serializeMetricValidationItem(item));

// Deserializers
export const deserializeMetricValidationUpdateItem = (
	item: IMetricPayloadItem
): IMetricResponseItemDTO => ({
	id: item.id,
	...(item.colId && {
		column: item.colId,
	}),

	item_type: item.itemType,
	...(item.liveDataStatus && { live_data_status: item.liveDataStatus }),
	...(item.historicalDataStatus && {
		hist_data_status: item.historicalDataStatus,
	}),
	...(item.assignedTo && {
		assigned_to: JSON.parse(item.assignedTo),
	}),
	...(item.comments && {
		comments:
			item.comments === 'null' ? JSON.parse(item.comments) : item.comments,
	}),
	...(item.dashboard && {
		dashboard: JSON.parse(item.dashboard),
	}),

	...(item.eta && {
		eta: item.eta,
	}),
});

export const deserializeMetricValidationUpdateItems = (
	items: IMetricPayloadItem[]
): IMetricResponseItemDTO[] =>
	items.map((item) => deserializeMetricValidationUpdateItem(item));
