export type TRequestStatus = 'idle' | 'pending' | 'rejected' | 'fulfilled';

export type TChangeType = 'add' | 'remove' | 'change';
export type TSortOrder = 'asc' | 'desc';
export type TTopBottomType = 'top' | 'bottom';
export type TChartType =
	| 'kpi'
	| 'line'
	| 'bar'
	| 'stack'
	| 'sarea'
	| 'donut'
	| 'sunburst'
	| 'funnel'
	| 'scatter'
	| 'bubble'
	| 'comboChart'
	| 'table'
	| 'flatTable'
	| 'treeMap';

export enum ETableType {
	DIMENSIONAL = 'dim',
	TRANSACTIONAL = 'fact',
	CALENDAR = 'calendar',
}

export enum EWeekDay {
	SUN = 'Sunday',
	MON = 'Monday',
	TUE = 'Tuesday',
	WED = 'Wednesday',
	THU = 'Thursday',
	FRI = 'Friday',
	SAT = 'Saturday',
}

export enum EMonth {
	JAN = 1,
	FEB = 2,
	MAR = 3,
	APR = 4,
	MAY = 5,
	JUN = 6,
	JUL = 7,
	AUG = 8,
	SEP = 9,
	OCT = 10,
	NOV = 11,
	DEC = 12,
}

export enum ENumberSystem {
	INDIAN = 'indian',
	INTERNATIONAL = 'interNational',
}
