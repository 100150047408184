import { IUserDetails } from '../models';
import { IWorkspaceDashboardDTO } from '../models/dto';
import {
	deserializeShareReportData,
	serializeWorkspaceDashboard,
	serializeWorkspaceDashboards,
} from '../serializers';
import axiosInstance from './axios/axios';

export const shareDashboard = async (
	dashboardId: number,
	userDetails: IUserDetails[]
) => {
	const data = deserializeShareReportData({ userDetails });
	const dashboardDto = await axiosInstance
		.post<IWorkspaceDashboardDTO>(
			`ddive/api/dashboard/${dashboardId}/share/`,
			data
		)
		.then((response) => response.data);

	const dashboard = serializeWorkspaceDashboard(dashboardDto);

	return dashboard;
};

export const deleteDashboard = async (dashboardId: number) => {
	const dashboardDto = await axiosInstance
		.patch<IWorkspaceDashboardDTO>(`ddive/api/dashboard/${dashboardId}/`, {
			is_active: false,
		})
		.then((response) => response.data);

	const deletedDashboard = serializeWorkspaceDashboard(dashboardDto);

	return deletedDashboard;
};

export const getAllDashboardsApi = async () => {
	const dashboardDto = await axiosInstance
		.get<IWorkspaceDashboardDTO[]>(`ddive/api/dashboard/`)
		.then((response) => response.data);

	const dashboards = serializeWorkspaceDashboards(dashboardDto);

	return dashboards;
};
