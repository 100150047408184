import { createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../../models/store';

export const selectAuthState = (state: IAppState) => state.auth;

export const selectLoginStatus = createSelector(
	selectAuthState,
	(authState) => {
		return {
			isLoggedIn: authState.isLoggedIn,
			loginCheckStatus: authState.loginCheckStatus,
		};
	}
);

export const selectFirstName = createSelector(selectAuthState, (authState) => {
	const { firstName, username } = authState;
	return firstName || username;
});

export const selectNavbarEssentials = createSelector(
	selectAuthState,
	({ firstName, lastName, username, email, appVersion }) => {
		let fullName = firstName;
		fullName += lastName ? ` ${lastName}` : '';

		const userName = fullName || username;

		return {
			userName,
			email,
			appVersion,
		};
	}
);

export const selectUserRole = createSelector(selectAuthState, (authState) => ({
	role: authState.role,
}));

export const selectUserId = createSelector(
	selectAuthState,
	(authState) => authState.id
);

export const selectUserEmail = createSelector(
	selectAuthState,
	(authState) => authState.email
);
