import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAuthState } from '../models';
import { autoLogin, logout } from '../actions/auth.actions';
import { ICookies } from '../../models/auth.model';

const INITIAL_STATE: IAuthState = {
	loginCheckStatus: 'idle',
	logoutStatus: 'idle',
	isLoggedIn: false,
	appVersion: '',
	email: '',
	username: '',
	firstName: '',
	lastName: '',
	role: null,
	id: -1,
};

const authSlice = createSlice({
	name: 'auth',
	initialState: INITIAL_STATE,
	reducers: {
		cookiesRead: (state, action: PayloadAction<ICookies>) => {
			const { appVersion } = action.payload;
			state.appVersion = appVersion;
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(autoLogin.pending, (state) => {
				state.loginCheckStatus = 'pending';
			})
			.addCase(autoLogin.rejected, (state) => {
				state.loginCheckStatus = 'rejected';
				state.isLoggedIn = false;
			})
			.addCase(autoLogin.fulfilled, (state, action) => {
				const { email, firstName, lastName, role, username, id } =
					action.payload;

				state.loginCheckStatus = 'fulfilled';
				state.isLoggedIn = true;
				state.role = role;
				state.email = email;
				state.firstName = firstName;
				state.lastName = lastName;
				state.username = username;
				state.id = id;
			})
			.addCase(logout.pending, (state) => {
				state.logoutStatus = 'pending';
			})
			.addCase(logout.rejected, (state) => {
				state.logoutStatus = 'rejected';
			})
			.addCase(logout.fulfilled, (state) => {
				state.logoutStatus = 'fulfilled';
				state.isLoggedIn = false;
				state.email = '';
				state.firstName = '';
				state.lastName = '';
				state.username = '';
				state.role = null;
				state.id = -1;
			}),
});

const authReducer = authSlice.reducer;

export default authReducer;
export const authActions = { ...authSlice.actions, autoLogin, logout };
