import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EAsyncTaskStatus, IAsyncTask } from '../../models/asyncTask.model';
import { IAsyncTaskState } from '../../models/store/asyncTask.store.model';

const INITIAL_STATE: IAsyncTaskState = {
	tasks: {},
	completedTasks: {},
	pollingStatus: 'idle',
};

const asyncTask = createSlice({
	name: 'asyncTask',
	initialState: INITIAL_STATE,
	reducers: {
		poll: (state, action: PayloadAction<IAsyncTask>) => {
			const { id: taskId, name: taskName } = action.payload;
			if (state.tasks[taskName] !== taskId) {
				state.tasks[taskName] = taskId;
			}
		},
		stop: (state, action: PayloadAction<string>) => {
			state.pollingStatus = 'idle';
		},
		pending: (state, action: PayloadAction<string>) => {
			state.pollingStatus = 'pending';
		},
		rejected: (state, action: PayloadAction<string>) => {
			const taskId = action.payload;
			const taskMap = state.tasks;
			const taskName = Object.keys(taskMap).find(
				(task) => taskMap[task] === taskId
			);

			if (taskName) {
				delete taskMap[taskName];
			}

			if (!Object.keys(taskMap).length) {
				state.pollingStatus = 'rejected';
			}
		},
		fulfilled: (
			state,
			action: PayloadAction<{ task: IAsyncTask; status: EAsyncTaskStatus }>
		) => {
			const { task, status } = action.payload;
			const taskMap = state.tasks;
			const taskName = Object.keys(taskMap).find((t) => t === task.name);

			if (taskName) {
				delete taskMap[taskName];
				state.completedTasks[taskName] = { id: task.id, status };
			}

			if (!Object.keys(taskMap).length) {
				state.pollingStatus = 'fulfilled';
			}
		},
	},
});

export const asyncTaskActions = asyncTask.actions;
export default asyncTask.reducer;
