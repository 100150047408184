import {
	IUpdateChartDetailsPayload,
	IUpdateDashboardDetailsPayload,
	IWorkspaceAutoCompleteParams,
	IWorkspaceCreateDashboardPayload,
	IWorkspaceFetchOptions,
} from '../models';
import {
	IUserDTO,
	IWorkspaceChartDTO,
	IWorkspaceDashboardDTO,
	IWorkspaceFetchResultDTO,
} from '../models/dto';
import { serializeUsers } from '../serializers';
import {
	serializeWorkspaceChart,
	serializeWorkspaceDashboard,
	serializeWorkspaceFetchResult,
} from '../serializers/workspace.serializer';
import axiosInstance from './axios/axios';

export const getWorkspaceReports = async (options?: IWorkspaceFetchOptions) => {
	const searchParams = new URLSearchParams();

	if (options?.search) {
		searchParams.append('q', options.search);
	}

	if (options?.types) {
		options.types.forEach((type) => {
			searchParams.append('rtype', type);
		});
	}

	if (options?.statuses) {
		options.statuses.forEach((status) => {
			searchParams.append('status', status);
		});
	}

	const paramString = `?${searchParams.toString()}`;

	const response = await axiosInstance.get<IWorkspaceFetchResultDTO>(
		`/ddive/api/collections/${paramString}`
	);

	const result = serializeWorkspaceFetchResult(response.data);

	return result;
};

// TODO: Move this to a separate file.
export const getWorkspaceAutoCompleteUsers = async (
	options?: IWorkspaceAutoCompleteParams
) => {
	// TODO: Interim API only. Need to replace with actual autocomplete API.
	const response = await axiosInstance.get<IUserDTO[]>('/ddive/api/users/', {
		params: { ...options, share: 1 },
	});

	const result = serializeUsers(response.data);

	return result;
};

// TODO: Move this to dashboard api file
export const createWorkspaceDashboard = async (
	options: IWorkspaceCreateDashboardPayload
) => {
	const response = await axiosInstance.post<IWorkspaceDashboardDTO>(
		'/ddive/api/dashboard/',
		{ dashboard_name: options.dashboardName }
	);

	const result = serializeWorkspaceDashboard(response.data);

	return result;
};

export const updateChartDetailsApi = async (
	options: IUpdateChartDetailsPayload
) => {
	const response = await axiosInstance.patch<IWorkspaceChartDTO>(
		`/ddive/api/chart/${options.id}/`,
		{ chart_title: options.chartTitle, is_system: options.isPublished }
	);

	const result = serializeWorkspaceChart(response.data);

	return result;
};

export const updateDashboardDetailsApi = async (
	options: IUpdateDashboardDetailsPayload
) => {
	const response = await axiosInstance.patch<IWorkspaceDashboardDTO>(
		`/ddive/api/dashboard/${options.id}/`,
		{ dashboard_name: options.dashboardTitle, is_system: options.isPublished }
	);

	const result = serializeWorkspaceDashboard(response.data);

	return result;
};
