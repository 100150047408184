import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useLocation, useNavigate } from 'react-router-dom';

const BottomBar = () => {
	const [active, setActive] = useState('permissions');

	const location = useLocation();
	const navigate = useNavigate();

	const handleChange = (newValue: string) => {
		setActive(newValue);
		navigate(`${newValue}`);
	};

	useEffect(() => {
		const pathName = location.pathname.split('/')[2];
		setActive(pathName);
	}, [location.pathname]);

	return (
		<Paper
			sx={{
				position: 'fixed',
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: 'drawer',
			}}
			elevation={3}
		>
			<BottomNavigation
				showLabels
				value={active}
				onChange={(event, newValue) => {
					handleChange(newValue);
				}}
			>
				<BottomNavigationAction
					value="permissions"
					label="Permissions"
					icon={<VerifiedUserIcon />}
				/>
				<BottomNavigationAction
					value="groups"
					label="Groups"
					icon={<GroupIcon />}
				/>
				<BottomNavigationAction
					value="users"
					label="Users"
					icon={<PersonIcon />}
				/>
			</BottomNavigation>
		</Paper>
	);
};

export default BottomBar;
