import { createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../../models/store';

// APP SETTINGS
const selectAppSettingsState = (store: IAppState) => store.appSettingsState;
export const appSettingsSelector = createSelector(
	selectAppSettingsState,
	(state) => ({
		...state,
	})
);

// App Configurations  ----------------------------------------------------------
const selectAppConfigurationsState = (store: IAppState) =>
	store.appSettingsState.appConfigurations;
const selectAppConfigurationsStatusState = (store: IAppState) =>
	store.appSettingsState.appConfigurationsStatuses;

export const appConfigurationsSelector = createSelector(
	selectAppConfigurationsState,
	(state) => ({
		...state,
	})
);
export const appConfigurationsStatusSelector = createSelector(
	selectAppConfigurationsStatusState,
	(state) => ({
		...state,
	})
);

// USER PREFERENCE  -----------------------------------------------------------
const selectUserPreferencesState = (store: IAppState) =>
	store.appSettingsState.userPreferences;
const selectUserPreferencesStatusState = (store: IAppState) =>
	store.appSettingsState.userPreferencesStatuses;

export const userPreferencesSelector = createSelector(
	selectUserPreferencesState,
	(state) => ({
		...state,
	})
);

export const userPreferencesStatusSelector = createSelector(
	selectUserPreferencesStatusState,
	(state) => ({
		...state,
	})
);

// ADMIN PREFERENCE  -----------------------------------------------------------
const selectAdminPreferencesState = (store: IAppState) =>
	store.appSettingsState.adminPreferences;
const selectAdminPreferencesStatusState = (store: IAppState) =>
	store.appSettingsState.adminPreferencesStatuses;

export const adminPreferencesSelector = createSelector(
	selectAdminPreferencesState,
	(state) => ({
		...state,
	})
);

export const adminPreferencesStatusSelector = createSelector(
	selectAdminPreferencesStatusState,
	(state) => ({
		...state,
	})
);
