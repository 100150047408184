import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAxisItems,
	IChartsData,
	IContextFiltersPayload,
	IDisambiguationItems,
	IMetricItems,
	IPinnedChartData,
	TChartType,
} from '../../models';
import {
	IChartsSettings,
	IChartsState,
	IPauseModeState,
} from '../../models/store/charts.store.model';
import {
	getAllItems,
	getChartData,
	getData,
	getPinnedChartData,
	previousOrNextState,
	scheduleChart,
	searchQuery,
	selectedOption,
	shareChart,
	subCall,
	timeSeriesModel,
} from '../actions/charts.actions';

const INITIAL_STATE: IChartsState = {
	searchStatus: 'idle',
	subCallStatus: 'idle',
	previousOrNextStatus: 'idle',
	selectedOptionStatus: 'idle',
	timeSeriesModelStatus: 'idle',
	getDataStatus: 'idle',
	getPinnedChartDataStatus: 'idle',
	scheduleChartStatus: 'idle',
	shareChartStatus: 'idle',
	disambiguationItems: null,
	chartsData: null,
	pinnedChartData: null,
	responseType: '',
	timeSeriesModel: null,
	isPauseMode: false,
	pauseModeState: {},
	contextFiltersState: [],
	tableColumns: [],
	showAllBadges: false,
	isChangeAvailable: false,
	tableContextDetails: {},
	chartsSettings: {
		showDataPoints: {},
		showDetailedTooltip: {},
		connectNulls: {},
		showNullsAsZero: {},
		showGrowthInSimpleTooltip: {},
		showAggregationWithTitle: {},
		showXAxis: {},
		showYAxis: {},
		showLegends: {},
		showVerticalBarChart: {},
	},
	selectedFilter: 'All',
	rightYAxisMetrics: [],
};

const chartsSlice = createSlice({
	name: 'charts',
	initialState: INITIAL_STATE,
	reducers: {
		resetResponseType: (state) => {
			state.responseType = '';
		},
		resetPauseMode: (state) => {
			state.isPauseMode = false;
			state.pauseModeState = {};
		},
		resetChartsData: (state) => {
			state.chartsData = null;
		},
		resetPinnedChartsData: (state) => {
			state.pinnedChartData = null;
		},
		resetStatus: (state) => {
			state.subCallStatus = 'idle';
			state.previousOrNextStatus = 'idle';
		},
		resetTableColumns: (state) => {
			state.tableColumns = [];
		},
		resetSearchStatus: (state) => {
			state.searchStatus = 'idle';
		},
		resetSelectedOptionStatus: (state) => {
			state.selectedOptionStatus = 'idle';
		},
		reorderAxes: (
			state,
			action: PayloadAction<{ data: string[]; isFirstRender?: boolean }>
		) => {
			const { data, isFirstRender } = action.payload;
			const orderedAxes: IAxisItems[] = [];
			data.forEach((item) => {
				const axis = state.chartsData?.selectedAxes.filter(
					(axisItem) => axisItem.metaId === item
				);
				if (axis?.length) {
					orderedAxes.push(axis[0]);
				}
			});
			if (state.chartsData) {
				state.chartsData.selectedAxes = orderedAxes;
			}
			if (!isFirstRender) {
				state.isChangeAvailable = true;
			}
		},
		reorderMetrics: (
			state,
			action: PayloadAction<{ data: string[]; isFirstRender?: boolean }>
		) => {
			const { data, isFirstRender } = action.payload;
			const reorderedMetrics: IMetricItems[] = [];
			data.forEach((item) => {
				const metric = state.chartsData?.selectedMetrics.filter(
					(metricItem) => `${metricItem.metaId}_${metricItem.name}` === item
				);
				if (metric?.length) {
					reorderedMetrics.push(metric[0]);
				}
			});
			if (state.chartsData) {
				state.chartsData.selectedMetrics = reorderedMetrics;
			}
			if (!isFirstRender) {
				state.isChangeAvailable = true;
			}
		},
		togglePauseMode: (state) => {
			state.isPauseMode = !state.isPauseMode;
		},
		updateRightYAxisMetric: (
			state,
			action: PayloadAction<{ alias: string; type?: 'remove' }>
		) => {
			const { alias, type } = action.payload;

			let rightYAxisMetricsCopy = [...state.rightYAxisMetrics];

			if (!rightYAxisMetricsCopy.includes(alias) && type !== 'remove') {
				state.rightYAxisMetrics = [...rightYAxisMetricsCopy, alias];
			} else {
				rightYAxisMetricsCopy = rightYAxisMetricsCopy.filter(
					(item) => item !== alias
				);
				state.rightYAxisMetrics = [...rightYAxisMetricsCopy];
			}
		},
		resetRightYAxisMetric: (state) => {
			state.rightYAxisMetrics = [];
		},
		updatePauseModeState: (state, action: PayloadAction<IPauseModeState>) => {
			const data = action.payload;
			if (data.metrics) {
				state.pauseModeState.metrics = data.metrics;
			}
			if (data.axes) {
				state.pauseModeState.axes = data.axes;
			}
			if (data.filters) {
				state.pauseModeState.filters = data.filters;
			}
			if (data.timeFilters) {
				state.pauseModeState.timeFilters = data.timeFilters;
			}
			if (data.joinPaths) {
				state.pauseModeState.joinPaths = data.joinPaths;
			}
			if (data.sortingData) {
				state.pauseModeState.sortingData = data.sortingData;
			}
			if (data.topBottomFilters) {
				state.pauseModeState.topBottomFilters = data.topBottomFilters;
			}
		},
		updateContextFiltersState: (
			state,
			action: PayloadAction<IContextFiltersPayload[]>
		) => {
			state.contextFiltersState = action.payload;
			state.isChangeAvailable = true;
		},
		setTableColumns: (state, action: PayloadAction<string[]>) => {
			state.tableColumns = action.payload;
			state.isChangeAvailable = true;
		},
		toggleTotalAndSubTotal: (
			state,
			action: PayloadAction<{ type: 'total' | 'subtotal'; prevValue: boolean }>
		) => {
			const { type, prevValue } = action.payload;

			if (type === 'total') {
				state.tableContextDetails = {
					...state.tableContextDetails,
					showTotal: !prevValue,
				};
			} else {
				state.tableContextDetails = {
					...state.tableContextDetails,
					showSubTotal: !prevValue,
				};
			}

			state.isChangeAvailable = true;
		},
		showAllBadges: (state) => {
			state.showAllBadges = true;
		},
		showLessBadges: (state) => {
			state.showAllBadges = false;
		},
		updateChartData: (state, action: PayloadAction<IChartsData>) => {
			state.chartsData = action.payload;
		},
		updatePinnedChartData: (state, action: PayloadAction<IPinnedChartData>) => {
			const { payload } = action;
			state.pinnedChartData = payload;
			state.tableColumns = payload.axisOrderSet
				.filter((axis) => axis.isColumn)
				.map((item) => item.metaId);
		},
		updateSelectedFilter: (state, action: PayloadAction<string>) => {
			state.selectedFilter = action.payload;
		},
		updateChartsSettings: (
			state,
			action: PayloadAction<{
				chartType: TChartType;
				field: keyof IChartsSettings;
				prevValue: boolean;
			}>
		) => {
			const { chartType, field, prevValue } = action.payload;
			const chartSettingsCopy = { ...state.chartsSettings };

			chartSettingsCopy[field][chartType] = !prevValue;

			state.chartsSettings = { ...chartSettingsCopy };
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(searchQuery.pending, (state) => {
				state.searchStatus = 'pending';
			})
			.addCase(searchQuery.rejected, (state) => {
				state.searchStatus = 'rejected';
			})
			.addCase(searchQuery.fulfilled, (state, action) => {
				const data = action.payload;
				if (data.responseType === 'OptionsResponse') {
					state.chartsData = null;
					state.disambiguationItems = data as IDisambiguationItems;
				} else {
					state.disambiguationItems = null;
					state.chartsData = data as IChartsData;
				}
				state.tableColumns = [];

				state.tableContextDetails = {
					showSubTotal: true,
					showTotal: true,
				};
				state.chartsSettings = {
					showDataPoints: {},
					showDetailedTooltip: {},
					connectNulls: {},
					showNullsAsZero: {},
					showGrowthInSimpleTooltip: {},
					showAggregationWithTitle: {},
					showXAxis: {},
					showYAxis: {},
					showLegends: {},
					showVerticalBarChart: {},
				};

				state.responseType = data.responseType;
				state.searchStatus = 'fulfilled';
			})
			.addCase(selectedOption.pending, (state) => {
				state.selectedOptionStatus = 'pending';
			})
			.addCase(selectedOption.rejected, (state) => {
				state.selectedOptionStatus = 'rejected';
			})
			.addCase(selectedOption.fulfilled, (state, action) => {
				const data = action.payload;

				state.disambiguationItems = null;
				state.chartsData = data;

				state.responseType = data.responseType;
				state.selectedOptionStatus = 'fulfilled';
			})
			.addCase(subCall.pending, (state) => {
				state.subCallStatus = 'pending';
			})
			.addCase(subCall.rejected, (state) => {
				state.subCallStatus = 'rejected';
				if (state.isPauseMode) {
					state.isPauseMode = false;
					state.pauseModeState = {};
				}
				state.contextFiltersState = [];
			})
			.addCase(subCall.fulfilled, (state, action) => {
				const data = action.payload;

				// show update button on UI
				state.isChangeAvailable = true;
				state.disambiguationItems = null;
				state.chartsData = data;
				state.responseType = data.responseType;

				if (data.selectedAxes.length === 1) {
					state.tableColumns = [];
				} else {
					const selectedAxesMetaIds = data.selectedAxes.map(
						(item) => item.metaId
					);
					state.tableColumns = state.tableColumns.filter((item) =>
						selectedAxesMetaIds.includes(item)
					);
				}

				// update rightYAxisMetric
				let rightYAxisMetricsCopy = [...state.rightYAxisMetrics];
				const selectedMetricsAliases: string[] =
					state.chartsData?.selectedMetrics.map((item) => item.alias) || [];

				rightYAxisMetricsCopy = rightYAxisMetricsCopy.filter((item) =>
					selectedMetricsAliases.includes(item)
				);
				state.rightYAxisMetrics = [...rightYAxisMetricsCopy];

				state.subCallStatus = 'fulfilled';
				if (state.isPauseMode) {
					state.isPauseMode = false;
					state.pauseModeState = {};
				}
				state.contextFiltersState = [];
				state.showAllBadges = false;
			})
			.addCase(previousOrNextState.pending, (state) => {
				state.previousOrNextStatus = 'pending';
			})
			.addCase(previousOrNextState.rejected, (state) => {
				state.previousOrNextStatus = 'rejected';
			})
			.addCase(previousOrNextState.fulfilled, (state, action) => {
				const data = action.payload;

				if (data.responseType === 'OptionsResponse') {
					state.chartsData = null;
					state.disambiguationItems = data as IDisambiguationItems;
				} else {
					state.disambiguationItems = null;
					state.chartsData = data as IChartsData;
				}

				state.responseType = data.responseType;
				state.previousOrNextStatus = 'fulfilled';
			})
			.addCase(timeSeriesModel.pending, (state) => {
				state.timeSeriesModelStatus = 'pending';
			})
			.addCase(timeSeriesModel.rejected, (state) => {
				state.timeSeriesModelStatus = 'rejected';
			})
			.addCase(timeSeriesModel.fulfilled, (state, action) => {
				const data = action.payload;
				state.timeSeriesModel = data;
				state.timeSeriesModelStatus = 'fulfilled';
			})
			.addCase(getData.pending, (state) => {
				state.getDataStatus = 'pending';
			})
			.addCase(getData.rejected, (state) => {
				state.getDataStatus = 'rejected';
			})
			.addCase(getData.fulfilled, (state, action) => {
				const { data } = action.payload;
				state.chartsData = data;
				state.getDataStatus = 'fulfilled';
			})
			.addCase(getChartData.pending, (state) => {
				state.getDataStatus = 'pending';
			})
			.addCase(getChartData.rejected, (state) => {
				state.getDataStatus = 'rejected';
			})
			.addCase(getChartData.fulfilled, (state, action) => {
				const data = action.payload;
				state.chartsData = data;
				state.getDataStatus = 'fulfilled';
			})
			.addCase(getAllItems.pending, (state) => {
				state.getDataStatus = 'pending';
			})
			.addCase(getAllItems.rejected, (state) => {
				state.getDataStatus = 'rejected';
			})
			.addCase(getAllItems.fulfilled, (state, action) => {
				const data = action.payload;
				state.chartsData = {
					chartTitle: '',
					chartTypes: [],
					chartId: -1,
					correctedText: '',
					dagId: -1,
					responseType: '',
					searchText: '',
					stateChangeable: [],
					data: [],
					selectedAxes: [],
					selectedSeries: [],
					selectedFilters: [],
					selectedMetrics: [],
					drillDownAxes: [],
					subQueries: [],
					axes: data.axes,
					metrics: data.metrics,
					filters: data.filters,
					truncated: false,
				};
				state.getDataStatus = 'fulfilled';
			})
			.addCase(getPinnedChartData.pending, (state) => {
				state.getPinnedChartDataStatus = 'pending';
			})
			.addCase(getPinnedChartData.rejected, (state) => {
				state.getPinnedChartDataStatus = 'rejected';
			})
			.addCase(getPinnedChartData.fulfilled, (state, action) => {
				const data = action.payload;
				const columnAxes = data.axisOrderSet.filter((item) => item.isColumn);

				state.tableContextDetails = {
					showSubTotal: !data.subtotalFlag,
					showTotal: !data.totalFlag,
				};
				state.chartsSettings = {
					...state.chartsSettings,
					showDataPoints: { [data.chartType]: data.showDataPoints },
					showXAxis: { [data.chartType]: data.showXAxis as boolean },
					showYAxis: { [data.chartType]: data.showYAxis as boolean },
					showLegends: { [data.chartType]: data.showLegends as boolean },
				};

				state.pinnedChartData = data;
				state.tableColumns = columnAxes.map((item) => item.metaId);

				state.getPinnedChartDataStatus = 'fulfilled';
			})
			.addCase(scheduleChart.schedule, (state) => {
				state.scheduleChartStatus = 'pending';
			})
			.addCase(scheduleChart.rejected, (state) => {
				state.scheduleChartStatus = 'rejected';
			})
			.addCase(scheduleChart.fulfilled, (state, action) => {
				const data = action.payload;
				if (state.pinnedChartData) {
					state.pinnedChartData = { ...state.pinnedChartData, schedule: data };
				}

				state.scheduleChartStatus = 'fulfilled';
			})
			.addCase(shareChart.share, (state) => {
				state.shareChartStatus = 'pending';
			})
			.addCase(shareChart.rejected, (state) => {
				state.shareChartStatus = 'rejected';
			})
			.addCase(shareChart.fulfilled, (state, action) => {
				const data = action.payload;
				state.pinnedChartData = data;

				state.shareChartStatus = 'fulfilled';
			}),
});

export const chartsActions = {
	...chartsSlice.actions,
	searchQuery,
	selectedOption,
	subCall,
	getData,
	getChartData,
	getAllItems,
	getPinnedChartData,
	previousOrNextState,
	timeSeriesModel,
	scheduleChart,
	shareChart,
};

export default chartsSlice.reducer;
