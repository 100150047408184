import { Action, isAnyOf } from '@reduxjs/toolkit';
import { from, of } from 'rxjs';
import { combineEpics, Epic } from 'redux-observable';
import { catchError, filter, map, startWith, switchMap } from 'rxjs/operators';

import { fetchDataRefreshData } from '../../api/dataRefresh.api';
import { IAppState } from '../../models/store';
import { fetchDataRefreshItems } from '../actions/dataRefresh.actions';

const fetchDataRefreshEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isDatRefreshFetchAction = isAnyOf(fetchDataRefreshItems.fetch);

	return action$.pipe(
		filter(isDatRefreshFetchAction),
		switchMap(() =>
			from(fetchDataRefreshData()).pipe(
				map((data) => fetchDataRefreshItems.fulfilled(data)),
				catchError(() => {
					return of(fetchDataRefreshItems.rejected());
				}),
				startWith(fetchDataRefreshItems.pending())
			)
		)
	);
};

export const dataRefreshEpic = combineEpics(fetchDataRefreshEpic);
