// libraries imports
import React, { useEffect, useMemo, useState } from 'react';

// mui imports
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	CircularProgress,
	FormControl,
	InputLabel,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// project imports
import { IReleaseNotes } from '../../models/releaseNotes.model';
import { fetchReleaseNotesApi } from '../../api/releaseNotes.api';
import { TRequestStatus } from '../../models';

// types
interface IReleaseNotesMap {
	[version: string]: IReleaseNotes;
}

// =====================|| RELEASE NOTES ||===================================//
const ReleaseNotes = () => {
	// states
	const [releaseNotes, setReleaseNotes] = useState<IReleaseNotesMap>({});
	const [selectedVersion, setSelectedVersion] = useState<string>('');
	const [fetchStatus, setFetchStatus] = useState<TRequestStatus>('idle');

	// hooks and variable
	const selectedNotesDetails = useMemo(() => {
		return releaseNotes[selectedVersion];
	}, [releaseNotes, selectedVersion]);

	// event handlers
	const handleChangeVersion = (event: SelectChangeEvent) => {
		setSelectedVersion(event.target.value as string);
	};

	// use effects
	useEffect(() => {
		const fetchData = async () => {
			setFetchStatus('pending');
			try {
				const data = await fetchReleaseNotesApi();
				const dataMap: IReleaseNotesMap = {};

				data.forEach((item) => {
					dataMap[item.version] = item;
				});
				setReleaseNotes(dataMap);
				setSelectedVersion(data[0]?.version || '');
				setFetchStatus('fulfilled');
			} catch {
				setFetchStatus('rejected');
			}
		};

		fetchData();
	}, []);

	if (fetchStatus === 'pending') {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}
	if (fetchStatus === 'rejected') {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography sx={{ color: 'secondary.main' }}>
					Something went wrong. Failed to fetch Release Notes.
				</Typography>
			</Box>
		);
	}

	return (
		<Box component="section" sx={{ mt: 1 }}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Typography variant="h5">Release Notes</Typography>
				<FormControl sx={{ minWidth: 90 }}>
					<InputLabel id="releaseNotesVersion">Version</InputLabel>
					<Select
						labelId="releaseNotesVersionLabel"
						id="releaseNotesVersionSelect"
						value={selectedVersion}
						label="Version"
						size="small"
						onChange={handleChangeVersion}
					>
						{Object.values(releaseNotes).map((item) => (
							<MenuItem key={item.version} value={item.version}>
								{item.version}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			{selectedNotesDetails && (
				<>
					{/* Release Date  */}
					<Box sx={{ mt: 2 }}>
						<Typography variant="h6">Release Date</Typography>
						<Typography>{selectedNotesDetails.releaseDate}</Typography>
					</Box>

					{/* New Features  */}
					{!!selectedNotesDetails.newFeatures?.length && (
						<Box sx={{ mt: 2 }} id="newFeatures">
							<Accordion defaultExpanded>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="newFeaturesContent"
									id="newFeaturesHeader"
								>
									<Typography variant="h6">New Features</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<List>
										{selectedNotesDetails.newFeatures.map((item, index) => (
											<ListItemButton
												key={`${selectedNotesDetails.version}_${index}`}
												disableRipple
												disableTouchRipple
											>
												<ListItemIcon>
													<ArrowRightIcon />
												</ListItemIcon>
												<ListItemText primary={item} />
											</ListItemButton>
										))}
									</List>
								</AccordionDetails>
							</Accordion>
						</Box>
					)}

					{/* Bug Fixes  */}
					{!!selectedNotesDetails.bugFixes?.length && (
						<Box sx={{ mt: 2 }} id="bugFixes">
							<Accordion defaultExpanded>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="newFeaturesContent"
									id="newFeaturesHeader"
								>
									<Typography variant="h6">Bugs Fixed</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<List>
										{selectedNotesDetails.bugFixes.map((item, index) => (
											<ListItemButton
												key={`${selectedNotesDetails.version}_${index}`}
												disableRipple
												disableTouchRipple
											>
												<ListItemIcon>
													<ArrowRightIcon />
												</ListItemIcon>
												<ListItemText primary={item} />
											</ListItemButton>
										))}
									</List>
								</AccordionDetails>
							</Accordion>
						</Box>
					)}
				</>
			)}
		</Box>
	);
};

export default ReleaseNotes;
