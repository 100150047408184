// libraries imports
import React from 'react';
import { Link } from 'react-router-dom';

// mui imports
import { SvgIconComponent } from '@mui/icons-material';
import { Button, LinkProps } from '@mui/material';

// types
interface IProps extends LinkProps {
	path: string;
	icon: SvgIconComponent;
}

// ======================|| NAVBAR BUTTON WRAPPER ||==========================//
const NavbarButton = React.forwardRef<HTMLAnchorElement, IProps>(
	(props, ref) => {
		const { icon: Icon, path, children, ...linkProps } = props;
		const { sx } = linkProps;

		return (
			<Button
				{...linkProps}
				variant="text"
				data-testid={`${children}`}
				color="inherit"
				startIcon={<Icon />}
				component={Link}
				to={path}
				sx={{ mr: 2, whiteSpace: 'nowrap', ...sx, lineHeight: 'normal' }}
				ref={ref}
			>
				{children}
			</Button>
		);
	}
);

export default NavbarButton;
