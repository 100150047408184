import axiosInstance from './axios/axios';
import {
	ISearchAccuracyBulkUpdate,
	ISearchAccuracyItem,
	ISearchAccuracyUpdate,
} from '../models';
import { ISearchAccuracyItemDTO } from '../models/dto';
import {
	deserializeSearchAccuracyBulkItems,
	deserializeSearchAccuracyUpdateItem,
	serializeSearchAccuracyItem,
	serializeSearchAccuracyItems,
} from '../serializers';

export const fetchSearchAccuracyData = async (): Promise<
	ISearchAccuracyItem[]
> => {
	const response = await axiosInstance.get<ISearchAccuracyItemDTO[]>(
		'/configure/api/searchAccuracy/'
	);
	const searchAccuracyItems = serializeSearchAccuracyItems(response.data);
	return searchAccuracyItems;
};

export const updateSearchAccuracyItem = async (
	id: number,
	data: ISearchAccuracyUpdate
): Promise<ISearchAccuracyItem> => {
	const payload = deserializeSearchAccuracyUpdateItem(data);
	const response = await axiosInstance.patch<ISearchAccuracyItemDTO>(
		`/configure/api/searchAccuracy/${id}/`,
		payload
	);
	const updatedItem = serializeSearchAccuracyItem(response.data);
	return updatedItem;
};

export const bulkUpdateSearchAccuracy = async (
	data: ISearchAccuracyBulkUpdate[]
): Promise<ISearchAccuracyItem[]> => {
	const payload = deserializeSearchAccuracyBulkItems(data);
	const response = await axiosInstance.patch<ISearchAccuracyItemDTO[]>(
		'/configure/api/searchAccuracy/bulk_update/',
		payload
	);
	const updatedItems = serializeSearchAccuracyItems(response.data);
	return updatedItems;
};
