import { createAction } from '@reduxjs/toolkit';
import {
	IAdminPreferences,
	ICustomerConfigDetails,
	ICustomerSecretsDetails,
	IUserPreferences,
	TAppConfigurationsFormWithFile,
} from '../../models';

// APP CONFIGURATIONS ----------------------------------------------------------
export const fetchCustomerConfig = {
	fetch: createAction(`customerConfig/fetch`),
	pending: createAction(`customerConfig/fetch/pending`),
	rejected: createAction(`customerConfig/fetch/rejected`),
	fulfilled: createAction<ICustomerConfigDetails>(
		`customerConfig/fetch/fulfilled`
	),
};
export const fetchCustomerSecrets = {
	fetch: createAction(`customerSecrets/fetch`),
	pending: createAction(`customerSecrets/fetch/pending`),
	rejected: createAction(`customerSecrets/fetch/rejected`),
	fulfilled: createAction<ICustomerSecretsDetails>(
		`customerSecrets/fetch/fulfilled`
	),
};

export const updateCustomerConfig = {
	update: createAction<TAppConfigurationsFormWithFile>(`customerConfig/update`),
	pending: createAction(`customerConfig/update/pending`),
	rejected: createAction(`customerConfig/update/rejected`),
	fulfilled: createAction<ICustomerConfigDetails>(
		`customerConfig/update/fulfilled`
	),
};
export const updateCustomerSecrets = {
	update: createAction<TAppConfigurationsFormWithFile>(
		`customerSecrets/update`
	),
	pending: createAction(`customerSecrets/update/pending`),
	rejected: createAction(`customerSecrets/update/rejected`),
	fulfilled: createAction<ICustomerSecretsDetails>(
		`customerSecrets/update/fulfilled`
	),
};

// USER Preferences ------------------------------------------------------------
export const updateUserPreferences = {
	update: createAction<IUserPreferences>(`userPreferences/update`),
	pending: createAction(`userPreferences/update/pending`),
	rejected: createAction(`userPreferences/update/rejected`),
	fulfilled: createAction<IUserPreferences>(`userPreferences/update/fulfilled`),
};
export const fetchUserPreferences = {
	fetch: createAction<{ isPublicChart: boolean } | undefined>(
		`userPreferences/fetch`
	),
	pending: createAction(`userPreferences/fetch/pending`),
	rejected: createAction(`userPreferences/fetch/rejected`),
	fulfilled: createAction<IUserPreferences>(`userPreferences/fetch/fulfilled`),
};

export const resetUserPreferences = {
	reset: createAction(`userPreferences/reset`),
	pending: createAction(`userPreferences/reset/pending`),
	rejected: createAction(`userPreferences/reset/rejected`),
	fulfilled: createAction<IUserPreferences>(`userPreferences/reset/fulfilled`),
};

// ADMIN Preferences -----------------------------------------------------------
export const updateAdminPreferences = {
	update: createAction<IAdminPreferences>(`adminPreferences/update`),
	pending: createAction(`adminPreferences/update/pending`),
	rejected: createAction(`adminPreferences/update/rejected`),
	fulfilled: createAction<IAdminPreferences>(
		`adminPreferences/update/fulfilled`
	),
};
export const fetchAdminPreferences = {
	fetch: createAction<{ isPublicChart: boolean } | undefined>(
		`adminPreferences/fetch`
	),
	pending: createAction(`adminPreferences/fetch/pending`),
	rejected: createAction(`adminPreferences/fetch/rejected`),
	fulfilled: createAction<IAdminPreferences>(
		`adminPreferences/fetch/fulfilled`
	),
};

export const resetAdminPreferences = {
	reset: createAction(`adminPreferences/reset`),
	pending: createAction(`adminPreferences/reset/pending`),
	rejected: createAction(`adminPreferences/reset/rejected`),
	fulfilled: createAction<IAdminPreferences>(
		`adminPreferences/reset/fulfilled`
	),
};
