import React from 'react';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	SvgIconProps,
	Tooltip,
	Typography,
} from '@mui/material';
import { TreeItem, TreeItemProps } from '@mui/lab';

interface IProps extends TreeItemProps {
	labelText: React.ReactNode;
	labelIcon: React.ElementType<SvgIconProps>;
	labelInfo?: React.ReactNode;
}

const NavigatorTreeItem: React.FC<IProps> = (props) => {
	const {
		labelIcon: LabelIcon,
		labelText,
		labelInfo,
		...treeItemProps
	} = props;

	const label = (
		<List disablePadding>
			<ListItem dense sx={{ pl: 0 }}>
				<ListItemIcon
					sx={{
						minWidth: 'unset !important',
						marginRight: 1,
					}}
				>
					<LabelIcon sx={{ height: '0.95rem' }} />
				</ListItemIcon>

				<ListItemText
					disableTypography
					sx={{
						display: 'flex',
					}}
				>
					<Tooltip title={<Typography>{labelText}</Typography>}>
						<Typography
							sx={{
								flexGrow: 1,
								textOverflow: 'ellipsis',
								overflowX: 'hidden',
								whiteSpace: 'nowrap',
							}}
						>
							{labelText}
						</Typography>
					</Tooltip>
					<Typography sx={{ mr: 1 }}>{labelInfo}</Typography>
				</ListItemText>
			</ListItem>
		</List>
	);

	return <TreeItem label={label} {...treeItemProps} />;
};

NavigatorTreeItem.defaultProps = { labelInfo: null };

export default React.memo(NavigatorTreeItem);
