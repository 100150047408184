// libraries imports
import React from 'react';
import { useNavigate } from 'react-router-dom';

// mui imports
import { Box, Button, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

// =======================|| NOT FOUND PAGE ||================================//
const NotFound = () => {
	// hooks
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography variant="h1">404</Typography>
			<Typography variant="h5">Page not found</Typography>
			<Button
				onClick={() => {
					navigate('/');
				}}
				sx={{ mt: 2, fontWeight: 'fontWeightBold' }}
				variant="contained"
				startIcon={<HomeIcon />}
			>
				Go To HomePage
			</Button>
		</Box>
	);
};

export default NotFound;
