import { Action, isAnyOf } from '@reduxjs/toolkit';
import { combineEpics, Epic } from 'redux-observable';
import {
	filter,
	from,
	map,
	mergeMap,
	takeUntil,
	of,
	switchMap,
	timer,
	withLatestFrom,
	concatMap,
	catchError,
	concat,
	startWith,
	tap,
} from 'rxjs';
import { asyncTaskApi } from '../../api/asyncTask.api';
import { snackbarService } from '../../components/snackbar/snackbar.service';
import { EAsyncTask, EAsyncTaskStatus } from '../../models/asyncTask.model';
import { IAppState } from '../../models/store';
import { asyncTaskActions } from '../reducers/asyncTask.reducer';
import { chartsActions } from '../reducers/charts.reducers';
import { store } from '../store';
import { workspaceDashboardAction } from '../reducers/workspaceDashboard.reducer';
import { getErrorMessage } from '../../utils/functions/errorMessages';

interface ITaskIdValue {
	chart_id: number;
	status: string;
}

const asyncTaskPollingEpic: Epic<Action, Action, IAppState> = (
	action$,
	state$
) => {
	const isPollingAction = isAnyOf(asyncTaskActions.poll);
	const isPollStopAction = isAnyOf(asyncTaskActions.stop);

	return action$.pipe(
		filter(isPollingAction),
		withLatestFrom(state$),
		mergeMap(([action, state]) => {
			const task = action.payload;
			const taskMap = state.asyncTaskState.tasks;
			const taskIdExists = task.id && taskMap[task.name] === task.id;

			if (!taskIdExists) {
				return of(
					asyncTaskActions.fulfilled({ task, status: EAsyncTaskStatus.SUCCESS })
				);
			}

			const stopPolling$ = action$.pipe(
				filter(isPollStopAction),
				map((stopAction) => {
					return stopAction.payload === task.id;
				})
			);

			// run asyncTaskApi until we see status turns success or reject.
			// then dispatch action once again, that will end the whole polling
			// cycle
			const alreadyProcessed = new Set();
			return timer(0, 1000).pipe(
				takeUntil(stopPolling$),
				switchMap(() => from(asyncTaskApi(task.id, task.isPublic))),
				tap((asyncTaskApiRes) => {
					//  now only do this for dashboards
					if (task.dashboardId) {
						Object.entries(asyncTaskApiRes)
							.filter(([key, value]) => {
								if (key === 'status') return null;
								return [key, value];
							})
							.forEach(([key, value]) => {
								const taskIdRes = value as ITaskIdValue;
								// if not already processed and its successfull, dispatch action
								if (
									!alreadyProcessed.has(key) &&
									taskIdRes?.status === 'SUCCESS'
								) {
									const chartId = taskIdRes.chart_id;
									store.dispatch(
										workspaceDashboardAction.fetchDashboardGetData.fetch({
											id: chartId,
											chartId,
											taskId: key || '',
											isPublic: task.isPublic,
										})
									);

									alreadyProcessed.add(key);
								} else if (
									!alreadyProcessed.has(key) &&
									taskIdRes?.status === 'FAILURE'
								) {
									const chartId = taskIdRes.chart_id;
									store.dispatch(
										workspaceDashboardAction.fetchDashboardGetData.rejected(
											chartId
										)
									);
									alreadyProcessed.add(key);
								}
							});
					}
				}),
				filter(({ status }) => status !== EAsyncTaskStatus.IN_PROGRESS),
				concatMap(({ status }) => {
					const actions = [];
					switch (task.name) {
						case EAsyncTask.GET_DASHBOARD_TASK_IDS:
							actions.push(
								workspaceDashboardAction.fetchDashboardTaskIds.fulfilled()
							);
							break;
						case EAsyncTask.SEARCH:
							actions.push(
								chartsActions.searchQuery.search({
									value: '',
									taskId: task.id,
								})
							);
							break;
						case EAsyncTask.SUB_CALL:
							actions.push(
								chartsActions.subCall.update({
									items: null,
									taskId: task.id,
								})
							);
							break;
						case EAsyncTask.PREVIOUS_OR_NEXT_STATE:
							actions.push(
								chartsActions.previousOrNextState.fetch({
									items: null,
									taskId: task.id,
								})
							);
							break;
						case EAsyncTask.GET_DATA:
							actions.push(
								chartsActions.getData.fetch({
									id: -1,
									taskId: task.id,
									chartId: task.chartId,
									isPublic: task.isPublic,
								})
							);
							break;
						case EAsyncTask.GET_CHART_DATA:
							actions.push(
								chartsActions.getChartData.fetch({
									id: -1,
									taskId: task.id,
								})
							);
							break;
						case EAsyncTask.SELECT_OPTIONS:
							actions.push(
								chartsActions.selectedOption.fetch({
									items: null,
									taskId: task.id,
								})
							);
							break;
						case EAsyncTask.DASHBOARD_GET_DATA:
							actions.push(
								workspaceDashboardAction.fetchDashboardGetData.fetch({
									id: -1,
									taskId: task.id,
									chartId: task.chartId,
								})
							);
							break;
						case EAsyncTask.DASHBOARD_SUBCALL_GET_DATA:
							actions.push(
								workspaceDashboardAction.fetchDashboardFiltersSubCall.fetch({
									filters: null,
									dashboardId: -1,
									taskId: task.id,
									chartId: task.chartId || -1,
								})
							);
							break;
						default:
							break;
					}

					return [
						...actions,
						asyncTaskActions.fulfilled({ task, status }),
						asyncTaskActions.stop(task.id),
					];
				}),
				catchError((error) => {
					const actions = [];
					switch (task.name) {
						case EAsyncTask.GET_DASHBOARD_TASK_IDS:
							actions.push(
								workspaceDashboardAction.fetchDashboardTaskIds.rejected()
							);
							break;
						case EAsyncTask.SEARCH:
							actions.push(chartsActions.searchQuery.rejected());
							break;
						case EAsyncTask.SUB_CALL:
							actions.push(chartsActions.subCall.rejected());
							break;
						case EAsyncTask.PREVIOUS_OR_NEXT_STATE:
							actions.push(chartsActions.previousOrNextState.rejected());
							break;
						case EAsyncTask.GET_DATA:
							actions.push(chartsActions.getData.rejected(task.chartId || -1));
							break;
						case EAsyncTask.GET_CHART_DATA:
							actions.push(chartsActions.getChartData.rejected());
							break;
						case EAsyncTask.SELECT_OPTIONS:
							actions.push(chartsActions.selectedOption.rejected());
							break;
						case EAsyncTask.DASHBOARD_GET_DATA:
							actions.push(
								workspaceDashboardAction.fetchDashboardGetData.rejected(
									task.chartId || -1
								)
							);
							break;
						case EAsyncTask.DASHBOARD_SUBCALL_GET_DATA:
							actions.push(
								workspaceDashboardAction.fetchDashboardFiltersSubCall.rejected(
									task.chartId || -1
								)
							);
							break;
						default:
							break;
					}

					const message = getErrorMessage(
						error,
						'Sorry, that action could not be completed. Please try again later or contact your administrator.'
					);
					snackbarService.showErrorToast(message);

					return concat([
						...actions,
						asyncTaskActions.rejected(task.id),
						asyncTaskActions.stop(task.id),
					]);
				}),
				startWith(asyncTaskActions.pending(task.id))
			);
		}, 1)
	);
};
export const asyncTaskEpic = combineEpics(asyncTaskPollingEpic);
