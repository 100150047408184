import { IDataRefreshItems } from '../models';
import { IDataRefreshItemDTO } from '../models/dto';

export const serializeDataRefreshItem = (
	item: IDataRefreshItemDTO
): IDataRefreshItems => ({
	id: item.metadata_key,
	table: item.tbl_name,
	dataSource: item.data_source,
	lastRefreshDate: item.last_refresh_date,
	status: item.last_refresh_status,
	numberOfRecords: item.extract_row_count,
});

export const serializeDataRefreshItems = (
	items: IDataRefreshItemDTO[]
): IDataRefreshItems[] => items.map((item) => serializeDataRefreshItem(item));
