import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	Box,
	IconButton,
	InputBase,
	List,
	ListItem,
	Paper,
	Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchAutocomplete } from '../../../api/search.api';
import { IAutocompleteItem } from '../../../models';

interface IProps {
	onSearch: (query: string) => void;
}

const SearchBar: React.FC<IProps> = (props) => {
	const { onSearch } = props;

	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState<readonly IAutocompleteItem[]>([]);
	const [highlightedOptionIndex, setHighlightedOptionIndex] =
		useState<number>(-1);
	const [open, setOpen] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);
	const listboxRef = useRef<HTMLUListElement>(null);

	const onChange = useCallback(
		(
			_event: React.SyntheticEvent<Element, Event>,
			option: IAutocompleteItem | null
		) => {
			if (inputRef.current) {
				inputRef.current.focus();
			}

			setInputValue((prev) => {
				const newInputValue = option?.title || '';
				const words = prev.split(' ');

				if (!option) {
					return inputRef?.current?.value || '';
				}

				if (words.length === 1) {
					return newInputValue;
				}

				if (!prev) {
					return newInputValue;
				}

				words.splice(words.length - 1, 1, newInputValue);
				const newString = words.join(' ');

				return newString;
			});
			setOpen(false);
		},
		[]
	);

	const onInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
		(event) => {
			const newInputValue = event.target.value;
			setInputValue(newInputValue);

			if (!newInputValue) {
				setOptions([]);
				return;
			}

			const words = newInputValue.split(' ');
			const lastWord = words[words.length - 1];

			if (!lastWord) {
				return;
			}

			searchAutocomplete(lastWord).then((results) => {
				setOptions(results);
				setOpen(true);
			});
		},
		[]
	);

	const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
		(e) => {
			const selectedOption =
				highlightedOptionIndex >= 0 && highlightedOptionIndex <= options.length
					? options[highlightedOptionIndex]
					: null;

			switch (e.key) {
				case 'ArrowDown':
					if (!open) {
						setOpen(true);
					} else {
						setHighlightedOptionIndex((prev) => {
							if (prev < 0) {
								return 0;
							}

							if (prev >= options.length - 1) {
								return options.length - 1;
							}

							return prev + 1;
						});
					}

					break;

				case 'ArrowUp':
					if (!open) {
						setOpen(true);
					} else {
						setHighlightedOptionIndex((prev) => (prev > 0 ? prev - 1 : 0));
					}
					break;

				case 'Enter':
					e.preventDefault();

					if (open) {
						onChange(e, selectedOption);
					} else {
						onSearch(inputValue);
					}

					break;
				default:
					break;
			}
		},
		[open, options, highlightedOptionIndex, onChange, inputValue, onSearch]
	);

	const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();

		if (!open) {
			onSearch(inputValue);
		}
	};

	useEffect(() => {
		const clickListener = (e: MouseEvent) => {
			if (
				!listboxRef?.current?.contains(e.target as HTMLElement) ||
				e.target !== listboxRef?.current
			) {
				setOpen(false);
			}
		};

		if (open) {
			window.addEventListener('click', clickListener);
		}

		return () => {
			window.removeEventListener('click', clickListener);
		};
	}, [open]);

	useEffect(() => {
		if (!open) {
			setHighlightedOptionIndex(-1);
		}
	}, [open]);

	return (
		<Box
			sx={{
				position: 'relative',
			}}
		>
			<Paper
				component="form"
				onSubmit={onSubmit}
				sx={{
					p: 0,
					display: 'flex',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<InputBase
					inputRef={inputRef}
					sx={{ flex: 1, px: 2 }}
					placeholder="Ask any data question"
					value={inputValue}
					inputProps={{
						'aria-label': 'search',
						'data-testid': 'navbarSearch',
						onKeyDown,
						// onBlur: onInputBlur,
						onChange: onInputChange,
					}}
				/>
				<IconButton
					type="submit"
					size="small"
					data-testid="navbarSearchSubmitBtn"
				>
					<SearchIcon color="primary" fontSize="small" />
				</IconButton>
			</Paper>
			{open && options.length ? (
				<Paper
					sx={{
						position: 'absolute',
						top: '100%',
						left: 0,
						width: '100%',
						zIndex: 'modal',
					}}
				>
					<List role="listbox" ref={listboxRef}>
						{options.map((option, index) => (
							<ListItem
								role="option"
								sx={{
									display: 'block',
									cursor: 'pointer',
									backgroundColor:
										index === highlightedOptionIndex
											? 'primary.light'
											: 'inherit',
									color:
										index === highlightedOptionIndex
											? 'primary.contrastText'
											: 'inherit',
									'&:hover': {
										backgroundColor: 'primary.light',
										color: 'primary.contrastText',
									},
								}}
								onClick={(e) => onChange(e, option)}
								key={`option_${index}`}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%',
									}}
								>
									<strong>{option.title}</strong>
									<Typography variant="caption">{option.type}</Typography>
								</Box>
								<Box sx={{ display: 'block' }}>
									<Typography variant="caption">{option.table}</Typography>
								</Box>
							</ListItem>
						))}
					</List>
				</Paper>
			) : null}
		</Box>
	);
};

export default SearchBar;
