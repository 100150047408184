import {
	IAdminPreferences,
	IUserPreferences,
} from '../models/appSettings.model';
import {
	IAdminPreferencesDTO,
	ICustomerConfigDetailsDTO,
	ICustomerSecretsDetailsDTO,
	IUserPreferencesDTO,
} from '../models/dto/appSettings.dto.model';
import {
	deSerializeAdminPreferences,
	deSerializeUserPreferences,
	serializeAdminPreferences,
	serializeCustomerConfigDetails,
	serializeCustomerSecretDetails,
	serializeUserPreferences,
} from '../serializers/appSettings.serializer';
import axiosInstance from './axios/axios';

export const fetchCustomerConfigApi = async () => {
	const response = await axiosInstance.get<ICustomerConfigDetailsDTO[]>(
		'/configure/api/configureCustomerConfig/'
	);
	const serializedResponse = serializeCustomerConfigDetails(response.data[0]);

	return serializedResponse;
};

export const fetchCustomerSecretsApi = async () => {
	const response = await axiosInstance.get<ICustomerSecretsDetailsDTO[]>(
		'/configure/api/configureCustomerSecrets/'
	);

	const serializedResponse = serializeCustomerSecretDetails(response.data[0]);

	return serializedResponse;
};

export const updateCustomerConfigApi = async (payload: FormData) => {
	const response = await axiosInstance.post<ICustomerConfigDetailsDTO>(
		'/configure/api/configureCustomerConfig/',
		payload
	);
	const serializedResponse = serializeCustomerConfigDetails(response.data);

	return serializedResponse;
};

export const updateCustomerSecretsApi = async (payload: FormData) => {
	const response = await axiosInstance.post<ICustomerSecretsDetailsDTO>(
		'/configure/api/configureCustomerSecrets/',
		payload
	);

	const serializedResponse = serializeCustomerSecretDetails(response.data);

	return serializedResponse;
};

// User Preferences ------------------------------------------------------------
export const fetchUserPreferencesApi = async () => {
	const response = await axiosInstance.get<IUserPreferencesDTO[]>(
		'/ddive/api/prefs_api/user/'
	);

	const serializedResponse = serializeUserPreferences(response.data);

	return serializedResponse;
};

export const updateUserPreferencesApi = async (payload: IUserPreferences) => {
	const updatedPayload = deSerializeUserPreferences(payload);
	const response = await axiosInstance.post<IUserPreferencesDTO[]>(
		'/ddive/api/prefs_api/user/bulk/',
		updatedPayload
	);

	const serializedResponse = serializeUserPreferences(response.data);

	return serializedResponse;
};

export const resetUserPreferencesApi = async () => {
	const response = await axiosInstance.get<IUserPreferencesDTO[]>(
		'/ddive/api/prefs_api/user/bulk_reset/'
	);

	const serializedResponse = serializeUserPreferences(response.data);

	return serializedResponse;
};

// Admin Preferences -----------------------------------------------------------
export const fetchAdminPreferencesApi = async () => {
	const response = await axiosInstance.get<IAdminPreferencesDTO[]>(
		'/ddive/api/prefs_api/global/'
	);

	const serializedResponse = serializeAdminPreferences(response.data);

	return serializedResponse;
};

export const updateAdminPreferencesApi = async (payload: IAdminPreferences) => {
	const updatedPayload = deSerializeAdminPreferences(payload);
	const response = await axiosInstance.post<IUserPreferencesDTO[]>(
		'/ddive/api/prefs_api/global/bulk/',
		updatedPayload
	);

	const serializedResponse = serializeAdminPreferences(response.data);

	return serializedResponse;
};

export const resetAdminPreferencesApi = async () => {
	const response = await axiosInstance.get<IAdminPreferencesDTO[]>(
		'/ddive/api/prefs_api/global/bulk_reset/'
	);

	const serializedResponse = serializeAdminPreferences(response.data);

	return serializedResponse;
};
