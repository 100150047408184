import { Action, isAnyOf } from '@reduxjs/toolkit';
import { from, of } from 'rxjs';

import { combineEpics, Epic } from 'redux-observable';
import {
	catchError,
	filter,
	map,
	mergeMap,
	startWith,
	switchMap,
	tap,
} from 'rxjs/operators';

import { IAppState } from '../../models/store';
import {
	bulkUpdateHierarchies,
	fetchHierarchies,
	fetchHierarchiesKeywords,
	updateHierarchy,
} from '../actions/hierarchyValidation.actions';
import {
	bulkUpdateHierarchiesApi,
	fetchHierarchiesKeywordsApi,
	fetchHierarchiesApi,
	updateHierarchyApi,
} from '../../api/hierarchyValidation.api';
import { snackbarService } from '../../components/snackbar/snackbar.service';

const fetchHierarchyDataEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isHierarchyFetchAction = isAnyOf(fetchHierarchies.fetch);
	return action$.pipe(
		filter(isHierarchyFetchAction),
		switchMap(() =>
			from(fetchHierarchiesApi()).pipe(
				map((data) => fetchHierarchies.fulfilled(data)),
				catchError(() => {
					return of(fetchHierarchies.rejected());
				}),
				startWith(fetchHierarchies.pending())
			)
		)
	);
};

const fetchHierarchyKeywordsEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isHierarchyFetchAction = isAnyOf(fetchHierarchiesKeywords.fetch);
	return action$.pipe(
		filter(isHierarchyFetchAction),
		switchMap((data) =>
			from(fetchHierarchiesKeywordsApi(data.payload)).pipe(
				map((response) => fetchHierarchiesKeywords.fulfilled(response)),
				catchError((e) => {
					return of(fetchHierarchiesKeywords.rejected(e.response?.data.detail));
				}),
				startWith(fetchHierarchiesKeywords.pending(data.payload))
			)
		)
	);
};

const bulkUpdateHierarchyEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isBulkUpdateHierarchyAction = isAnyOf(bulkUpdateHierarchies.update);
	return action$.pipe(
		filter(isBulkUpdateHierarchyAction),
		mergeMap((rows) =>
			from(bulkUpdateHierarchiesApi(rows.payload.payloadData)).pipe(
				map((data) => bulkUpdateHierarchies.fulfilled(data)),
				tap((action) => {
					const updatedColumn = rows.payload.updatingColumn.columnName;
					const updatedValue = rows.payload.updatingColumn.value;
					const items = action.payload;
					let message: string;

					switch (updatedColumn) {
						case 'assignedTo':
							message = `${items.length} items : assigned to '${updatedValue}'`;
							break;
						case 'comments':
							message = `${items.length} items : comments updated to '${updatedValue}'`;
							break;
						default:
							message = `${items.length} items : ${updatedColumn} updated to '${updatedValue}'`;
							break;
					}
					snackbarService.showSuccessToast(message);
				}),
				catchError(() => {
					const updatedColumn = rows.payload.updatingColumn.columnName;
					const updateDataLength = rows.payload.payloadData.length;
					let message: string;

					switch (updatedColumn) {
						case 'assignedTo':
							message = `${updateDataLength} items : assign failed`;
							break;
						default:
							message = `${updateDataLength} items : ${updatedColumn} update failed`;
							break;
					}
					snackbarService.showSuccessToast(message);
					return of(bulkUpdateHierarchies.rejected(rows.payload.updatingRows));
				}),
				startWith(bulkUpdateHierarchies.pending(rows.payload.updatingRows))
			)
		)
	);
};

const singleUpdateHierarchyEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const isSingleUpdateHierarchyAction = isAnyOf(updateHierarchy.update);
	return action$.pipe(
		filter(isSingleUpdateHierarchyAction),
		mergeMap((rows) =>
			from(updateHierarchyApi(rows.payload.payloadData)).pipe(
				map((data) => updateHierarchy.fulfilled(data)),
				tap((action) => {
					const updatedColumn = rows.payload.updatingColumn.columnName;
					const updatedValue = rows.payload.updatingColumn.value;
					const hierarchyName = action.payload.name;
					let message: string;

					switch (updatedColumn) {
						case 'assignedTo':
							message = `${hierarchyName} : assigned to '${updatedValue}'`;
							break;
						case 'comments':
							message = `${hierarchyName} : comment updated to '${updatedValue}'`;
							break;
						default:
							message = `${hierarchyName} : ${updatedColumn} updated to '${updatedValue}'`;
							break;
					}
					snackbarService.showSuccessToast(message);
				}),
				catchError(() => {
					const updatedColumn = rows.payload.updatingColumn.columnName;
					const { hierarchyName } = rows.payload.updatingColumn;
					let message: string;

					switch (updatedColumn) {
						case 'assignedTo':
							message = `${hierarchyName} : assign failed`;
							break;
						case 'comments':
							message = `${hierarchyName} : comment update failed`;
							break;
						default:
							message = `${hierarchyName} : ${updatedColumn} update failed`;
							break;
					}
					snackbarService.showSuccessToast(message);
					return of(updateHierarchy.rejected(rows.payload));
				}),
				startWith(updateHierarchy.pending(rows.payload))
			)
		)
	);
};

export const hierarchyValidationEpic = combineEpics(
	fetchHierarchyDataEpic,
	fetchHierarchyKeywordsEpic,
	bulkUpdateHierarchyEpic,
	singleUpdateHierarchyEpic
);
