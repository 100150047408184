import { createTheme } from '@mui/material';
import MontserratRegularTTF from '../assets/fonts/Montserrat-Regular.ttf';
import MontserratBoldTTF from '../assets/fonts/Montserrat-Bold.ttf';

export const themeDefaultValues = {
	primaryDefaultColor: '0a2583',
	secondaryDefaultColor: 'f50057',
};

const customizedTheme = (items?: {
	primaryColor: string;
	secondaryColor: string;
}) => {
	const theme = createTheme({
		palette: {
			primary: {
				main: `#${
					items?.primaryColor || themeDefaultValues.primaryDefaultColor
				}`,
			},
			secondary: {
				main: `#${
					items?.secondaryColor || themeDefaultValues.secondaryDefaultColor
				}`,
			},
			contrastThreshold: 2,
			text: {
				primary: 'rgb(0, 0, 0)',
			},
		},
		typography: {
			fontFamily: [`'Montserrat'`, 'Roboto', 'sans-serif'].join(','),
			fontSize: 12,
		},

		components: {
			MuiCssBaseline: {
				styleOverrides: `
					@font-face {
						font-family: 'Montserrat';
						font-style: 'normal';
						font-display: 'swap';
						font-weight: 400;
						src: local('Montserrat'), local('Montserrat-Regular'), url(${MontserratRegularTTF}) format('truetype');
					}
	
					@font-face {
						font-family: 'Montserrat';
						font-style: 'normal';
						font-display: 'swap';
						font-weight: 700;
						src: local('Montserrat'), local('Montserrat-Bold'), url(${MontserratBoldTTF}) format('truetype');
					}
	
					html, body {
						height: 100%;
						font-feature-settings: "tnum";
					}
	
					#root {
						height: 100%;
					}
				`,
			},
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: 'none',
					},
				},
			},
		},
	});

	return theme;
};

export default customizedTheme;
