export enum EAsyncTaskStatus {
	IN_PROGRESS = 'IN PROGRESS',
	SUCCESS = 'SUCCESS',
	FAILURE = 'FAILURE',
	TASK_NOT_FOUND = 'TASK NOT FOUND',
}

export interface ITaskStatusResponse {
	status: EAsyncTaskStatus;
	error?: string;
}

export enum EAsyncTask {
	SEARCH = 'SEARCH',
	SUB_CALL = 'SUB CALL',
	PREVIOUS_OR_NEXT_STATE = 'PREVIOUS OR NEXT STATE',
	GET_DATA = 'GET DATA',
	GET_CHART_DATA = 'GET CHART DATA',
	CREATE_FROM_ITEMS = 'CREATE FROM ITEMS',
	SELECT_OPTIONS = 'SELECT OPTIONS',
	DASHBOARD_GET_DATA = 'DASHBOARD GET DATA',
	DASHBOARD_SUBCALL_GET_DATA = 'DASHBOARD SUBCALL GET DATA',
	GET_DASHBOARD_TASK_IDS = 'GET DASHBOARD TASK IDS',
}

export interface IAsyncTask {
	name: EAsyncTask;
	id: string;
	chartId?: number;
	isPublic?: boolean;
	chartIds?: number[];
	dashboardId?: number;
}
