import { Action, isAnyOf } from '@reduxjs/toolkit';
import { combineEpics, Epic } from 'redux-observable';
import { catchError, filter, from, map, of, startWith, switchMap } from 'rxjs';
import { fetchUsageLogsApi } from '../../api/usageLogs.api';
import { IAppState } from '../../models/store';
import { usageLogsActions } from '../reducers/usageLogs.reducer';

const fetchUsageLogsEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isFetchAction = isAnyOf(usageLogsActions.fetchUsageLogs.fetch);
	return action$.pipe(
		filter(isFetchAction),
		switchMap((data) => {
			return from(fetchUsageLogsApi(data.payload)).pipe(
				map((items) => usageLogsActions.fetchUsageLogs.fulfilled(items)),
				catchError(() => of(usageLogsActions.fetchUsageLogs.rejected())),
				startWith(usageLogsActions.fetchUsageLogs.pending())
			);
		})
	);
};

export const usageLogsEpics = combineEpics(fetchUsageLogsEpic);
