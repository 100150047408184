import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	Box,
	Divider,
	Drawer,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const drawerWidth = 280;
interface IProps {
	open: boolean;
	handleDrawerToggle: () => void;
}

const SideDrawer: React.FC<IProps> = (props) => {
	const { open, handleDrawerToggle } = props;

	const location = useLocation();
	const theme = useTheme();

	const [active, setActive] = useState('permissions');

	useEffect(() => {
		const pathName = location.pathname.split('/')[2];
		setActive(pathName);
	}, [location.pathname]);

	const drawerContents = (
		<List>
			<ListItemButton onClick={handleDrawerToggle}>
				<ListItemIcon>
					{open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
				</ListItemIcon>
			</ListItemButton>

			<Divider />
			<Tooltip
				title={<Typography>Permissions</Typography>}
				placement="right"
				arrow
			>
				<ListItemButton
					selected={active === 'permissions'}
					component={Link}
					to="permissions"
					aria-label="Go to permissions"
				>
					<ListItemIcon>
						<VerifiedUserIcon
							color={active === 'permissions' ? 'primary' : 'inherit'}
						/>
					</ListItemIcon>
					<ListItemText primary="Permissions" />
				</ListItemButton>
			</Tooltip>

			<Tooltip title={<Typography>Groups</Typography>} placement="right" arrow>
				<ListItemButton
					selected={active === 'groups'}
					component={Link}
					to="groups"
					aria-label="Go to groups"
				>
					<ListItemIcon>
						<GroupIcon color={active === 'groups' ? 'primary' : 'inherit'} />
					</ListItemIcon>
					<ListItemText primary="Groups" />
				</ListItemButton>
			</Tooltip>

			<Tooltip title={<Typography>Users</Typography>} placement="right" arrow>
				<ListItemButton
					selected={active === 'users'}
					component={Link}
					to="users"
					aria-label="Go to users"
				>
					<ListItemIcon>
						<PersonIcon color={active === 'users' ? 'primary' : 'inherit'} />
					</ListItemIcon>
					<ListItemText primary="Users" />
				</ListItemButton>
			</Tooltip>
		</List>
	);

	return (
		<Drawer
			variant="permanent"
			sx={{
				width: open ? drawerWidth : theme.spacing(7),
				flexShrink: 0,
				whiteSpace: 'nowrap',
				marginTop: 8,
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: open
						? theme.transitions.duration.enteringScreen
						: theme.transitions.duration.leavingScreen,
				}),
			}}
			PaperProps={{
				sx: {
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: open
							? theme.transitions.duration.enteringScreen
							: theme.transitions.duration.leavingScreen,
					}),
					marginTop: 8,
					width: open ? drawerWidth : theme.spacing(7),
					zIndex: 'drawer',
					boxSizing: 'border-box',
				},
			}}
			ModalProps={{
				keepMounted: true, // Better open performance on mobile.
			}}
		>
			<Box
				sx={{
					height: 'calc(100% - 64px)',
					overflowY: 'auto',
					overflowX: 'hidden',
				}}
			>
				{drawerContents}
			</Box>
		</Drawer>
	);
};

export default SideDrawer;
