import { createAction } from '@reduxjs/toolkit';

export const createAsyncAction = <F, P = void, R = void>(
	name: string,
	slice?: string
) => {
	const prefix = slice ? `${slice}/` : '';

	return {
		pending: createAction<P>(`${prefix}${name}/pending`),
		rejected: createAction<R>(`${prefix}${name}/rejected`),
		fulfilled: createAction<F>(`${prefix}${name}/fulfilled`),
	};
};
