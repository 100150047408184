import {
	IDataSummarySearchResponseDTO,
	IHierarchyDetailsItemDTO,
	IHierarchyDetailsItemsDTO,
	IJoinPathsDetailsDTO,
	IModelDetailsItemDTO,
	IReferenceDetailsItemDTO,
	ITableDetailsDataDTO,
	ITableDetailsItemsDTO,
	ITableDiagramDetailsDTO,
	IAxisDetailsItemsDTO,
	IMetricDetailsItemsDTO,
} from '../models/dto';
import {
	IAxisDetailsItems,
	IDataModelEdge,
	IDataModelNode,
	IDataSummarySearchResponse,
	IHierarchyDetailsItems,
	IJoinPathDetails,
	IMetricDetailsItems,
	IModelDetailsItem,
	INavigatorAxis,
	INavigatorHierarchy,
	INavigatorMetric,
	INavigatorModel,
	INavigatorReference,
	INavigatorTable,
	IReferenceDetailsItem,
	ITableDetailsData,
	ITableAxis,
	ITableDetailsItems,
	ITableDiagramEdge,
	ITableDiagramNode,
	ITableMetrics,
	ITableReference,
} from '../models';

// NAVIGATOR SERIALIZER  -------------------------------------------------------
export const serializeNavigatorModelItem = (
	model: IModelDetailsItemDTO
): INavigatorModel => ({
	id: model.id,
	name: model.db_name,
	customerName: model.instance.name,
});

export const serializeNavigatorModelItems = (
	models: IModelDetailsItemDTO[]
): INavigatorModel[] =>
	models.map((model) => serializeNavigatorModelItem(model));

export const serializeNavigatorTableItem = (
	table: ITableDetailsItemsDTO
): INavigatorTable => ({
	id: table.id,
	name: table.table_name,
	modelId: table.catalog,
	alias: table.aliases?.length ? table.aliases[0] : '',
});

export const serializeNavigatorTableItems = (
	tables: ITableDetailsItemsDTO[]
): INavigatorTable[] =>
	tables.map((table) => serializeNavigatorTableItem(table));

export const serializeNavigatorMetricItem = (
	metric: IMetricDetailsItemsDTO
): INavigatorMetric => ({
	id: metric.id,
	name: metric.measure_name,
	tableId: metric.table,
});

export const serializeNavigatorMetricItems = (
	metrics: IMetricDetailsItemsDTO[]
): INavigatorMetric[] =>
	metrics.map((metric) => serializeNavigatorMetricItem(metric));

export const serializeNavigatorAxisItem = (
	axis: IAxisDetailsItemsDTO
): INavigatorAxis => ({
	id: axis.id,
	name: axis.axis_name,
	tableId: axis.table,
});

export const serializeNavigatorAxisItems = (
	axes: IAxisDetailsItemsDTO[]
): INavigatorAxis[] => axes.map((axis) => serializeNavigatorAxisItem(axis));

export const serializeNavigatorReferenceItem = (
	reference: IReferenceDetailsItemDTO
): INavigatorReference => ({
	id: reference.id,
	tableId: reference.orig_table_detail.id,
	name:
		reference.aliases[0] ||
		`${reference.orig_table_detail.table_name}-${reference.referenced_table_detail.table_name}`,
});

export const serializeNavigatorReferenceItems = (
	references: IReferenceDetailsItemDTO[]
): INavigatorReference[] =>
	references.map((reference) => serializeNavigatorReferenceItem(reference));

export const serializeNavigatorHierarchyItem = (
	hierarchy: IHierarchyDetailsItemDTO
): INavigatorHierarchy => ({ id: hierarchy.id, name: hierarchy.entity_name });

export const serializeNavigatorHierarchyItems = (
	hierarchies: IHierarchyDetailsItemDTO[]
): INavigatorHierarchy[] =>
	hierarchies.map((hierarchy) => serializeNavigatorHierarchyItem(hierarchy));

export const serializeNavigatorSearchResult = (
	response: IDataSummarySearchResponseDTO
): IDataSummarySearchResponse => {
	const models = serializeNavigatorModelItems(response.data_sources);
	const searchTables = serializeNavigatorTableItems(response.tables);
	let parentTables = serializeNavigatorTableItems(response.parent_tables);
	const metrics = serializeNavigatorMetricItems(response.measures);
	const axes = serializeNavigatorAxisItems(response.axes);
	const references = serializeNavigatorReferenceItems(response.references);
	const hierarchies = serializeNavigatorHierarchyItems(response.hierarchies);

	const tableIds = new Set<number>(searchTables.map((table) => table.id));

	parentTables = parentTables.filter((table) => !tableIds.has(table.id));

	const tables = [...searchTables, ...parentTables];

	const searchResult: IDataSummarySearchResponse = {
		models,
		tables,
		axes,
		metrics,
		hierarchies,
		references,
	};

	return searchResult;
};
// END: NAVIGATOR SERIALIZER  ---------------------------------------------------

// MODEL SERIALIZER  ------------------------------------------------------------
export const serializeModelDetailsItem = (
	model: IModelDetailsItemDTO
): IModelDetailsItem => ({
	dbName: model.db_name,
	dbType: model.db_type,
	host: model.host,
	id: model.id,
	port: model.port,
	instance: { ...model.instance },
});

export const serializeDataModelNode = (
	table: ITableDetailsItemsDTO
): IDataModelNode => {
	return {
		id: table.table_name,
		name: table.aliases[0] || table.table_name,
		tableName: table.table_name,
		type: table.item_type,
		tableId: table.id,
	};
};

export const serializeDataModelNodes = (
	tables: ITableDetailsItemsDTO[]
): IDataModelNode[] => tables.map((table) => serializeDataModelNode(table));

export const serializeDataModelEdge = (
	reference: IReferenceDetailsItemDTO
): IDataModelEdge => ({
	id: `reference_${reference.id}`,
	source: reference.orig_table_detail.table_name,
	destination: reference.referenced_table_detail.table_name,
	data: reference.join_path_detail.map((path) => ({
		id: path.id,
		origColumnName: path.orig_column_name,
		refColumnName: path.ref_column_name,
	})),
});

export const serializeDataModelEdges = (
	references: IReferenceDetailsItemDTO[]
): IDataModelEdge[] =>
	references.map((reference) => serializeDataModelEdge(reference));

export const serializeTableMetricItem = (
	metric: IMetricDetailsItemsDTO
): ITableMetrics => ({
	id: metric.id,
	name: metric.measure_name,
	tableId: metric.table,
	aliases: metric.aliases,
});

export const serializeTableMetricItems = (
	metrics: IMetricDetailsItemsDTO[]
): ITableMetrics[] => metrics.map((metric) => serializeTableMetricItem(metric));

export const serializeTableAxisItem = (
	axis: IAxisDetailsItemsDTO
): ITableAxis => ({
	id: axis.id,
	name: axis.axis_name,
	tableId: axis.table,
	aliases: axis.aliases,
});

export const serializeTableAxisItems = (
	axes: IAxisDetailsItemsDTO[]
): ITableAxis[] => axes.map((axis) => serializeTableAxisItem(axis));

export const serializeTableReferenceItems = (
	references: IReferenceDetailsItemDTO[],
	tableName: string
): ITableReference[] => {
	const referencesDetails: ITableReference[] = [];

	references.forEach((reference) => {
		const isReferencedColumn =
			reference.referenced_table_detail.table_name === tableName;

		reference.join_path_detail.forEach((joinPath) => {
			if (
				isReferencedColumn &&
				!referencesDetails.filter(
					(referenceDetails) =>
						referenceDetails.name === joinPath.ref_column_name
				).length
			) {
				referencesDetails.push({
					id: reference.id,
					name: joinPath.ref_column_name,
				});
			} else if (
				!isReferencedColumn &&
				!referencesDetails.filter(
					(referenceDetails) =>
						referenceDetails.name === joinPath.orig_column_name
				).length
			) {
				referencesDetails.push({
					id: reference.id,
					name: joinPath.orig_column_name,
				});
			}
		});
	});

	return referencesDetails;
};

// END: MODEL SERIALIZER  -------------------------------------------------------

// TABLE SERIALIZER  ------------------------------------------------------------
export const serializeTablesDetailsItem = (
	item: ITableDetailsItemsDTO
): ITableDetailsItems => ({
	id: item.id,
	active: item.active,
	aliases: item.aliases,
	catalog: item.catalog,
	db: item.db,
	itemType: item.item_type,
	schema: item.schema,
	tableName: item.table_name,
	primaryColumn: item.primary_column,
	defaultColumn: item.default_column,
	countColumn: item.count_column,
	tableRank: item.table_rank,
});

export const serializeTablesDetailsData = (
	item: ITableDetailsDataDTO
): ITableDetailsData => ({
	table: serializeTablesDetailsItem(item.table),
	dataSource: item.data_source,
	lastUpdatedDate: item.last_update_date,
});

export const serializeTableDiagramNode = (
	table: ITableDetailsItemsDTO
): ITableDiagramNode => {
	return {
		id: table.id,
		name: table.aliases[0] || table.table_name,
		tableName: table.table_name,
		itemType: table.item_type,
	};
};

export const serializeTableDiagramNodes = (
	items: ITableDiagramDetailsDTO
): ITableDiagramNode[] =>
	items.tables.map((table) => serializeTableDiagramNode(table));

export const serializeTableJoinPaths = (
	joinPathDetail: IJoinPathsDetailsDTO
): IJoinPathDetails => {
	return {
		id: joinPathDetail.id,
		origColumnName: joinPathDetail.orig_column_name,
		refColumnName: joinPathDetail.ref_column_name,
	};
};

export const serializeTableDiagramEdge = (
	reference: IReferenceDetailsItemDTO
): ITableDiagramEdge => {
	return {
		source: reference.orig_table_detail.table_name,
		destination: reference.referenced_table_detail.table_name,
		joinPathsDetails: reference.join_path_detail.map((joinPathDetail) =>
			serializeTableJoinPaths(joinPathDetail)
		),
	};
};

export const serializeTableDiagramEdges = (
	items: ITableDiagramDetailsDTO
): ITableDiagramEdge[] =>
	items.references.map((reference) => serializeTableDiagramEdge(reference));

export const serializeTableDiagramReferences = (
	items: ITableDiagramDetailsDTO,
	tableId: number
): string[] => {
	const refDetails: string[] = [];
	const selectedTableName = items.tables.filter(
		(table) => table.id === tableId
	)[0].table_name;

	items.references.forEach((reference) => {
		const isReferencedColumn =
			reference.referenced_table_detail.table_name === selectedTableName;
		reference.join_path_detail.forEach((joinPath) => {
			if (
				!refDetails.includes(joinPath.ref_column_name) &&
				!refDetails.includes(joinPath.orig_column_name)
			) {
				if (isReferencedColumn) {
					refDetails.push(joinPath.ref_column_name);
				} else {
					refDetails.push(joinPath.orig_column_name);
				}
			}
		});
	});
	return refDetails;
};

// END: TABLE SERIALIZER  -------------------------------------------------------

// HIERARCHY SERIALIZER  -------------------------------------------------------

export const serializeHierarchyDetailsItem = (
	item: IHierarchyDetailsItemsDTO
): IHierarchyDetailsItems => ({
	id: item.id,
	aliases: item.axis_name.aliases,
	axisName: item.axis_name.axis_name,
	tableName: item.axis_name.column_detail.table.table_name,
	itemType: item.axis_name.column_detail.table.item_type,
	levelName: item.level_name,
	rank: item.rank,
	hierarchyName: item.hierarchy_name,
});

export const serializeHierarchyDetailsItems = (
	items: IHierarchyDetailsItemsDTO[]
): IHierarchyDetailsItems[] =>
	items.map((item) => serializeHierarchyDetailsItem(item));
// END: HIERARCHY SERIALIZER  ---------------------------------------------------

// METRICS SERIALIZER  ----------------------------------------------------------

export const serializeMetricsDetailsItem = (
	item: IMetricDetailsItemsDTO
): IMetricDetailsItems => ({
	id: item.id,
	active: item.active,
	aggregation: item.aggregation,
	alias: item.aliases[0],
	aliases: item.aliases,
	catalog: item.catalog,
	category: item.category,
	dataType: item.data_type,
	db: item.db,
	metaId: item.meta_id,
	itemType: item.item_type,
	measureName: item.measure_name,
	schema: item.schema,
	table: item.table,
	tableName: item.table_name,
	calculationFormula: item.calculation_def,
	uomDetails: {
		uom: item.uom_detail?.uom,
	},
});

export const serializeMetricsDetailsItems = (
	items: IMetricDetailsItemsDTO[]
): IMetricDetailsItems[] =>
	items.map((item) => serializeMetricsDetailsItem(item));

// END: METRICS SERIALIZER  -----------------------------------------------------

// AXIS SERIALIZER  -------------------------------------------------------------

export const serializeAxisDetailsItem = (
	item: IAxisDetailsItemsDTO
): IAxisDetailsItems => ({
	id: item.id,
	active: item.active,
	alias: item.aliases[0],
	aliases: item.aliases,
	catalog: item.catalog,
	category: item.category,
	axisName: item.axis_name,
	columnName: item.column_name,
	dataType: item.data_type,
	db: item.db,
	itemType: item.item_type,
	metaId: item.meta_id,
	schema: item.schema,
	table: item.table,
	tableName: item.table_name,
	calculationFormula: item.calculation_def,
});

export const serializeAxisDetailsItems = (
	items: IAxisDetailsItemsDTO[]
): IAxisDetailsItems[] => items.map((item) => serializeAxisDetailsItem(item));

// END: AXIS SERIALIZER  --------------------------------------------------------

// REFERENCES SERIALIZER  -------------------------------------------------------

export const serializeReferenceDetailsItem = (
	reference: IReferenceDetailsItemDTO
): IReferenceDetailsItem => ({
	active: reference.active,
	id: reference.id,
	originalTable: serializeTablesDetailsItem(reference.orig_table_detail),
	referencesTable: serializeTablesDetailsItem(
		reference.referenced_table_detail
	),
	isDefault: reference.is_default,
	aliases: reference.aliases,
	joinPaths: reference.join_path_detail.map((joinPath) => ({
		id: joinPath.id,
		originalColumnName: joinPath.orig_column_name,
		referencedColumnName: joinPath.ref_column_name,
	})),
});
// END: REFERENCES SERIALIZER  --------------------------------------------------
