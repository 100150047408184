import { Action, isAnyOf } from '@reduxjs/toolkit';
import { from, of } from 'rxjs';

import { combineEpics, Epic } from 'redux-observable';
import {
	catchError,
	filter,
	map,
	mergeMap,
	startWith,
	switchMap,
	tap,
} from 'rxjs/operators';

import { IAppState } from '../../models/store';
import {
	fetchDashboardsApi,
	fetchUsersApi,
	fetchMetricValidationTablesApi,
	fetchMetricValidationItemsApi,
	updateMetricValidationApi,
	bulkUpdateMetricValidationApi,
} from '../../api/metricValidation.api';
import { snackbarService } from '../../components/snackbar/snackbar.service';
import {
	bulkUpdateMetricValidationItems,
	fetchDashboards,
	fetchMetricValidationTables,
	fetchMetricValidationItems,
	fetchUsers,
	updateMetricValidationItem,
} from '../actions/metricValidation.actions';
import { getErrorMessage } from '../../utils/functions/errorMessages';

const fetchMetricValidationEpic: Epic<Action, Action> = (action$) => {
	const isMetricValidationFetchAction = isAnyOf(
		fetchMetricValidationItems.fetch
	);
	return action$.pipe(
		filter(isMetricValidationFetchAction),
		switchMap(() =>
			from(fetchMetricValidationItemsApi()).pipe(
				map((data) => fetchMetricValidationItems.fulfilled(data)),
				catchError(() => {
					return of(fetchMetricValidationItems.rejected());
				}),
				startWith(fetchMetricValidationItems.pending())
			)
		)
	);
};

const fetchMetricTablesEpic: Epic<Action, Action> = (action$) => {
	const isMetricTablesFetchAction = isAnyOf(fetchMetricValidationTables.fetch);
	return action$.pipe(
		filter(isMetricTablesFetchAction),
		switchMap(() =>
			from(fetchMetricValidationTablesApi()).pipe(
				map((data) => fetchMetricValidationTables.fulfilled(data)),
				catchError((err) => {
					const message = getErrorMessage(err, 'Failed to fetch tables.');
					snackbarService.showErrorToast(message);
					return of(fetchMetricValidationTables.rejected());
				}),
				startWith(fetchMetricValidationTables.pending())
			)
		)
	);
};

const fetchDataValidationUsersEpic: Epic<Action, Action> = (action$) => {
	const isDataValidationFetchAction = isAnyOf(fetchUsers.fetch);
	return action$.pipe(
		filter(isDataValidationFetchAction),
		switchMap(() =>
			from(fetchUsersApi()).pipe(
				map((data) => fetchUsers.fulfilled(data)),
				catchError((err) => {
					const message = getErrorMessage(err, 'Failed to load user data');
					snackbarService.showErrorToast(message);
					return of(fetchUsers.rejected());
				}),
				startWith(fetchUsers.pending())
			)
		)
	);
};

const fetchDashboardsEpic: Epic<Action, Action> = (action$) => {
	const isDataValidationFetchAction = isAnyOf(fetchDashboards.fetch);
	return action$.pipe(
		filter(isDataValidationFetchAction),
		switchMap(() =>
			from(fetchDashboardsApi()).pipe(
				map((data) => fetchDashboards.fulfilled(data)),
				catchError((err) => {
					const message = getErrorMessage(err, 'Failed to fetch dashboards.');
					snackbarService.showErrorToast(message);
					return of(fetchDashboards.rejected());
				}),
				startWith(fetchDashboards.pending())
			)
		)
	);
};

const bulkUpdateMetricEpic: Epic<Action, Action, IAppState> = (action$) => {
	const isBulkUpdateMetricAction = isAnyOf(
		bulkUpdateMetricValidationItems.update
	);
	return action$.pipe(
		filter(isBulkUpdateMetricAction),
		mergeMap((rows) =>
			from(bulkUpdateMetricValidationApi(rows.payload.payloadData)).pipe(
				map((data) => bulkUpdateMetricValidationItems.fulfilled(data)),
				tap((action) => {
					const items = action.payload;
					const updatedColumn = rows.payload.updatingColumn.columnName;
					const updatedValue = rows.payload.updatingColumn.value;

					let message: string;
					switch (updatedColumn) {
						case 'liveDataStatus':
							message = `${items.length} items : live data status updated to '${updatedValue}'.`;
							break;
						case 'historicalDataStatus':
							message = `${items.length} items : historical data status updated to '${updatedValue}'.`;
							break;
						case 'assignedTo':
							message = `${items.length} items : assigned to '${updatedValue}'.`;
							break;
						case 'comments':
							message = `${items.length} items : comment updated to '${updatedValue}'.`;
							break;
						default:
							message = `${items.length} items : ${updatedColumn} updated to '${updatedValue}'.`;
							break;
					}
					snackbarService.showSuccessToast(message);
				}),
				catchError((err) => {
					const updatedColumn = rows.payload.updatingColumn.columnName;
					const updateDataLength = rows.payload.payloadData.length;
					let message: string;

					switch (updatedColumn) {
						case 'assignedTo':
							message = `${updateDataLength} items : assign failed`;
							break;
						case 'liveDataStatus':
							message = `${updateDataLength} items : live data status update failed`;
							break;
						case 'historicalDataStatus':
							message = `${updateDataLength} items : historical data status update failed`;
							break;
						default:
							message = `${updateDataLength} items : ${updatedColumn} update failed`;
							break;
					}
					const errorMessage = getErrorMessage(err, message);
					snackbarService.showErrorToast(errorMessage);
					return of(
						bulkUpdateMetricValidationItems.rejected(rows.payload.updatingRows)
					);
				}),
				startWith(
					bulkUpdateMetricValidationItems.pending(rows.payload.updatingRows)
				)
			)
		)
	);
};

const singleUpdateMetricEpic: Epic<Action, Action> = (action$) => {
	const isSingleUpdateMetricAction = isAnyOf(updateMetricValidationItem.update);
	return action$.pipe(
		filter(isSingleUpdateMetricAction),
		mergeMap((rows) =>
			from(updateMetricValidationApi(rows.payload.payloadData)).pipe(
				map((data) => updateMetricValidationItem.fulfilled(data)),
				tap((action) => {
					const updatedColumn = rows.payload.updatingColumn.columnName;
					const updatedValue = rows.payload.updatingColumn.value || 'none';
					const { metricName } = action.payload;
					let message: string;
					switch (updatedColumn) {
						case 'liveDataStatus':
							message = `${metricName} : live data status updated to '${updatedValue}'`;
							break;
						case 'historicalDataStatus':
							message = `${metricName} : historical data status updated to '${updatedValue}'`;
							break;
						case 'assignedTo':
							message = `${metricName} : assigned to '${updatedValue}'`;
							break;
						case 'comments':
							message = `${metricName} : comment updated to '${updatedValue}'`;
							break;
						default:
							message = `${metricName} : ${updatedColumn} updated to '${updatedValue}'`;
							break;
					}
					snackbarService.showSuccessToast(message);
				}),
				catchError((err) => {
					const updatedColumn = rows.payload.updatingColumn.columnName;
					const { metricName } = rows.payload.updatingColumn;
					let message: string;

					switch (updatedColumn) {
						case 'assignedTo':
							message = `${metricName} : assign failed`;
							break;
						case 'comments':
							message = `${metricName} : comment update failed`;
							break;
						case 'liveDataStatus':
							message = `${metricName} : live data status update failed`;
							break;
						case 'historicalDataStatus':
							message = `${metricName} : historical data status update failed`;
							break;
						default:
							message = `${metricName} : ${updatedColumn} update failed`;
							break;
					}
					const errorMessage = getErrorMessage(err, message);
					snackbarService.showErrorToast(errorMessage);
					return of(updateMetricValidationItem.rejected(rows.payload));
				}),
				startWith(updateMetricValidationItem.pending(rows.payload))
			)
		)
	);
};

export const metricValidationEpic = combineEpics(
	fetchMetricValidationEpic,
	fetchMetricTablesEpic,
	fetchDataValidationUsersEpic,
	fetchDashboardsEpic,
	bulkUpdateMetricEpic,
	singleUpdateMetricEpic
);
