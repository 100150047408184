import { Action, isAnyOf } from '@reduxjs/toolkit';
import { combineEpics, Epic } from 'redux-observable';
import {
	catchError,
	filter,
	from,
	map,
	mergeMap,
	of,
	startWith,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs';

import { IAppState } from '../../models/store';
import { workspaceActions } from '../reducers/workspace.reducer';
import {
	createWorkspaceDashboard,
	getWorkspaceReports,
	updateChartDetailsApi,
	updateDashboardDetailsApi,
} from '../../api/workspace.api';
import { deleteDashboard, shareDashboard } from '../../api/dashboard.api';
import { deleteChart, shareChart } from '../../api/chart.api';
import { snackbarService } from '../../components/snackbar/snackbar.service';
import { scheduleReportApi } from '../../api/reportActions/schedule.api';
import { getErrorMessage } from '../../utils/functions/errorMessages';

const fetchWorkspaceReportsEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const { fetchReports } = workspaceActions;

	return action$.pipe(
		filter(isAnyOf(fetchReports.fetch)),
		switchMap((action) => {
			return from(getWorkspaceReports(action.payload)).pipe(
				map((response) => {
					return fetchReports.fulfilled(response);
				}),
				catchError(() => of(fetchReports.rejected())),
				startWith(fetchReports.pending())
			);
		})
	);
};

const createWorkspaceDashboardEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const { createDashboard } = workspaceActions;

	return action$.pipe(
		filter(isAnyOf(createDashboard.create)),
		switchMap((action) => {
			return from(createWorkspaceDashboard(action.payload)).pipe(
				map((response) => {
					return createDashboard.fulfilled(response);
				}),
				tap(() => {
					snackbarService.showSuccessToast(`Dashboard has been created.`);
				}),
				catchError((error) => {
					const message = getErrorMessage(
						error,
						'Failed to create new dashboard.'
					);
					snackbarService.showErrorToast(message);
					return of(createDashboard.rejected());
				}),
				startWith(createDashboard.pending())
			);
		})
	);
};

const updateChartDetailsEpic: Epic<Action, Action, IAppState> = (action$) => {
	const { updateChartDetails } = workspaceActions;

	return action$.pipe(
		filter(isAnyOf(updateChartDetails.update)),
		switchMap((action) => {
			return from(updateChartDetailsApi(action.payload)).pipe(
				map((response) => {
					return updateChartDetails.fulfilled(response);
				}),
				tap(() => {
					snackbarService.showSuccessToast(`Chart details has been updated.`);
				}),
				catchError((error) => {
					const message = getErrorMessage(
						error,
						'Failed to update chart details.'
					);
					snackbarService.showErrorToast(message);
					return of(updateChartDetails.rejected());
				}),
				startWith(updateChartDetails.pending())
			);
		})
	);
};

const updateDashboardDetailsEpic: Epic<Action, Action, IAppState> = (
	action$
) => {
	const { updateDashboardDetails } = workspaceActions;

	return action$.pipe(
		filter(isAnyOf(updateDashboardDetails.update)),
		switchMap((action) => {
			return from(updateDashboardDetailsApi(action.payload)).pipe(
				map((response) => {
					return updateDashboardDetails.fulfilled(response);
				}),
				tap(() => {
					snackbarService.showSuccessToast(
						`Dashboard details has been updated.`
					);
				}),
				catchError((error) => {
					const message = getErrorMessage(
						error,
						'Failed to update dashboard details.'
					);
					snackbarService.showErrorToast(message);
					return of(updateDashboardDetails.rejected());
				}),
				startWith(updateDashboardDetails.pending())
			);
		})
	);
};

export const shareReportEpic: Epic<Action, Action, IAppState> = (
	action$,
	state$
) => {
	const { shareReport: share } = workspaceActions;

	return action$.pipe(
		filter(isAnyOf(share.share)),
		withLatestFrom(state$),
		mergeMap(([action, state]) => {
			const { uiId, userDetails } = action.payload;
			const { workspaceState } = state;

			const reportToUpdate = workspaceState.reports[uiId];

			if (!reportToUpdate) {
				snackbarService.showWarningToast('Report does not exist.');
				return of(share.rejected({ uiId }));
			}

			const promise =
				reportToUpdate.type === 'dashboard'
					? shareDashboard(reportToUpdate.id, userDetails)
					: shareChart(reportToUpdate.id, userDetails);

			return from(promise).pipe(
				tap((report) => {
					const type = report.type === 'chart' ? 'Chart' : 'Dashboard';
					snackbarService.showSuccessToast(`${type} has been shared.`);
				}),
				map((report) => share.fulfilled(report)),
				catchError((err) => {
					const message = getErrorMessage(err, 'Failed to share report.');
					snackbarService.showErrorToast(message);
					return of(share.rejected({ uiId }));
				}),
				startWith(share.pending({ uiId }))
			);
		})
	);
};

export const scheduleReportEpic: Epic<Action, Action, IAppState> = (
	action$,
	state$
) => {
	const { scheduleReport: schedule } = workspaceActions;

	return action$.pipe(
		filter(isAnyOf(schedule.schedule)),
		withLatestFrom(state$),
		mergeMap(([action, state]) => {
			const { uiId, data } = action.payload;
			const { workspaceState } = state;

			const reportToUpdate = workspaceState.reports[uiId];

			return from(
				scheduleReportApi(reportToUpdate.id, reportToUpdate.type, data)
			).pipe(
				tap(() => {
					const type = reportToUpdate.type === 'chart' ? 'Chart' : 'Dashboard';
					snackbarService.showSuccessToast(`${type} has been scheduled.`);
				}),
				map((updatedSchedule) =>
					schedule.fulfilled({ uiId, schedule: updatedSchedule })
				),
				catchError((err) => {
					const message = getErrorMessage(err, 'Failed to schedule report.');
					snackbarService.showErrorToast(message);
					return of(schedule.rejected({ uiId }));
				}),
				startWith(schedule.pending({ uiId }))
			);
		})
	);
};

export const deleteReportEpic: Epic<Action, Action, IAppState> = (
	action$,
	state$
) => {
	const { deleteReport } = workspaceActions;

	return action$.pipe(
		filter(isAnyOf(deleteReport.delete)),
		withLatestFrom(state$),
		mergeMap(([action, state]) => {
			const { uiId } = action.payload;
			const { workspaceState } = state;

			const reportToDelete = workspaceState.reports[uiId];

			if (!reportToDelete) {
				snackbarService.showWarningToast('Report does not exist.');
				return of(deleteReport.rejected({ uiId }));
			}

			const promise =
				reportToDelete.type === 'dashboard'
					? deleteDashboard(reportToDelete.id)
					: deleteChart(reportToDelete.id);

			return from(promise).pipe(
				tap((report) => {
					const type = report.type === 'chart' ? 'Chart' : 'Dashboard';
					snackbarService.showSuccessToast(`${type} has been deleted.`);
				}),
				map((report) => deleteReport.fulfilled(report)),
				catchError((err) => {
					const message = getErrorMessage(err, 'Failed to delete report.');
					snackbarService.showErrorToast(message);
					return of(deleteReport.rejected({ uiId }));
				}),
				startWith(deleteReport.pending({ uiId }))
			);
		})
	);
};

export const workspaceEpic = combineEpics(
	fetchWorkspaceReportsEpic,
	createWorkspaceDashboardEpic,
	shareReportEpic,
	scheduleReportEpic,
	deleteReportEpic,
	updateChartDetailsEpic,
	updateDashboardDetailsEpic
);
