import { useMediaQuery, useTheme } from '@mui/material';

const useScreenSize = () => {
	const theme = useTheme();
	const isLargeScreenOrMore = useMediaQuery(theme.breakpoints.up('lg'), {
		noSsr: true,
	});

	const isLargeScreenOrLess = useMediaQuery(theme.breakpoints.up('lg'), {
		noSsr: true,
	});

	const isTabletOrAbove = useMediaQuery(theme.breakpoints.up('md'), {
		noSsr: true,
	});
	const isTabletOrLess = useMediaQuery(theme.breakpoints.down('md'), {
		noSsr: true,
	});
	const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'), {
		noSsr: true,
	});

	const isMobileOrAbove = useMediaQuery(theme.breakpoints.up('sm'), {
		noSsr: true,
	});

	return {
		isTabletOrAbove,
		isTabletOrLess,
		isMobileSize,
		isMobileOrAbove,
		isLargeScreenOrMore,
		isLargeScreenOrLess,
	};
};

export default useScreenSize;
