// libraries imports
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// utils
import AuthGuard from '../../guards/AuthGuard/AuthGuard';
import Config from '../Config/Config';

// layouts
import AppLayout from '../Layout/AppLayout';
import MainLayout from '../Layout/MainLayout';
import WorkspaceLayout from '../Layout/WorkspaceLayout';

import DDive from '../DDive/DDive';
import DataGovernance from '../DataGovernance/DataGovernance';
import DataSummary from '../DataGovernance/DataSummary/DataSummary';
import AppSettings from '../Settings/AppSettings/AppSettings';
import LazyLoader from '../../components/common/LazyLoader/LazyLoader';
import NotFound from '../NotFound/NotFound';
import RoleBasedAccess from '../RoleBasedAccess/RoleBasedAccess';
import ReleaseNotes from '../ReleaseNotes/ReleaseNotes';

// Page lazy loads
const LicenseInfo = React.lazy(
	() =>
		import(/* webpackChunkName: "LicenseInfo" */ '../LicenseInfo/LicenseInfo')
);

const Home = React.lazy(
	() => import(/* webpackChunkName: "Home" */ '../Home/Home')
);

const Options = React.lazy(
	() => import(/* webpackChunkName: "Options" */ '../DDive/Options')
);

const MetricStore = React.lazy(
	() =>
		import(
			/* webpackChunkName: "Metric" */ '../DataGovernance/MetricStore/MetricStore'
		)
);
const MetricStoreTablesSummary = React.lazy(
	() =>
		import(
			/* webpackChunkName: "Metric" */
			'../DataGovernance/MetricStore/MetricStoreTablesSummary/MetricStoreTablesSummary'
		)
);
const MetricsSummary = React.lazy(
	() =>
		import(
			/* webpackChunkName: "Metric" */ '../DataGovernance/common/MetricsSummary/MetricsSummary'
		)
);
const DashboardWithFilters = React.lazy(
	() =>
		import(
			/* webpackChunkName: "DashboardWithFilters" */ '../Dashboards/WorkspaceDashboard/DashboardWithFilters'
		)
);

const Charts = React.lazy(
	() => import(/* webpackChunkName: "CreateNewChart" */ '../DDive/Charts')
);
const CreateNewChart = React.lazy(
	() =>
		import(/* webpackChunkName: "CreateNewChart" */ '../DDive/CreateNewChart')
);

const AxesSummary = React.lazy(
	() =>
		import(
			/* webpackChunkName: "AxesSummary" */ '../DataGovernance/common/AxesSummary/AxesSummary'
		)
);

const ModelsSummary = React.lazy(
	() =>
		import(
			/* webpackChunkName: "ModelsSummary" */ '../DataGovernance/common/ModelsSummary/ModelsSummary'
		)
);
const HierarchySummary = React.lazy(
	() =>
		import(
			/* webpackChunkName: "HierarchySummary" */ '../DataGovernance/DataSummary/HierarchySummary/HierarchySummary'
		)
);
const ReferencesSummary = React.lazy(
	() =>
		import(
			/* webpackChunkName: "ReferencesSummary" */ '../DataGovernance/DataSummary/ReferencesSummary/ReferencesSummary'
		)
);
const ReservedKeywordsSummary = React.lazy(
	() =>
		import(
			/* webpackChunkName: "ReservedKeywordsSummary" */
			'../DataGovernance/DataSummary/ReservedKeywordsSummary/ReservedKeywordsSummary'
		)
);

const TablesSummary = React.lazy(
	() =>
		import(
			/* webpackChunkName: "TablesSummary" */ '../DataGovernance/DataSummary/TablesSummary/TablesSummary'
		)
);
const WorkspaceDashboardChart = React.lazy(
	() =>
		import(
			/* webpackChunkName: "WorkspaceDashboard" */ '../Dashboards/WorkspaceDashboard/WorkspaceDashboardChart'
		)
);
const AddNewChart = React.lazy(
	() =>
		import(
			/* webpackChunkName: "AddNewChart" */ '../Dashboards/WorkspaceDashboard/AddNewChart'
		)
);
const WorkspaceDashboard = React.lazy(
	() =>
		import(
			/* webpackChunkName: "WorkspaceDashboard" */ '../Dashboards/WorkspaceDashboard/WorkspaceDashboard'
		)
);
const Dive = React.lazy(
	() => import(/* webpackChunkName: "Dive" */ '../DDive/Dive')
);
const Workspace = React.lazy(
	() => import(/* webpackChunkName: "Workspace" */ '../Workspace/Workspace')
);
const Settings = React.lazy(
	() => import(/* webpackChunkName: "Settings" */ '../Settings/Settings')
);
const ChartWithFilters = React.lazy(
	() =>
		import(
			/* webpackChunkName: "ChartWithFilters" */ '../DDive/ChartWithFilters'
		)
);
const UsageLogs = React.lazy(
	() => import(/* webpackChunkName: "UsageLogs" */ '../UsageLogs/UsageLogs')
);

const AppConfigurations = React.lazy(
	() =>
		import(
			/* webpackChunkName: "ConfigPreferences" */ '../Settings/AppSettings/AppConfigurations'
		)
);
const UserPreferences = React.lazy(
	() =>
		import(
			/* webpackChunkName: "ConfigPreferences" */ '../Settings/AppSettings/UserPreferences'
		)
);
const AdminPreferences = React.lazy(
	() =>
		import(
			/* webpackChunkName: "ConfigPreferences" */ '../Settings/AppSettings/AdminPreferences'
		)
);
const Permissions = React.lazy(
	() =>
		import(
			/* webpackChunkName: "RoleBasedAccess" */ '../RoleBasedAccess/Permissions/Permissions'
		)
);
const Groups = React.lazy(
	() =>
		import(
			/* webpackChunkName: "RoleBasedAccess" */ '../RoleBasedAccess/Groups/Groups'
		)
);
const Users = React.lazy(
	() =>
		import(
			/* webpackChunkName: "RoleBasedAccess" */ '../RoleBasedAccess/Users/Users'
		)
);
const MetricStoreHome = React.lazy(
	() =>
		import(
			/* webpackChunkName: "Metric" */ '../DataGovernance/MetricStore/MetricStoreHome/MetricStoreHome'
		)
);

const DataSummaryHome = React.lazy(
	() =>
		import(
			/* webpackChunkName: "DataSummaryHome" */ '../DataGovernance/DataSummary/DataSummaryHome/DataSummaryHome'
		)
);

const RouteConfig: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<MainLayout />}>
				{/* home page route */}
				<Route element={<AppLayout />}>
					<Route
						index
						element={
							<LazyLoader>
								<Home />
							</LazyLoader>
						}
					/>
				</Route>

				{/* protected routes  */}
				<Route element={<AuthGuard />}>
					<Route element={<AppLayout />}>
						{/* license info  */}
						<Route
							path="licenseInfo"
							element={
								<LazyLoader>
									<LicenseInfo />
								</LazyLoader>
							}
						/>

						{/* release notes  */}
						<Route path="releaseNotes" element={<ReleaseNotes />} />

						{/* settings page */}
						<Route path="settings">
							<Route element={<AppSettings />}>
								<Route
									path="appConfigurations/*"
									element={
										<LazyLoader>
											<AppConfigurations />
										</LazyLoader>
									}
								/>
								<Route
									path="adminPreferences/*"
									element={
										<LazyLoader>
											<AdminPreferences />
										</LazyLoader>
									}
								/>
								<Route
									path="userPreferences/*"
									element={
										<LazyLoader>
											<UserPreferences />
										</LazyLoader>
									}
								/>
							</Route>
							<Route
								index
								element={
									<LazyLoader>
										<Settings />
									</LazyLoader>
								}
							/>
						</Route>
						<Route
							path="usageLogs"
							element={
								<LazyLoader>
									<UsageLogs />
								</LazyLoader>
							}
						/>

						{/* dive,disambiguation and charts page routes */}
						<Route path="dive">
							<Route path="charts">
								<Route index element={<Navigate to="createNewChart" />} />
								<Route
									path="dagId/:dagId/*"
									element={
										<LazyLoader>
											<Charts />
										</LazyLoader>
									}
								/>
								<Route
									path="chartId/:ChartId/*"
									element={
										<LazyLoader>
											<Charts />
										</LazyLoader>
									}
								/>
								<Route
									path="createNewChart"
									element={
										<LazyLoader>
											<CreateNewChart />
										</LazyLoader>
									}
								/>
							</Route>
							<Route
								path="options"
								element={
									<LazyLoader>
										<Options />
									</LazyLoader>
								}
							/>
							<Route
								index
								element={
									<LazyLoader>
										<Dive />
									</LazyLoader>
								}
							/>
						</Route>

						{/* role based access page routes  */}
						<Route path="roleBasedAccess" element={<RoleBasedAccess />}>
							<Route
								path="permissions/*"
								element={
									<LazyLoader>
										<Permissions />
									</LazyLoader>
								}
							/>
							<Route
								path="groups/*"
								element={
									<LazyLoader>
										<Groups />
									</LazyLoader>
								}
							/>
							<Route
								path="users/*"
								element={
									<LazyLoader>
										<Users />
									</LazyLoader>
								}
							/>
							<Route index element={<Navigate to="permissions" replace />} />
						</Route>
						{/* data governance routes  */}
						<Route path="dataGovernance" element={<DataGovernance />}>
							<Route
								path="metricStore"
								element={
									<LazyLoader>
										<MetricStore />
									</LazyLoader>
								}
							>
								<Route
									path="models/:modelId/*"
									element={
										<LazyLoader>
											<MetricStoreTablesSummary />
										</LazyLoader>
									}
								/>

								<Route
									path="models/:modelId/tables/:tableId/*"
									element={
										<LazyLoader>
											<MetricStoreTablesSummary />
										</LazyLoader>
									}
								/>

								<Route
									index
									element={
										<LazyLoader>
											<MetricStoreHome />
										</LazyLoader>
									}
								/>
							</Route>
							<Route path="summary" element={<DataSummary />}>
								<Route
									path="models/:modelId/*"
									element={
										<LazyLoader>
											<ModelsSummary />
										</LazyLoader>
									}
								/>
								<Route
									path="models/:modelId/tables/:tableId/*"
									element={
										<LazyLoader>
											<TablesSummary />
										</LazyLoader>
									}
								/>
								<Route
									path="models/:modelId/metricStore/tables/:tableId/*"
									element={
										<LazyLoader>
											<MetricStoreTablesSummary />
										</LazyLoader>
									}
								/>
								<Route
									path="models/:modelId/tables/:tableId/axes/:axisId"
									element={
										<LazyLoader>
											<AxesSummary />
										</LazyLoader>
									}
								/>
								<Route
									path="models/:modelId/tables/:tableId/metrics/:metricId"
									element={
										<LazyLoader>
											<MetricsSummary />
										</LazyLoader>
									}
								/>

								<Route
									path="models/:modelId/tables/:tableId/references/:referenceId"
									element={
										<LazyLoader>
											<ReferencesSummary />
										</LazyLoader>
									}
								/>

								<Route
									path="models/:modelId/hierarchies/:hierarchyId/*"
									element={
										<LazyLoader>
											<HierarchySummary />
										</LazyLoader>
									}
								/>
								<Route
									path="models/:modelId/reservedKeywords"
									element={
										<LazyLoader>
											<ReservedKeywordsSummary />
										</LazyLoader>
									}
								/>

								<Route
									index
									element={
										<LazyLoader>
											<DataSummaryHome />
										</LazyLoader>
									}
								/>
							</Route>

							<Route index element={<Navigate to="summary" replace />} />
						</Route>
					</Route>

					{/* workspace routes  */}
					<Route path="workspace" element={<WorkspaceLayout />}>
						<Route
							path="dashboard/:dashboardId/chart/:chartId/*"
							element={
								<LazyLoader>
									<WorkspaceDashboardChart />
								</LazyLoader>
							}
						/>
						<Route
							path="dashboard/:dashboardId/addNewChart/*"
							element={
								<LazyLoader>
									<AddNewChart />
								</LazyLoader>
							}
						/>
						<Route
							path="dashboard/:dashboardId"
							element={
								<LazyLoader>
									<WorkspaceDashboard />
								</LazyLoader>
							}
						/>

						<Route
							index
							element={
								<LazyLoader>
									<Workspace />
								</LazyLoader>
							}
						/>
					</Route>
				</Route>

				{/* public charts route  */}
				<Route
					path="workspace/dashboardWithFilters/dashboard/:dashboardId/*"
					element={
						<LazyLoader>
							<DashboardWithFilters />
						</LazyLoader>
					}
				/>

				<Route
					path="dive/chartWithFilters/chartId/:ChartId/*"
					element={
						<LazyLoader>
							<ChartWithFilters />
						</LazyLoader>
					}
				/>

				{/* old ui pages routes */}
				<Route path="explore" element={<DDive path="explore" />} />
				<Route path="help" element={<DDive path="help" />} />
				<Route path="fileUpload" element={<DDive path="file_upload" />} />
				<Route
					path="changePassword"
					element={<DDive path="auth/password_change" />}
				/>
				<Route path="login" element={<DDive path="auth/login" />} />

				{/* config-ui route  */}
				<Route path="configure" element={<Config path="" />} />

				{/* not found page route  */}
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
};

export default RouteConfig;
