import { createAction } from '@reduxjs/toolkit';
import {
	ISearchAccuracyBulkUpdate,
	ISearchAccuracyItem,
	ISearchAccuracyUpdate,
} from '../../models';

const SLICE_NAME = 'searchAccuracy';

export const fetchItems = {
	fetch: createAction(`${SLICE_NAME}/fetch`),
	pending: createAction(`${SLICE_NAME}/fetch/pending`),
	rejected: createAction(`${SLICE_NAME}/fetch/rejected`),
	fulfilled: createAction<ISearchAccuracyItem[]>(
		`${SLICE_NAME}/fetch/fulfilled`
	),
};

export const updateItem = {
	update: createAction<{ id: number; data: ISearchAccuracyUpdate }>(
		`${SLICE_NAME}/update`
	),
	pending: createAction<number>(`${SLICE_NAME}/update/pending`),
	rejected: createAction<number>(`${SLICE_NAME}/update/rejected`),
	fulfilled: createAction<ISearchAccuracyItem>(
		`${SLICE_NAME}/update/fulfilled`
	),
};

export const bulkUpdate = {
	update: createAction<ISearchAccuracyBulkUpdate[]>(`${SLICE_NAME}/bulkUpdate`),
	pending: createAction<number[]>(`${SLICE_NAME}/bulkUpdate/pending`),
	rejected: createAction<number[]>(`${SLICE_NAME}/bulkUpdate/rejected`),
	fulfilled: createAction<ISearchAccuracyItem[]>(
		`${SLICE_NAME}/bulkUpdate/fulfilled`
	),
};
