import { combineEpics } from 'redux-observable';
import { AppSettingsEpics } from './appSettings.epic';
import { asyncTaskEpic } from './asyncTask.epic';
import { authEpic } from './auth.epic';
import { chartsEpic } from './charts.epic';
import { dataRefreshEpic } from './dataRefresh.epic';
import { hierarchyValidationEpic } from './hierarchyValidation.epic';
import { metricValidationEpic } from './metricValidation.epic';
import { searchAccuracyEpic } from './searchAccuracy.epic';
import { usageLogsEpics } from './usageLogs.epic';
import { workspaceEpic } from './workspace.epic';
import { workspaceDashboardEpic } from './workspaceDashboard.epic';

export const rootEpic = combineEpics(
	authEpic,
	dataRefreshEpic,
	metricValidationEpic,
	hierarchyValidationEpic,
	searchAccuracyEpic,
	usageLogsEpics,
	workspaceEpic,
	chartsEpic,
	asyncTaskEpic,
	workspaceDashboardEpic,
	AppSettingsEpics
);
