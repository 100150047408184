import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDataRefreshData, IDataRefreshStore } from '../../models/store';
import { fetchDataRefreshItems } from '../actions/dataRefresh.actions';

const INITIAL_STATE: IDataRefreshStore = {
	fetchStatus: 'idle',
	data: [],
	originalItemOrder: [],
	currentItemOrder: [],
};

const dataRefreshSlice = createSlice({
	name: 'dataRefresh',
	initialState: INITIAL_STATE,
	reducers: {
		onDataRefreshSearch: (state, action: PayloadAction<IDataRefreshData[]>) => {
			const searchResult = action.payload;
			if (searchResult.length) {
				state.currentItemOrder = searchResult.map((result) => result.id);
			} else {
				state.currentItemOrder = [...state.originalItemOrder];
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDataRefreshItems.pending, (state) => {
				state.fetchStatus = 'pending';
			})
			.addCase(fetchDataRefreshItems.rejected, (state) => {
				state.fetchStatus = 'rejected';
			})
			.addCase(fetchDataRefreshItems.fulfilled, (state, action) => {
				state.fetchStatus = 'fulfilled';
				const items = action.payload;

				state.data = [];
				state.originalItemOrder = [];
				state.currentItemOrder = [];
				items.forEach((item: IDataRefreshData) => {
					state.data[item.id] = item;
					state.originalItemOrder.push(item.id);
					state.currentItemOrder.push(item.id);
				});
			});
	},
});

export const dataRefreshActions = {
	...dataRefreshSlice.actions,
	fetchDataRefreshItems,
};

export default dataRefreshSlice.reducer;
