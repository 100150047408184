export function getErrorMessage(err: any, defaultMessage: string): string {
	// if error has data from server
	if (err?.response?.data) {
		const data = err?.response?.data;
		const responseDataKey: string = Object.keys(data)[0];
		if (data[responseDataKey] === '<') {
			return defaultMessage;
		}
		if (Array.isArray(data[responseDataKey])) {
			return data[responseDataKey][0];
		}
		return data[responseDataKey];
	}

	switch (err.message) {
		case 'Network Error':
			return 'That operation could not be processed. Please check your internet connection and try again.';

		default:
			return defaultMessage;
	}
}
