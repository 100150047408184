import { IDataSourceDetails } from './dataSource.model';
import { ITableDTO } from './dto';
import { TChangeType, TTopBottomType } from './misc.model';
import { IReportSchedule } from './schedule.model';
import { IUser } from './user.model';
import { TWorkspaceReportType } from './workspace.model';

export enum EChartType {
	TABLE = 'table',
	BAR = 'bar',
	STACKED_BAR = 'stack',
	DONUT = 'donut',
	LINE = 'line',
	STACKED_AREA = 'sarea',
	HISTOGRAM = 'histogram',
	FUNNEL = 'funnel',
}

// DISAMBIGUATION RESULT MODELS ------------------------------------------------
export interface ISubQueryJoinPaths {
	fromKey: string;
	toKey: string;
	aliases: string[];
}

export interface ISubQueryMetrics {
	name: string;
	table: string;
	alias: string;
	aggregation: string;
}

export interface ISubQueryAxes {
	name: string;
	alias: string;
	table: string;
}

export interface ISubQueryFilters {
	alias: string;
	name: string;
	table: string;
	filterOperator: string;
	filterValue: string;
}

export interface IDisambiguationItems {
	dagId: number;
	responseType: string;
	subQueries: {
		subQueryKey: string;
		name: string;
		joinPaths: ISubQueryJoinPaths[];
		metrics: ISubQueryMetrics[];
		axes: ISubQueryAxes[];
		filters: ISubQueryFilters[];
	}[];
	stateChangeable: boolean[];
	modelResponse: {
		biTag: string;
		columnTag: string;
		correctedQuestion: string;
		query: string;
		questionTag: string;
		typeTag: string;
	};
}
// END: DISAMBIGUATION RESULT MODELS  ------------------------------------------

export interface ITimeSeriesModel {
	active: boolean;
	alias: string;
	metaId: string;
	name: string;
	table: string;
	type: string;
}

export interface ITableItems {
	catalog: string;
	db: string;
	schema: string;
	tableName: string;
	tableType: string;
}
export interface IMetricItems {
	aggregation: string;
	aggregationList: string[];
	alias: string;
	customSort: boolean;
	dataType: string;
	metaId: string;
	name: string;
	table: string;
	type: string;
	sortOperations: {
		ascending?: boolean;
		measureColumn?: string;
	};
	queryName: string;
	dagId: number;
	unitsOfMeasure: {
		label: string;
		uom: string;
		uomType: string;
	};
	dataSource: IDataSourceDetails[];
	aggregateAxisId?: string;
}

export interface IAxisKeywords {
	keywords: string;
	aliases: string[];
}

export interface ISearchedKeywords {
	metaId: string;
	type: string;
	alias: string;
	name: string;
	table: string;
	keywords: IAxisKeywords[];
	category: string;
}

export interface IFilterItems {
	isTime: boolean;
	alias: string;
	filterOperator: string | null;
	filterValue1: string | null;
	filterValue2: string | null;
	metaId: string;
	name: string;
	table: string;
	type: string;
	system: boolean;
	keywords?: IAxisKeywords[];
	word?: string;
	category?: string;
	queryName?: string;
}
export interface IAxisItems {
	axisPriority?: string;
	alias: string;
	customSort: boolean;
	dataType: string;
	metaId: string;
	name: string;
	table: string;
	tokenFormat: string | null;
	type: string;
	category?: string;
	sortOperation: {
		ascending?: boolean;
		measureColumn?: string;
	} | null;
	keywords: IAxisKeywords[];
	keywordsFromData: string[];
	isContinuous: boolean;
}

export interface IJoinPathItems {
	aliases: string[];
	fromKey: string;
	toKey: string;
	isSelected: boolean;
	joinPathType: string;
	fromTable: ITableItems;
	toTable: ITableItems;
	innerJoinPath: ITableDTO[][];
	pathDef?: string;
}

export interface IOtherItems {
	limit: number;
	parentNodeId: number;
	relatedMeasureName: string;
	topOfSliceName: string;
	type: TTopBottomType;
	measureMetaId: string;
}

export interface ISubQueriesItems {
	alias: string;
	contextFilters: IFilterItems[];
	queryName: string;
	sql: string;
	joinPaths: IJoinPathItems[];
	otherItems: IOtherItems[];
}

export interface IAxisOrderSet {
	alias: string;
	isColumn: boolean;
	metaId: string;
	order: number;
}

export interface IMetricOrderSet {
	measureName: string;
	metaId: string;
	order: number;
}

export interface IPinnedChartData {
	id: number;
	sharedWith: IUser[];
	isActive: boolean;
	userId: IUser;
	schedule: IReportSchedule | null;
	isSystem: false;
	axisOrderSet: IAxisOrderSet[];
	measureOrderSet: IMetricOrderSet[];
	collection: null;
	isScheduled: boolean;
	isPublic: boolean;
	chartTitle: string;
	explore: boolean;
	exploreTemplate: null;
	createdDate: string;
	showXAxis: boolean | null;
	showYAxis: boolean | null;
	showLegends: boolean | null;
	showTitle: boolean | null;
	modifiedDate: string;
	position: number;
	width: number | null;
	height: number | null;
	startCoordinates: number[];
	chartType: string;
	subtotalFlag: boolean;
	totalFlag: boolean;
	showDataPoints: boolean;
	chartData: number;
	dashboardId: number | null;
	origChartData: number;
}

export interface IDataMap {
	[key: string]: string | number;
}
export interface IChartsData {
	chartTitle: string;
	chartId: number;
	chartTypes: string[];
	correctedText: string;
	dagId: number;
	responseType: string;
	searchText: string;
	stateChangeable: boolean[];
	data: IDataMap[];
	selectedAxes: IAxisItems[];
	selectedSeries: IAxisItems[];
	selectedFilters: IFilterItems[];
	selectedMetrics: IMetricItems[];
	drillDownAxes: IAxisItems[];
	axes: IAxisItems[];
	filters: IFilterItems[];
	metrics: IMetricItems[];
	subQueries: ISubQueriesItems[];
	truncated: boolean;
}

export interface IAllItems {
	axes: IAxisItems[];
	filters: IFilterItems[];
	metrics: IMetricItems[];
}
export interface IBrowserDetails {
	appCodeName: string;
	appName: string;
	appVersion: string;
	cookieEnabled: boolean;
	onLine: boolean;
	platform: string;
	plugins: string[];
	userAgent: string;
}
export interface IFeedbackData {
	browser: IBrowserDetails;
	comment: string;
	is_issue: boolean;
	screenshot: string;
	url: string;
	id: number;
	user: number;
}

export interface IDataWithNullValues {
	[key: string]: {
		[key: string]: string | number | null;
	};
}

export interface IChartMoreDetails {
	primaryAxes: IAxisItems[];
	secondaryAxes: IAxisItems[];
	othersAxes: IAxisItems[];
	metrics: IMetricItems[];
	secondaryAxesKeywordsFromData: string[];
	primaryAxesKeywordsFromData: string[];
	dataWithNullValue: IDataWithNullValues;
	isNegativeValues?: boolean;
	metricsMinMaxValues: {
		[metricAlias: string]: [number, number];
	};
}

// Payload models  -------------------------------------------------------------

export interface ISubCallItemsPayload {
	changeType: TChangeType;
	metaId: string;
	type: string;
}

export interface IDownloadPayload {
	chartTitle: string;
	columns: string[];
	dagId: number;
	disableSubtotals: boolean;
	measures: string[];
	rows: string[];
}

export interface IMetricsPayload extends ISubCallItemsPayload {
	queryName?: string;
	aggregation?: string;
	aggregateAxisId?: string;
}

export interface IFiltersPayload extends ISubCallItemsPayload {
	filterOperator: string;
	filterValue1: string | null;
	filterValue2: string | null;
	word?: string;
	queryName?: string;
}

export interface IContextFiltersPayload extends IFiltersPayload {
	queryName: string;
	measureMetaId: string;
}

export interface IJoinPathsPayload extends IJoinPathItems {
	queryName: string;
	measureMetaId: string;
}
export interface ISortingPayload {
	ascending: boolean;
	axes: string[];
	customSortDef: string[];
	metricColumn: string | null;
	sortColumn: string;
}

export interface ITopBottomFiltersPayload {
	changeType: TChangeType;
	limit: string;
	parentNodeId: string | null;
	relatedMeasureName: string;
	topOfSliceName: string;
	type: TTopBottomType;
	queryName: string;
	measureMetaId: string;
}
export interface ISubCallPayload {
	dagId: number;
	axes?: ISubCallItemsPayload[];
	metrics?: IMetricsPayload[];
	filters?: IFiltersPayload[];
	contextFilters?: IContextFiltersPayload[];
	joinPaths?: IJoinPathsPayload[];
	sortingData?: ISortingPayload[];
	topBottomFilters?: ITopBottomFiltersPayload[];
	axisOrderSet: IAxisOrderSet[];
	measureOrderSet: IMetricOrderSet[];
}

export interface IPreviousOrNextStatePayload {
	dagId: number;
	type: 'back' | 'forward';
}

export interface IOptionsPayload {
	dagId: number;
	subQuery: string;
}

export interface IPinChartPayload {
	axes: string[];
	axisOrderSet: IAxisOrderSet[];
	measures: string[];
	measureOrderSet: IMetricOrderSet[];
	chartData: number;
	chartTitle: string;
	startCoordinates: number[];
	chartType: string;
	dashboardId: number | null;
	filterCount: number;
	isSwapped: number;
	isSystem: boolean;
	position: number;
	showDataPoints: boolean;
	showXAxis: boolean | null;
	showYAxis: boolean | null;
	showLegends: boolean | null;
	subTotalFlag: boolean;
	totalFlag: boolean;
}

export interface IScheduleChartPayload {
	id: number;
	type: TWorkspaceReportType;
	data: IReportSchedule;
}

export interface IFeedbackPayload {
	browser: IBrowserDetails;
	comment: string;
	is_issue: boolean;
	screenshot: string;
	url: string;
}

// END: Payload models  --------------------------------------------------------
