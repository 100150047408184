import { IUserDetails } from '../models';
import { IWorkspaceChartDTO } from '../models/dto';
import {
	deserializeShareReportData,
	serializeWorkspaceChart,
} from '../serializers';

import axiosInstance from './axios/axios';

export const shareChart = async (
	chartId: number,
	userDetails: IUserDetails[]
) => {
	const data = deserializeShareReportData({ userDetails });
	const chartDto = await axiosInstance
		.post<IWorkspaceChartDTO>(`ddive/api/chart/${chartId}/share/`, data)
		.then((response) => response.data);

	const chart = serializeWorkspaceChart(chartDto);

	return chart;
};

export const deleteChart = async (chartId: number) => {
	const chartDto = await axiosInstance
		.patch<IWorkspaceChartDTO>(`ddive/api/chart/${chartId}/`, {
			is_active: false,
		})
		.then((response) => response.data);

	const deletedChart = serializeWorkspaceChart(chartDto);

	return deletedChart;
};
